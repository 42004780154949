import React, {useState, useEffect} from 'react'
import { fetchData, sendData } from '../../utils';
import { useNavigate } from 'react-router-dom';

function getCurrentDateInYYYYMMFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so we add 1 and pad with 0 if needed
    return `${year}-${month}`;
}

const ProductSales = () => {
    const [loading, setLoading]= useState(true);
    const [branches, setBranches] = useState([]);
    const [data, setData] = useState([]);
    const [branchid, setBranchid] = useState('0');
    const [month, setMonth] = useState(getCurrentDateInYYYYMMFormat());
    const navigate = useNavigate();

    const getData = async () => {
        if(!loading) setLoading(true)
        const {status, data, msg} = await sendData(`/getreportsdata`, JSON.stringify(
            {
            type: 'product-sales', 
            storeid: localStorage.getItem("storeid"),
            userid: localStorage.getItem("userid"),
            branchid,
            from: month ? month : getCurrentDateInYYYYMMFormat()
            }
        ), navigate);
        if(status){
            setData(data);
            setLoading(false);
        }
    }

    const getBranches = async () => {
        const {status, data, msg} = await fetchData(`/branches/dropdown/${localStorage.getItem('storeid')}/${localStorage.getItem("userid")}`, navigate);
        if(status){
            setBranches(data);
        }
    }

    useEffect(() => {
        getData();
    }, [branchid, month])

    useEffect(() => {
        getBranches();
    }, [])


  return (
    <div style={{flex: 1, overflow: 'hidden'}}>
    <div className='d-flex h-100'>
        <aside className="sidebar-containerx border-end">
        <div className="py-4">
        <div className="px-3">

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Month</p>
                    <input value={month} onChange={e => setMonth(e.target.value)} type="month"  className="form-control mt-2" />

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Branch</p>
                    <select onChange={e => setBranchid(e.target.value)} value={branchid} name="branchid" id="branchid" className="form-select mt-2">
                    <option value="0">All branches</option>
                        {branches.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                    </select>

        </div>

        </div>
    </aside>

    <main className="main-containerx" style={{width: '80%', position:'relative', overflow: 'scroll' }}>

        {loading ?
        <div className="py-3 px-4 d-flex justify-content-center align-items-center" style={{marginTop:'10%'}}>

            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        : 
        <div className='py-3 px-4' style={{height: '100vh', position:'absolute', left:0, top: 0,width: '100%'}}>
            {data.length > 0 ? (
                <>
                    <div class="p-2 bg-white table-container">
                        <table class="table">

                        <thead class="pnf-thead">
                            <tr class="pnf-tr-head">
                            <th scope="col">Product</th>
                            <th scope="col">Brand</th>
                            <th scope="col">Orders</th>
                            <th scope="col">Users</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Sales</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                        {data.map(product => {
                            return (
                                <tr className='pnf-tr bg-white'>
                                    <th scope="row">{product.prod.name}</th>
                                    <td>{product.prod.brand ? product.prod.brand.name:''}</td>
                                    <td>{product.orders}</td>
                                    <td>{product.users}</td>
                                    <td>{parseFloat((+product.quanch).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{product.unitmeasure2 == 'package' || product.unitmeasure2 == 'null'  ? ' UN' :  ' grams'  }</td>
                                    <td>${product.totalx.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            )
                        }) }
                        </tbody>

                        </table>
                        
                    </div>
                    <div style={{paddingBottom: '30px'}}>

                    </div>
                </>
            )
        :
        (
            <>
            <div class="p-2 bg-white table-container">
                <table class="table">

                <thead class="pnf-thead">
                    <tr class="pnf-tr-head">
                    <th scope="col">Product</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Orders</th>
                    <th scope="col">Users</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Sales</th>
                    </tr>
                </thead>
                

                </table>
                {data.length==0&&(
                    <div style={{width:'100%',backgroundColor:'white',height:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <p style={{color:'#333333',fontWeight:'500',fontSize:16}}>No data to show</p>
              </div>
                )}
            </div>
            <div style={{paddingBottom: '30px'}}>

            </div>
        </>  
        )
        }
        </div>
        }

    </main>

</div>
</div>
  )
}

export default ProductSales