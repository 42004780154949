import React, { useEffect, useState , useRef } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
export default function UserItem(props) {
  const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const DeleteUser = async(userid,storeid) => {
      try{
        const req = await fetch(`https://api.trakitrak.com/merchant/users/delete/${userid}/${storeid}`,
            {
                method:'DELETE', 
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    
                },
            }
        );
        if(req.status === 200){
            const res = await req.json();
           props.fetchUsers()
        } else {
            if(req.status === 494){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
            const res = await req.json();
           // return {status: false, msg: res.message}
        }
    } catch(err){
       // return { status: false, msg: "Server error" };
    }
    }
    const popover = (
        <Popover >
            <div style={{width:60,height:70,display:'flex',flexDirection:'column',justifyContent:'center',borderRadius:10}}>
            <div style={{height:35,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderBottomWidth:1,borderBottomColor:'red'}}>
              <a onClick={()=>navigate(`/users/edit/${props.admin.id}`)}  style={{cursor:'pointer'}} >Edit</a>
            </div>
            <div style={{height:35,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <a onClick={()=>setShow(true)} style={{cursor:'pointer',color:'red'}} className="red-a delete-user" data-bs-toggle="modal" data-bs-target="#branchModal">
                Delete
              </a>
            </div>
            </div>
        </Popover>
      );
  return (
    <>
    <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.admin.email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>{DeleteUser(props.admin.id,localStorage.getItem('storeid'))}}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    <tr style={{ lineHeight: '35px' }} key={props.admin.id}>
    <th width="40%" scope="row">
      <p>Name</p>
      <p className="text-body-secondary" style={{ fontSize: '13px' }}>{props.admin.email}</p>
    </th>
    <td width="25%">{props.admin.admin === 1 ? 'Store admin' : 'Branch manager'}</td>
    <td width="25%">
      {props.admin.admin === 1 ? (
        <p className="users-tr"> All branches</p>
      ) : (
        <p className="users-tr">{props.admin.branches.map((branch, key) => (key > 0 ? `, ${branch.branch.name}` : branch.branch.name))}</p>
      )}
    </td>
    <td width="10%">
      <OverlayTrigger  trigger="click" placement="right" rootClose={true} overlay={popover}>
        <Button>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" className="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>
        </Button>
      </OverlayTrigger>
    </td>
  </tr>
  </>
  )
}
