import React, {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { sendData } from '../utils';
import { Alert } from 'react-bootstrap';

import Tippy from '@tippyjs/react/headless';
import {fetchData} from "../utils"
import Select, { components, createFilter } from "react-select"
// import Select, { components, GroupHeadingProps,StylesConfig } from "react-select"
import makeAnimated from "react-select/animated";
import { borderBottom, textAlign, width } from '@mui/system';
export default function AddProductBarcode() {
    const navigate = useNavigate()
    const {barcode} = useParams()
    const [Errors, setErrors] = useState([]);
    const [error, setError] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [loader, setLoader] = useState(true);
    const [msg, setMsg] = useState('');
    const [barcodeinfo, setBarcodeinfo] = useState({});


    const [ImagesName, setImagesName] = useState([]);
    const [ImagesSize, setImagesSize] = useState([]);
    const [ImageName, setImageName] = useState('');
    const [cat, setCat] = useState('');
    const [brand, setBrand] = useState();
    const [selectedBrand, setSelectedBrand] = useState({value: null, label: 'Select Brand'});
    const [newBrand, setNewBrand] = useState(false);
    const [newBrandName, setNewBrandName] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [format, setFormat] = useState('');
    const [selectedCat, setSelectedCat] = useState({value: null, label: 'Category (required)'});
    const [sku, setSku] = useState('');
    const [featured, setFeatured] = useState('0');
    const [tags, setTags] = useState([]);
    const [image, setImage] = useState(null);
    const [otherImages, setOtherImages] = useState([]);
    const [unitmeasure, setUnitMeasure] = useState('unit');
    const [unitmeasure2, setUnitMeasure2] = useState(null);
    const [prixunit1, setPrixunit1] = useState('');
    const [prixbulk1, setPrixbulk1] = useState('');
    const [prixpack1, setPrixpack1] = useState('');
    const [unit, setUnit] = useState('');
    const [weightpack1, setWeightpack1] = useState('');


    const GetProdBarcode = async () => {
  
        const {status, data, msg} = await sendData('/prodbarcode', JSON.stringify({userid: localStorage.getItem('userid'),storeid: localStorage.getItem('storeid'),barcode:barcode}));
        if(status){
          console.log(data)
          setBarcodeinfo(data)
          setImageName(data.prodbarcode.img)
          setName(data.prodbarcode.name)
          setCat(data.prodbarcode.prodcat.name+" > "+data.prodbarcode.prodsub.name+" > "+data.prodbarcode.prodsubsub.name)
          setBrand(data.prodbarcode.prodbrand!=null?data.prodbarcode.prodbrand.name:'')
          setPrixbulk1(data.prodbarcode.prixbulk1==0?'':data.prodbarcode.prixbulk1)
          setPrixpack1(data.prodbarcode.prixpack1==0?'':data.prodbarcode.prixpack1)
          setPrixunit1(data.prodbarcode.prixunit1==0?'':data.prodbarcode.prixunit1)
          setDescription(data.prodbarcode.dess)
          setFormat(data.prodbarcode.formatt)
          setImage(data.prodbarcode.prodimg)
          setWeightpack1(data.prodbarcode.weightpack1)
          setUnit(data.prodbarcode.unit)
        //   setUnitMeasure('both')
        //   setUnitMeasure2('package')
           setUnitMeasure(data.prodbarcode.unitmeasure)
           setUnitMeasure2(data.prodbarcode.unitmeasure2)
          //setSku(data.prodbarcode.sku)
          if(data.prodbarcode.prodimg2){
            var obj = {};
            obj["value"] =data.prodbarcode.prodimg2
            obj["label"] = data.prodbarcode.img2
            otherImages.push(obj)

          }
          if(data.prodbarcode.prodimg3){
              var obj = {};
            obj["value"] =data.prodbarcode.prodimg3
            obj["label"] = data.prodbarcode.img3
            otherImages.push(obj)
          }
          if(data.prodbarcode.prodimg4){
            var obj = {};
            obj["value"] =data.prodbarcode.prodimg4
            obj["label"] = data.prodbarcode.img4
            otherImages.push(obj)
          }
          if(data.prodbarcode.prodimg5){
            var obj = {};
            obj["value"] =data.prodbarcode.prodimg5
            obj["label"] = data.prodbarcode.img5
            otherImages.push(obj)
          }
          if(data.prodbarcode.tobacco){
            tags.push('Tobacco')
          }
          if(data.prodbarcode.alcoholic){
            tags.push('Alcohol')
          }
          if(data.prodbarcode.fragile){
            tags.push('Fragile')
          }
          if(data.prodbarcode.frozen){
            tags.push('Frozen')
          }
          setLoader(false)
        }else{
            setLoader(false)
          setShowalert(true)
          setMsg(msg)
    
  
     
        }
      }
    //   /prodbarcode/create
      useEffect(()=>{
        GetProdBarcode()
      },[])

      function containsOnlyNumbers(str) {
        // Regular expression to match only digits
        const regex = /^[0-9]+$/;
        
        // Test the string against the regular expression
        return regex.test(str);
      }
      const isFormValid = () => {
        let x=[]
              if (!sku) x.push('SKU is required'); 
        
          
              if(unitmeasure=='unit'&&unitmeasure2==null){
                if (!prixunit1) x.push('Price is required'); 
                if (!unit) x.push('Weight is required'); 
                // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
                // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
              }
              if(unitmeasure=='weight'&&unitmeasure2=='bulk'){
                if (!prixbulk1) x.push('Price is required'); 
                // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
              }
              if(unitmeasure=='weight'&&unitmeasure2=='package'){
                if (!prixpack1) x.push('Price is required'); 
                if (!weightpack1) x.push('Weight per package is required');
                // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
                // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
      
              }
              if(unitmeasure=='weight'&&unitmeasure2=='both'){
                if (!prixbulk1) x.push('Price is required'); 
                if (!prixpack1) x.push('Price is required'); 
                if (!weightpack1) x.push('Weight per package is required');
                // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
                // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');
                // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
       
              }
              if(unitmeasure=='both'&&unitmeasure2=='weight'){
                if (!prixbulk1) x.push('Price is required'); 
                if (!prixunit1) x.push('Price is required'); 
                if (!unit) x.push('Weight per unit is required');
                // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
                // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
                // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');
      
              }
              if(unitmeasure=='both'&&unitmeasure2=='package'){
                if (!prixunit1) x.push('Price is required'); 
                if (!prixpack1) x.push('Price is required'); 
                if (!weightpack1) x.push('Weight per package is required');
                if (!unit) x.push('Weight per unit is required');
              //   if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
              //   if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
              //   if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
              //   if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
               }
              if(unitmeasure=='both'&&unitmeasure2=='both'){
                if (!prixunit1) x.push('Price is required'); 
                if (!prixbulk1) x.push('Price is required'); 
                if (!prixpack1) x.push('Price is required'); 
                if (!unit) x.push('Weight per unit is required');
                if (!weightpack1) x.push('Weight per package is required');
                // if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
                // if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
                // if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
                // if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
                // if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
              }
              if(unitmeasure=='both'&&!unitmeasure2){
                x.push('Please choose a unit measure 2');
              }
              if(unitmeasure=='weight'&&!unitmeasure2){
                x.push('Please choose a unit measure 2');
              }
            
      
            
      
      
      
              // Validate images
               
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };

       
      const showAlerts = () =>{
        return (
          <>
      {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
</Alert>
      )}
         
        </>
        )
      }
      const checkProd =async( ) => {
        setErrors([])
        if(isFormValid()){
            setLoader(true)
            setError(false)
            try {
                console.log('hrer')
                   let userid =localStorage.getItem('userid')
                   let storeid =localStorage.getItem('storeid')
                   const formData = new FormData();
                   formData.append('userid', userid);
                   formData.append('storeid', storeid);
                   formData.append('barcode', barcode);
                   formData.append('id', barcodeinfo.prodbarcode.id);
                   formData.append('highlight', featured);
                   formData.append('prixbulk1', prixbulk1);
                   formData.append('prixpack1', prixpack1);
                   formData.append('prixunit1', prixunit1);
                   formData.append('weightpack1', weightpack1);
                   formData.append('sku', sku);
                   const req = await fetch("https://api.trakitrak.com/merchant/prodbarcode/create", {
                    method:'POST',
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('token')}`,
                      //'Content-Type': 'multipart/form-data'
               
                    },
                    body: formData,
                   })
                 
                        if(req.status==200){
              const json = await req.json()
              console.log(json)
              setLoader(false)
              navigate('/products')
          }else{
              const json = await req.json()
              console.log(json) 
              setShowalert(true)
              setMsg(json.message)
              setLoader(false)
          }
            } catch (error) {
                console.log(error)
                console.log('hrer')
                setLoader(false)
            }
        }else{
            setLoader(false)
            setError(true)
            window.scrollTo({
              top: 0,
              behavior: "smooth" // Use smooth scrolling animation
            });
        }
      }
  return (
    <div>
 
                                 <div style={{display:'flex',width:'75%',marginLeft:'12.5%',alignItems:'center',backgroundColor:'#00000080',borderRadius:10,padding:'5px 15px 5px 15px',marginBottom:10,marginTop:20,}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                <div style={{marginLeft:10}}>
                <p style={{color:'white',fontSize:18}}>Product found</p>
                <p style={{color:'white',fontSize:15}}>We've loaded the information for you. Some fields cannot be modified, so they are disabled.</p>
                </div>
                                 </div>
                    
            {showAlerts()}
            {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
            {loader ? (
<div style={{width:'100%',height:'50vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
<div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>

) : (
    <div className="d-flex flex-column" >
    <h3  className="text-center">Add Product</h3>
     <div className="d-flex my-4 justify-content-center">
         <div className="preview-container my-shadowx rounded">
             <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
             <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
             <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
             </svg>
             <div className="preview-text">
             <p>Brand · Product name</p>
             <p style={{ fontSize: '14px' }}>$2.990</p>
             <p style={{ fontSize: '14px' }}>1 grs bag</p>
             </div>
         </div>

         <div className="preview-container my-shadowx rounded mx-5" style={{ transform: 'scale(1.1)' }}>
           {image?
           <div style={{ width: '100px', height: '50%',borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex'  }}>

          <img id="img-preview" alt='product preview' src={image} style={{ width: '70px', height: '70px',borderRadius:10  }} />
</div>
         :
         <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" id="no-prod" className="bi bi-card-image" viewBox="0 0 16 16">
         <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
         <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
         </svg>
         }
          
             <div className="preview-text">
             <p id="name" style={{color: (newBrand && newBrandName) || (selectedBrand.value && !newBrand) || name ? 'black' : '#dcdcdf'}}><span id="brandtext"></span><span id="nametext">{newBrand && newBrandName ? `${newBrandName} · ` : selectedBrand.value && !newBrand ? `${selectedBrand.label} · ` : ''}{name ? name : ''}</span></p>
             <p id="pricetext" style={{ fontSize: '14px', color: prixunit1 || prixbulk1 || prixpack1 ? 'black' : '#dcdcdf' }}>{prixunit1 ? `${prixunit1}$` : prixbulk1 ? `${prixbulk1}$` : prixpack1 ? `${prixpack1}` : 'Price' }</p>
             <p id="format" style={{ fontSize: '14px', color: format ? 'black' : '#dcdcdf' }}>{format ? format:'Format'}</p>
             </div>
         </div>

                         <div className="preview-container my-shadowx rounded">
                             <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
                             <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                             <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                             </svg>
                             <div className="preview-text">
                             <p>Brand · Product name</p>
                             <p style={{ fontSize: '14px' }}>$2.990</p>
                             <p style={{ fontSize: '14px' }}>1 grs bag</p>
                             </div>
                         </div>
         </div>
   
     <div  style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                     <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                     <label className="form-label">Brand</label>
                     <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{background: '#f1f1f1', color: 'black', maxWidth: 300}}>
                         <strong className="text-black" style={{fontFamily: 'Poppins-SemiBold', fontSize: '12px'}}>IMPORTANT:</strong>
                         <p style={{fontSize: '13px'}}>This is the manufacturer's brand. If the product is produced by your store, you do not need to enter a brand.</p>
                         <p style={{fontSize: '13px', marginTop: '5px'}}>If the brand you are looking for does not exist, you can create one. Remember to take into consideration the following tips</p>
                         <ul className="myul">
                           <li>Use capital letters only for brand names or at the beginning of the name.</li>
                         </ul>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
                     </Tippy>
                     </div>
                     <input style={{cursor:'not-allowed'}} disabled={true} value={brand}  name="name" id="name-input" type="text" className="form-control w-75" />

                  

             <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                 <label className="form-label">
                 Name <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
                 </label>
                 <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                     <div className="p-2 rounded my-shadow" style={{ background: '#f1f1f1', color: 'black' }}>
                       <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>
                         IMPORTANT:
                       </strong>
                       <ul className="myul">
                         <li>Use capital letters only at the start of the name.</li>
                         <li style={{ marginTop: '5px' }}>Do not include the product's brand in its name.</li>
                         <li style={{ marginTop: '5px' }}>The product's format (packaging goes in a different field).</li>
                       </ul>
                       <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>
                         EXAMPLES:
                       </strong>

                       <div className="d-flex align-items-center p-1">
                         <div style={{ width: '10%' }}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                           </svg>
                         </div>
                         <p className="ms-2" style={{ width: '90%' }}>Red eggs</p>
                       </div>

                       <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                         <div style={{ width: '10%' }}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                           </svg>
                         </div>
                         <p className="ms-2" style={{ width: '90%' }}>Red EGGS(12 eggs) SuperChicken</p>
                       </div>
                     </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
                     </Tippy>
             </div>
             <input disabled={true} style={{cursor:'not-allowed'}} value={name}  name="name" id="name-input" type="text" className="form-control w-75" />

           




                     <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                 <label className="form-label">Description</label>
                 <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                       <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                       <ul className="myul">
                           <li>Avoid using subjective adjectives about the product's characteristics.</li>
                           <li style={{ marginTop: '5px' }}>Be brief and objective.</li>
                           <li style={{ marginTop: '5px' }}>Use simple and formal language.</li>
                           <li style={{ marginTop: '5px' }}>HTML tags are not permitted.</li>
                       </ul>
                       <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>EXAMPLES:</strong>
                       <div className="d-flex align-items-center p-1">
                           <div style={{ width: '10%' }}>
                               <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                   <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                               </svg>
                           </div>
                           <p className="ms-2" style={{ width: '90%' }}>Sugar-free soda, lime flavor.</p>
                       </div>
                       <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                           <div style={{ width: '10%' }}>
                               <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                   <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                               </svg>
                           </div>
                           <p className="ms-2" style={{ width: '90%' }}>The best sugar-free soda. It will quench your thirst with its refreshing natural lime flavor.</p>
                       </div>
                   </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
                 </Tippy>
                 </div>
         <textarea disabled={true} style={{cursor:'not-allowed'}} value={description}  name="description" type="text" className="form-control w-75"></textarea>

         <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
             <label className="form-label">Format <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
             <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                           <p>Brief description of the product's presentation</p>
                           <ul className="myul">
                             <li>Make sure you include the product's net content and/or measurements.</li>
                           </ul>
                           <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>EXAMPLES:</strong>
                           <div className="d-flex align-items-center p-1">
                             <div style={{ width: '10%' }}>
                               <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                               </svg>
                             </div>
                             <p className="ms-2" style={{ width: '90%' }}>1.5 liter bottle</p>
                           </div>
                           <div className="d-flex align-items-center p-1">
                             <div style={{ width: '10%' }}>
                               <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                               </svg>
                             </div>
                             <p className="ms-2" style={{ width: '90%' }}>In bulk</p>
                           </div>
                           <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                             <div style={{ width: '10%' }}>
                               <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                               </svg>
                             </div>
                             <p className="ms-2" style={{ width: '90%' }}>Unit</p>
                           </div>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
         </div>
         <input disabled={true} style={{cursor:'not-allowed'}} value={format}  id="format-input" type="text" className="form-control w-75" name="format"/>

         <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
             <label className="form-label">Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
             <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p>The customer will be able to find your product in this category. It is very important that you classify your product in the corresponding related aisle.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
                 </Tippy>
         </div>
         <input disabled={true} style={{cursor:'not-allowed'}} value={cat}  id="format-input" type="text" className="form-control w-75" name="format"/>

          <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
          <label className="form-label">SKU <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>                    <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                           <p>This code must be unique among your products. If you don't have any product codes, you'll need to create codes in your file.</p>
                          
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
         </div>
        
         <input   value={sku} onChange={(e)=>setSku(e.target.value)}  name="sku" id="sku-input" type="text" className="form-control w-75" />
    
           <label className="form-label mt-4 w-75">Images</label>
             
             <div style={{cursor:'not-allowed'}} id="main-img-div" className="rounded border p-2 w-75">
                       
                       <div className="border-bottom pb-2"  style={{cursor:'not-allowed'}}>
                         <label htmlFor="img1" className="w-100 label-input">
                           {/* <input
                             //onChange={handleImageChange}
                            
                             required
                             accept="image/*"
                             style={{ display: 'none' }}
                             type="file"
                             name="img1"
                             id="img1"
                             //onChange={handleImg1Change}
                         /> */}
                       
                      
                           <div className="d-flex align-items-center" style={{cursor:'not-allowed'}}>
                                 <div>
                                   <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                     {!image ?
                                     <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                      : 
                                      <img alt='img1' className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={image}></img>
                                      }
                                   </div>
                                 </div>

                                 <div className="d-flex flex-column justify-content-center ms-2">
                                      <p>{ImageName?ImageName:'Image 1'}</p>
                                   {/*      <span style={{ color: 'gray', fontSize: '13px' }}>{ImageSize?Number(ImageSize*1000).toFixed(0)+'KB':'Choose...'}</span>
                                 */}
                                  </div>
                           </div>
                         </label>
                       </div>

                       {otherImages.map((otherimg,i) => {
                         return (
                           <div className={`${i < 3 ? 'border-bottom pb-2' : ''}`}>
                           <label htmlFor={"img" + i+2} className="w-100 label-input">
                           
                             <div className="d-flex align-items-center justify-content-between" style={{ cursor: 'not-allowed' }}>
                               <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>

                                   <div>
                                     <div className="d-flex p-2 rounded" style={{border: "0.125rem dashed black", height: 37, width: 37}}>
                                       {!otherimg.value ?
                                       <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"></path></svg>
                                        : 
                                        <img alt={"img" + i+2} className="img-uploaded" style={{width: '100%', height: '100%', objectFit: 'contain'}} src={otherimg.value}></img>
                                        }
                                     </div>
                                   </div>

                                   <div className="d-flex flex-column justify-content-center ms-2">
                                     <p>{otherimg.label?otherimg.label:`Image ${i+2}`}</p>
                                     {/* <span style={{ color: 'gray', fontSize: '13px' }}>{ImagesSize[i]?Number(ImagesSize[i]*1000).toFixed(0)+"KB":`Choose...`}</span> */}
                                   </div>

                               </div>

                                   {/* <div onClick={() => setOtherImages(otherImages.filter(x => x.key !== otherimg.key))} class="close-img">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                                   </div> */}
                             </div>
                           </label>
                         </div>
                         )
                         })}
                       

                     
                     {/* { otherImages.length < 4 &&

                     <div onClick={() => {
                       setOtherImages([...otherImages, {key: Math.random(), value: null,}]);
                     }} 
                     id="add-img" 
                     className="p-2 mt-2 justify-content-between align-items-center" 
                     style={{ display: 'flex', cursor: 'pointer' }}
                     >
                         <div className="d-flex align-items-center">
                           <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                         <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                         </svg>
                         <p className="ms-2">Add another image</p>
                         </div>
                         <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
                         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                         <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                         <g id="SVGRepo_iconCarrier">
                         <title>plus-circle</title>
                         <desc>Created with Sketch Beta.</desc>
                         <defs></defs>
                         <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                         <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                             <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup"></path>
                         </g>
                         </g>
                     </g>
                     </svg>
                     </div>
                     } */}

                     </div>
                     {/* <div className="mt-1 w-75">
                     <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                     </div> */}
             
     </div>
          
    {/* unit measure  */}
             <div style={{display:'flex',alignSelf:'center'}} className="unitmeasuresmaindiv mt-4" id="unitmeasuremaindiv">

         
         <div
        //   onClick={() => {
        //    setUnitMeasure('unit');
        //    setUnitMeasure2(null);
        //  }}
         className="measurediv unitdiv" style={{opacity: unitmeasure == 'unit' ? 1 : 0.5,cursor:'not-allowed',  transform: `scale(${unitmeasure == 'unit' ? 1.04 : 1})`}}>
             <div className="measure-first">
               <input
               checked={unitmeasure == 'unit'}
                   id="unit-check"
                   style={{ marginLeft: '10px' }}
                   value="unit"
                   type="radio"
                   name="unitmeasure"
               />
               <div className="measure-text">
                   <p>By unit</p>
                   <p>Example: soda can</p>
               </div>
             </div>
             <div className="measure-second">
               <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/pepsi.png'} alt="soda-can" style={{ cursor:'not-allowed',width: '100%', top: '10px' }} />
             </div>
         </div>

         <div style={{ flex: '0.1' }}></div>

         <div 
        //    onClick={() => {
        //    setUnitMeasure('weight');
        //    setUnitMeasure2(null);
        //  }}
         className="measurediv bulkdiv" style={{opacity: unitmeasure == 'weight' ? 1 : 0.5,cursor:'not-allowed', transform: `scale(${unitmeasure == 'weight' ? 1.04 : 1})`}}>
             <div className="measure-first">
             <input
                 checked={unitmeasure == 'weight'}
                 id="weight-check"
                 type="radio"
                 name="unitmeasure"
                 value="weight"
                 style={{ marginLeft: '10px' }}
             />
             <div className="measure-text" style={{ color: 'black' }}>
                 <p>By Weight</p>
                 <p>Example: Lemon</p>
             </div>
             </div>
             <div className="measure-second">
             <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/lemon.png'} alt="lemon" style={{ width: '100%', top: '10px' }} />
             </div>
         </div>
         
         </div>

         {/* UNIT AND WEIGHT INPUTS */}
         {unitmeasure == 'unit'?
           <div id="unit-inputs" className="mt-3 w-75 m-auto">
           <label className="form-label w-100">Price per unit</label>
           <input value={prixunit1} onChange={e=>setPrixunit1(e.target.value)} disabled={unitmeasure=='unit'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

           <label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>                    
         <span style={{marginLeft:5}}>
         <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p>This value won't appear to the customer, however it is required for our internal systems.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
         </span>
             </label>
           <input value={unit} disabled={true} min="1" name="unit" className="form-control w-100" type="number" />
         </div>
         :
         unitmeasure == 'weight'?
         <div className="mt-3 w-75 m-auto">
         <div className='d-flex'>
         <div className="form-check">
             <input checked={unitmeasure2 == 'bulk'} disabled={true} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">weight                  
          <span style={{marginLeft:5}}>
          <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
          </span>
             </label>
         </div>

         <div className="form-check ms-4">
             <input checked={unitmeasure2 == 'package'} disabled={true} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">package                     
            <span style={{marginLeft:5}}>
            <Tippy
           
           placement='left'
           theme='tomato'
           render={attrs => (
             <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                 <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
             </div>

           )}
           >
             <i id="brand-tip">
               <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
             </i>
   </Tippy>
            </span>
             </label>
         </div>
         {/* <i className="ms-1" id="package-tip">
           <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
         </i>   */}


         <div className="form-check ms-4">
             <input checked={unitmeasure2 == 'both'} disabled={true} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">weight &amp; package
             
             <span>                    <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
                       <ul style={{marginTop: 5}}>
                         <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
                       </ul>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy></span>
             </label>
         </div>


         </div>

         {/* inputs unitmeasure2 */}
         <div className='mt-3'>
           {unitmeasure2 == 'bulk' ?
           (
             <>
               <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
               <input value={prixbulk1} onChange={e=>setPrixbulk1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='bulk'?false:true} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
             </>
           )
           : unitmeasure2 == 'package' ?
             (
               <>
                 <label className="form-label w-100">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                 <input value={prixpack1} onChange={e=>setPrixpack1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='package'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

                 <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                 <input value={weightpack1} onChange={e=>setWeightpack1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='package'?false:true} min="1" name="unit" className="form-control w-100" type="number" />
               </>
             )
           : unitmeasure2 == 'both' ? (
             <>
             <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
             <input value={prixbulk1} onChange={e=>setPrixbulk1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='both'?false:true} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
           
             <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
             <input value={prixpack1} onChange={e=>setPrixpack1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='both'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

             <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
             <input value={weightpack1} onChange={e=>setWeightpack1(e.target.value)} disabled={unitmeasure=='weight'&&unitmeasure2=='both'?false:true}  min="1" name="unit" className="form-control w-100" type="number" />
             </>
             ) : null
           }
         </div>
         {/* inputs unitmeasure2 */}

         </div>
         :
         null
         }
       
       
         {/* UNIT AND WEIGHT INPUTS */}

    {/* unit measure  */}
    <div style={{display:'flex',alignSelf:'center',justifyContent:'center',alignItems:'center',width:'100%',cursor:'not-allowed'}}  id="unitmeasuremaindiv">
   <div
  
     style={{opacity: unitmeasure == 'both' ? 1 : 0.5,cursor:'not-allowed',  transform: `scale(${unitmeasure == 'both' ? 1.04 : 1})`}} className="weightunitdiv" id="weightunitdiv">
             <div className="measure-first">
                 <input checked={unitmeasure == 'both'} id="weightunit-check" style={{marginLeft:10}} type="radio" name="unitmeasure"
                     value="both"/>
                 <div className="measure-text">
                     <p>By unit and weight</p>
                     <p>Example: pieces of meat</p>
                 </div>
             </div>
             <div className="measure-second">
                 <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/meat.png'} alt="meat" style={{top:-30}}/>
             </div>
         </div>
   </div>

   {unitmeasure == 'both' && (
     <>
     <div id="unit-inputs" className="mt-3 w-75 m-auto">
       <label className="form-label w-100">Price per unit</label>
       <input value={prixunit1} onChange={e=>setPrixunit1(e.target.value)} disabled={unitmeasure=='both'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

       <label className="form-label w-100 mt-3">Weight per unit<span style={{color:'gray', fontSize:'13px'}}> (in grams)</span>
       <span style={{marginLeft:5}}>
       <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p>This value won't appear to the customer, however it is required for our internal systems.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
             </span>
       </label>
       
       <input value={unit} disabled={true} min="1" name="unit" className="form-control w-100" type="number" />
     </div>


     <div className="mt-3 w-75 m-auto">
     <div className='d-flex'>
         <div className="form-check">
             <input checked={unitmeasure2 == 'bulk'} disabled={true} value="bulk" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">weight                  
             <span style={{marginLeft:5}}>
             <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p style={{fontSize: 14}}>In this case, products are sold only by kilograms such as 1 KG Tomato.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
             </span>
             </label>
         </div>

         <div className="form-check ms-4">
             <input checked={unitmeasure2 == 'package'} disabled={true} value="package" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">package                    
         
             <span style={{marginLeft:5}}>    <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p style={{fontSize: 14}}>Some packed products like meat have different weights. For this type of product, you must include the approximate weight per unit.</p>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy></span>
             </label>
         </div>
         {/* <i className="ms-1" id="package-tip">
           <svg stroke="red" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="2.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" stroke-width="2.5" stroke-linecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
         </i>   */}


         <div className="form-check ms-4">
             <input checked={unitmeasure2 == 'both'} disabled={true} value="both" className="form-check-input weight-radiox" type="radio" name="unitmeasure2"/>
             <label className="form-check-label">weight &amp; package
             
             <span>                    <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <p style={{fontSize: 14}}>some products may be requested by the
customer either by bulk (1 kg of apples) or by units (5 apples). Check this option if the product can be ordered by weight or unit.</p>
                       <ul style={{marginTop: 5}}>
                         <li  style={{fontSize: 14}}>If that is the case, do not forget to include the products approximate weight per unit.</li>
                       </ul>
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy></span>
             </label>
         </div>


         </div>

         {/* inputs unitmeasure2 */}
         <div className='mt-3'>
           {unitmeasure2 == 'bulk' ?
           (
             <>
               <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
               <input value={prixbulk1} onChange={e=>setPrixbulk1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='bulk'?false:true} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
             </>
           )
           : unitmeasure2 == 'package' ?
             (
               <>
                 <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
                 <input value={prixpack1} onChange={e=>setPrixpack1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='package'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

                 <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
                 <input value={weightpack1} onChange={e=>setWeightpack1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='package'?false:true} min="1" name="unit" className="form-control w-100" type="number" />
               </>
             )
           : unitmeasure2 == 'both' ? (
             <>
             <label className="form-label w-100">Price per Kilogram<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
             <input value={prixbulk1} onChange={e=>setPrixbulk1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='both'?false:true} min="1" id="prixbulk-input" name="prixbulk1" className="form-control w-100 prixinput" type="number" />
           
             <label className="form-label w-100 mt-2">Price per package<span style={{color:'gray', fontSize:'13px'}}> (required)</span></label>
             <input value={prixpack1} onChange={e=>setPrixpack1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='both'?false:true} min="1" id="prixunit-input" name="prixunit1" className="form-control w-100 prixinput" type="number" />

             <label className="form-label w-100 mt-3">Weight per package<span style={{color:'gray', fontSize:'13px'}}> (required in grams)</span></label>
             <input value={weightpack1} onChange={e=>setWeightpack1(e.target.value)} disabled={unitmeasure=='both'&&unitmeasure2=='both'?false:true} min="1" name="unit" className="form-control w-100" type="number" />
             </>
             ) : null
           }
         </div>
         {/* inputs unitmeasure2 */}

         </div>


     </>

   )}



    {/* product tags */}
    <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-5 w-75">
    <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
             <label className="form-label">   Product tags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span></label>
             <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                           <p>This information is optional, but it can be important for the Shopper to know if there’s any product that needs to be treated with special care.
</p>
                       
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
         </div>
         </div>
    <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" w-75">
  
<div className="row ms-0 me-0" >
 <div className="col-sm-6">
   <div  className="tag-container rounded mt-4 bg-primary d-flex align-items-center px-3" style={{ height: '100px',cursor:'not-allowed' }}>
     <input checked={tags.includes("Frozen")}  
     value="Frozen" name="tags[]" type="checkbox" />
     <div className="ms-3">
       <h6 className="m-0">Frozen</h6>
       <p>Example: Ice</p>
     </div>
   </div>
 </div>

 <div className="col-sm-6">
   <div  className="bg-danger mt-4 d-flex align-items-center px-3 tag-container rounded" style={{ height: '100px',cursor:'not-allowed', background: 'linear-gradient(to right, rgb(121 55 89 / 80%) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
     <input checked={tags.includes("Alcohol")}   value="Alcohol" name="tags[]" type="checkbox" />
     <div className="ms-3 text-white">
       <h6 className="m-0">Alcohol</h6>
       <p>Example: Beer</p>
     </div>
   </div>
 </div>

 <div className="col-sm-6">
   <div  className="d-flex align-items-center px-3 mt-4 tag-container rounded" style={{ height: '100px',cursor:'not-allowed', background: 'linear-gradient(to right, rgb(48 206 62) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
     <input checked={tags.includes("Fragile")}   value="Fragile" name="tags[]" type="checkbox" />
     <div className="ms-3">
       <h6 className="m-0">Fragile</h6>
       <p>Example: Cake</p>
     </div>
   </div>
 </div>

 <div className="col-sm-6">
   <div  className="tag-container rounded d-flex align-items-center px-3 mt-4" style={{ height: '100px',cursor:'not-allowed', background: 'linear-gradient(to right, rgb(206 48 160) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
     <input checked={tags.includes("Tobacco")}   value="Tobacco" name="tags[]" type="checkbox" />
     <div className="ms-3 text-white">
       <h6 className="m-0">Tobacco</h6>
       <p>Example: Cigarette</p>
     </div>
   </div>
 </div>
</div>
</div>

    {/* product tags */}
    <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-5 w-75">
    <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
             <label className="form-label">Featured</label>
             <Tippy
                     placement='left'
                     theme='tomato'
                     render={attrs => (
                       <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                           <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                           <p>It corresponds to the most commercial, eye-catching, and attractive products. They will also be used as a reference within Trakitrak to differentiate the location of the products in the aisles.</p>
                       
                       </div>

                     )}
                     >
                       <i id="brand-tip">
                         <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                       </i>
             </Tippy>
         </div>
         </div>
  {/* <label style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}} className="form-label mt-4 w-75">featured</label> */}
     <select value={featured} onChange={(e) => setFeatured(e.target.value)} style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}} className="form-select w-75" name="highlight" id="status">
         <option value="1">Yes</option>
         <option value="0">No</option>
     </select>

     <div className="my-5 w-75" style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}}>
         <input onClick={()=>{checkProd()}} type="submit" value="ADD PRODUCT" className="btn btn-primary buttonx w-100" />
     </div>
  {/* </div> */}

</div>
)}

    </div>
  )
}
