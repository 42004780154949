import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData } from '../utils';
import Spinner from 'react-bootstrap/Spinner';
export default function Stores() {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchStores= async (signal)=>{
      const {status, data, msg} = await fetchData(`/stores/${localStorage.getItem('userid')}`);
      setStores(data);
      setLoading(true)
     
      }
    useEffect(()=>{
        fetchStores()
    },[])
  return (
  
  <div className='stores'>
      <h2>Stores</h2>


      {loading?
      <>
      {stores.length==0?
       <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
   <svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  height="3em" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="black" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No Stores</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents Stores will appear here.</p>
         </div>:
         <>
             <hr />
       {stores.map((store) => (
        <a onClick={()=>{
          if(store.branches.length==0){
            localStorage.setItem('isfirstbranch','true')
          }else{
            localStorage.setItem('isfirstbranch','false')
          }
          if( store.branches.length==0&&store.conf === 0){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/branch/add`)
          }
          if( store.branches.length==0&&store.conf === 1){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/branch/add`)
          }
          if( store.branches.length==0&&store.conf === 2){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/stores/edit/${store.id}`)
          }
          if( store.branches.length!==0&&store.conf === 2){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/stores/edit/${store.id}`)
          }
           if(store.branches.length!==0&&store.conf === 1&&store.status==1){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/stores/${store.id}`)
          }
          if(store.branches.length!==0&&store.conf === 1&&store.status==0){
           console.log('test')
          }
          if(store.branches.length!==0&&store.conf === 1&&store.status==2){
            localStorage.setItem('storeid',store.id)
            localStorage.setItem('storename',store.name)
            navigate(`/branch/edit/${store.branch.id}`)
          }
          if( store.branches.length!==0&&store.conf === 0){
            console.log('')
            // localStorage.setItem('storeid',store.id)
            //   localStorage.setItem('storename',store.name)
            //   navigate(`/stores/edit/${store.id}`)
          }
         
          
       
       
        }} key={store.id}  className="my-shadow rounded store-container mb-3 storesss">
          <div className="img-container rounded-start"  style={{ backgroundColor:store.conf === 1&&store.status==1? store.color:'#fafbff',alignItems:'center',display:'flex',justifyContent:'center' }}>
           {store.conf === 1&&store.status==1?
           <img src={store.image} alt={store.name} style={{width:'90%',height:'90%',objectFit:'contain'}} />:
           <svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  height="3em" viewBox="0 0 512 512">

           <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           fill="#d1d1d1" stroke="none">
           <path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
           -146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
           229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
           -310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
           734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
           l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
           <path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
           l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
           0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
           158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
           -456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
           -1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
           0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
           </g>
           </svg>
           } 
          </div>
          <div style={{ flex: 0.01 }}></div>
       
          <div className="text-container" style={{width:'100%'}}>
            <div>
            <h3 className="storename">{store.name}</h3>
           <div style={{display:'flex',alignItems:'center'}}>
            <div className="storename" style={{width:12,height:12,borderRadius:10,backgroundColor: store.branches.length!=0&&store.conf === 2?'red':store.branches.length==0&&store.conf === 2?'red':store.branches.length==0&&store.conf === 0?'gray':store.branches.length==0&&store.conf === 1?'gray':store.branches.length!=0&&store.conf === 1&&store.status==1 ?'green':store.branches.length!=0&&store.conf === 1&&store.status==0?'gray':store.branches.length!=0&&store.conf === 1&&store.status==2 ? 'red':'gray'}}/>
            <p style={{marginLeft:5}}>
              {
               store.status == 0 && store.conf === 0?'Incomplete application':
               store.status == 0 && store.conf === 1?'Incomplete application':
               store.status == 0 && store.conf === 2?'Rejected application':
               store.conf === 2?'Rejected application':
               store.conf === 1 && store.status == 1?'Published store':
               store.conf === 1 && store.status == 0?'TrakiTrak is reviewing your application':
               store.conf === 1 && store.status == 2?'Rejected application':
               store.conf === 0?'TrakiTrak is reviewing your application'
               :null}
            </p>
           </div>
           </div>

           <svg className='arroww' style={{marginLeft:5,opacity:0.5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1819 4027 c-64 -34 -102 -93 -107 -166 -8 -112 -33 -83 601 -718
                l582 -583 -582 -583 c-634 -635 -609 -606 -601 -718 8 -110 93 -189 206 -189
                29 0 70 7 90 17 42 18 1314 1280 1365 1353 36 52 47 133 27 196 -10 32 -142
                169 -679 707 -446 447 -679 674 -707 687 -57 28 -138 27 -195 -3z"/>
                </g>
            </svg>

          </div>
          
          <div  className={(store.branches.length!==0&&store.conf === 0)||(store.branches.length!==0&&store.conf === 1&&store.branches.conf==0)||(store.branches.length!==0&&store.conf === 1&&store.status==1)?"text-container2":"text-container1"}>
            {store.branches.length==0&&store.conf === 0||store.branches.length==0&&store.conf === 1?
           <svg style={{marginRight:5,}} version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="18.000000pt" height="18.000000pt" viewBox="0 0 512.000000 512.000000"
           preserveAspectRatio="xMidYMid meet">
     <g><path  fill="#ffc107" d="M 251.5,53.5 C 268.807,52.905 282.973,59.2383 294,72.5C 353.919,175.336 413.586,278.336 473,381.5C 483.256,400.375 482.922,419.042 472,437.5C 463.042,449.066 451.209,455.566 436.5,457C 315.833,457.667 195.167,457.667 74.5,457C 46.6471,452.793 31.9804,436.627 30.5,408.5C 30.0466,398.692 32.5466,389.692 38,381.5C 96.586,280.663 154.919,179.663 213,78.5C 221.798,63.8554 234.631,55.522 251.5,53.5 Z"/></g>
     <g><path  fill="#fffefd" d="M 251.5,190.5 C 258.666,189.488 263.832,192.155 267,198.5C 267.667,240.167 267.667,281.833 267,323.5C 263.617,331.63 257.784,334.13 249.5,331C 246.865,329.039 245.031,326.539 244,323.5C 243.333,281.833 243.333,240.167 244,198.5C 245.69,194.979 248.19,192.312 251.5,190.5 Z"/></g>
     <g><path  fill="#fffefb" d="M 249.5,356.5 C 263.162,354.341 269.662,360.008 269,373.5C 267.932,378.235 265.099,381.402 260.5,383C 244.119,384.279 238.619,377.113 244,361.5C 245.812,359.7 247.645,358.033 249.5,356.5 Z"/></g>
           </svg>:null
          }
          
              {store.branches.length!==0&&store.conf === 1&&stores.status==2?'See reasons for rejection':
              store.branches.length==0&&store.conf === 2?'See reasons for rejection':
              store.branches.length!==0&&store.conf === 2?'See reasons for rejection':
              store.branches.length==0&&store.conf === 0?'The application is incomplete and has not been sent':
              store.branches.length==0&&store.conf === 1?'The application is incomplete and has not been sent':
              null
             }
             <svg className='arroww2' style={{marginLeft:5,opacity:0.5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M1819 4027 c-64 -34 -102 -93 -107 -166 -8 -112 -33 -83 601 -718
                l582 -583 -582 -583 c-634 -635 -609 -606 -601 -718 8 -110 93 -189 206 -189
                29 0 70 7 90 17 42 18 1314 1280 1365 1353 36 52 47 133 27 196 -10 32 -142
                169 -679 707 -446 447 -679 674 -707 687 -57 28 -138 27 -195 -3z"/>
                </g>
                </svg>
          </div>
           
    
     
        </a>
      ))}
        </>
}    

      </>
      :
      <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Spinner style={{marginTop:'15%'}} animation="grow" />
        </div>
      
      }
     
    </div>
  )
}
