import React, { useEffect, useState , useRef } from 'react'
import { fetchData, sendData } from '../utils';
import Modal from 'react-bootstrap/Modal';
import PaymentDetails from './PaymentDetails';

export default function Payments() {
            const [searchQuery, setSearchQuery] = useState('');
            const [filteredOrders, setFilteredOrders] = useState([]);
            const [modalData, setModalData] = useState({from:'',to:'',num: '', date: '', prix: '', 'com': '', tax: '', final: '', comiss: '', file: '',taxperc:'',fixed:''});
            const [orders, setOrders] = useState([]);
            const [showmodal, setshowmodal] = useState(false);
            const [loader, setLoader] = useState(false);
            const [payments, setPayments] = useState([]);
            const [page, setPage] = useState(1);
            const [count, setCount] = useState(1);
            const [limit, setLimit] = useState(100);
            const [totalpages, setTotalpages] = useState(100);
            const currentDate = new Date();
            const [search, setSearch] = useState('');
            const formattedDate = currentDate.toISOString().slice(0, 7);
            //const [filter, setFilter] = useState(formattedDate);
            const [filter, setFilter] = useState('');
            useEffect(() => {
              const filterOrders = () => {
                if (!searchQuery) {
                  setFilteredOrders(orders);
                } else {
                  const filtered = orders.filter((order) =>
                    order.checkoutbranch?.number?.toString().includes(searchQuery)
                  );
                  setFilteredOrders(filtered);
                }
              };
          
              filterOrders();
            }, [searchQuery, orders]);
          
            const handleSearchChange = (e) => {
              setSearchQuery(e.target.value);
            };
            function formatDate(dateString) {
              const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
              ];
              const date = new Date(dateString);
              const year = date.getFullYear();
              const month = months[date.getMonth()];
              const day = date.getDate();
              let hours = date.getHours();
              const minutes = date.getMinutes();
              const ampm = hours >= 12 ? 'PM' : 'AM';
              hours = hours % 12;
              hours = hours ? hours : 12; // the hour '0' should be '12'
              const minutesStr = minutes < 10 ? '0'+minutes : minutes;
            
              return `${month} ${day}, ${year} ${hours}:${minutesStr} ${ampm}`;
            }
            function convertTo12HourFormat(time24) {
              // Assuming the input is a string in "HH:MM" format
        
              const [hours, minutes] = time24.split(':');
              
              // Create a Date object. The date doesn't matter since we're only interested in time.
              // Here, we're setting the date to "January 1, 1970" with the provided hours and minutes.
              const date = new Date(1970, 0, 1, hours, minutes);
              
              // Convert to 12-hour time format with AM/PM. Specify the locale as 'en-US' and use options to get the desired format.
              const time12Hour = date.toLocaleTimeString('en-US', {
                  hour: 'numeric', // "numeric" for the hour, which could be 1 through 12.
                  minute: '2-digit', // "2-digit" for the minute, ensuring two digits are always displayed.
                  hour12: true // Ensure we're using the 12-hour format.
              });
              
              return time12Hour;
          }
            const handleClose = () => setshowmodal(false);
            const fetchpauments = async () => {
              if(localStorage.getItem('storeid')){
   
              const {status, data, msg} = await sendData('/payments', JSON.stringify({storid: localStorage.getItem('storeid'),userid: localStorage.getItem('userid'),page,filter}));
              if(status){
              console.log(data)
              setLoader(true)
              setPayments(data.payments)
              }
            }else{
              setLoader(true)
            }
            }
            const getPaymentOrder= async (id)=>{
              console.log(id)
                const {status, data, msg} = await fetchData(`/payments/${id}}`);
                setOrders(data)              
                }
                function formatDate(dateString) {
                  const options = { year: 'numeric', month: 'short', day: 'numeric' };
                  return new Date(dateString).toLocaleDateString('en-US', options);
                }
            useEffect(()=>{
              fetchpauments()
            },[page,orders])
  return (
    <div className="container" style={{marginTop:'4%'}}>
        <h2>Settlement</h2>
        <div id="root" className="payments-container">
        {/*  <div className="d-flex align-items-center">
     <input
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        type="month"
        className="form-control w-25"
        name="filter"
      />
      <a
        className="btn buttonx-outline ms-3"
        onClick={()=>fetchpauments()}
      >
        Filter
      </a>
      </div> */}
      {!loader ? (

<div style={{width:'100%',height:300,justifyContent:'center',alignItems:'center',display:'flex'}}>
<div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
  </div>
      ):
      (
        <>
        {payments.length>0?
        <div style={{marginBottom:15}}>
         {payments.map((payment, i) => (
        <div
          onClick={() => {
            setModalData({
              from:payment.from,
              to:payment.to,
              num: payments.length-i,
              date: payment.dater,
              prix: payment.prix,
              com: payment.comtotal,
              tax: payment.tax,
              final: payment.finaltotal,
              comiss: payment.comiss,
              file: payment.filer,
              taxperc:payment.taxperc,
              fixed:payment.fixed_commission
            });
            getPaymentOrder(payment.id)
            setshowmodal(true)
            //setOrders(payment.settlchecks);
          }}
          className="payment-cont mt-3 px-3 py-2 d-flex align-items-center justify-content-between"
          style={{ cursor: 'pointer',borderRadius:9,height:95 }}
          data-bs-toggle="modal"
          data-bs-target="#payModal"
          key={i}
        >
          <div>
            <p className="m-0" style={{ fontSize: '18px', fontFamily: 'Poppins-SemiBold' }}>
            Settlement #{payments.length-i}
            </p>
            <div className="d-flex align-items-center" style={{marginTop:3}}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="12.000000pt" height="12.000000pt" viewBox="0 0 256.000000 256.000000"
                preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M604 2548 c-31 -15 -44 -50 -44 -122 l0 -64 -153 -3 c-149 -4 -154
                -5 -219 -37 -76 -37 -120 -82 -157 -162 l-26 -55 0 -925 0 -925 26 -55 c37
                -80 81 -125 157 -162 l67 -33 1025 0 1025 0 55 26 c80 3a7 125 81 162 157 l33
                67 0 925 0 925 -33 67 c-37 76 -83 120 -162 157 -50 23 -69 26 -207 29 l-153
                4 0 64 c0 76 -13 109 -51 125 -22 9 -36 9 -58 0 -38 -16 -51 -49 -51 -126 l0
                -65 -560 0 -560 0 0 65 c0 75 -13 108 -47 124 -29 13 -41 13 -69 -1z m1793
                -1568 l3 -695 -23 -40 c-12 -22 -40 -50 -62 -62 l-40 -23 -995 0 -995 0 -40
                23 c-22 13 -49 40 -62 62 l-23 40 0 691 c0 380 3 694 7 697 3 4 506 6 1117 5
                l1111 -3 2 -695z"/>
                </g>
            </svg>
              <p className="text-muted" style={{ margin: '0px 0px 0px 10px' }}>
                 {formatDate((payment.dater).substring(0,10))}
                
              </p>
            </div>
          </div>
          <div className="h-100 d-flex justify-content-center align-items-center">
            <p className="m-0">Amount: ${payment.finaltotal}</p>
          </div>
        </div>
      ))}
        </div>
        :
        <>
         <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
         <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M832 4940 c-237 -62 -422 -259 -462 -490 -7 -46 -10 -646 -8 -2075
l3 -2010 21 -40 c46 -88 156 -137 251 -112 29 8 172 88 360 200 171 103 316
187 323 187 6 0 173 -97 372 -215 360 -215 361 -215 420 -215 32 0 74 7 94 15
19 8 185 105 370 216 222 133 342 199 354 196 11 -3 159 -90 331 -193 296
-179 314 -188 370 -192 80 -6 137 17 188 74 22 26 43 63 50 89 8 31 11 434 11
1399 l0 1355 298 3 297 3 57 27 c80 37 152 107 191 187 l32 66 0 520 0 520
-24 74 c-66 201 -236 357 -447 411 -75 20 -117 20 -1728 19 -1592 0 -1654 -1
-1724 -19z m2783 -317 c-5 -10 -18 -43 -29 -73 -21 -55 -21 -55 -26 -2001 l-5
-1947 -239 143 c-132 79 -260 149 -285 157 -57 16 -162 16 -217 0 -23 -7 -189
-100 -368 -207 l-325 -195 -293 176 c-161 97 -306 184 -323 194 -43 26 -131
50 -181 50 -97 0 -139 -18 -392 -170 l-247 -149 -3 1891 c-1 1209 1 1904 8
1927 24 91 100 170 193 202 49 17 132 18 1396 19 1275 0 1343 -1 1336 -17z
m659 -17 c60 -29 103 -73 138 -139 23 -42 23 -46 26 -511 3 -429 1 -470 -14
-487 -16 -18 -36 -19 -281 -19 l-263 0 0 493 c0 473 1 494 21 537 33 73 93
126 169 151 46 15 147 3 204 -25z"/>
<path d="M1295 3736 c-120 -53 -120 -228 -1 -286 43 -20 50 -21 724 -18 673 3
681 3 709 24 15 11 38 36 51 54 46 68 18 176 -58 219 -37 21 -43 21 -716 21
-541 -1 -685 -3 -709 -14z"/>
<path d="M1318 2888 c-50 -13 -104 -74 -113 -127 -9 -53 25 -126 74 -158 l34
-23 691 0 691 0 36 24 c97 66 91 210 -11 270 -34 21 -49 21 -700 23 -418 1
-679 -3 -702 -9z"/>
<path d="M1292 2003 c-127 -62 -110 -254 26 -293 25 -8 251 -10 709 -8 663 3
672 3 700 24 100 75 98 201 -6 271 l-34 23 -681 0 c-608 0 -685 -2 -714 -17z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No payments results found</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again with a different date</p>
        </div>
        </>

        }
       
        </>
      )
        }


      


    </div>
       <Modal  scrollable dialogClassName="custom-modal" centered show={showmodal} onHide={handleClose}>
       <Modal.Header style={{height:50,alignItems:'center'}}>
      
        <h4 style={{ fontFamily: "Poppins-Regular",fontSize:18,margin:0 }}>Settlement {modalData.num}</h4>
        <div style={{display:'flex'}}>
   
          {/* <a download target="_blank" href={`https://trakitrak-prod.s3.eu-west-1.amazonaws.com/payments/merchants/${modalData.file}`} className="downloadbtn btn btn-danger" style={{ marginRight: "5px" }}>Download PDF</a> */}
          <button onClick={()=>setshowmodal(false)} data-bs-dismiss="modal" style={{borderColor:'#cecece',color:'#24292e',minHeight:34,padding:'0px 7px',backgroundColor:'transparent',border:'2px solid #cecece',borderRadius:7}}>Close</button>
        </div>
  
       </Modal.Header>
    <div className="modal-content" style={{border:'none'}}>
      <div className='downloadpayments'>
      <div className="d-flex align-items-center" style={{width:'70%',marginLeft:10}}>
      <div style={{cursor:'pointer',width:'auto',minWidth:'40%',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:35}} className="form-control">

       <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
        <input
         value={searchQuery}
         onChange={handleSearchChange}
          type="text"
          id="search-order"
          name="search"
          placeholder="Search by order number"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}

          // className="form-control input-order"
        />
        {searchQuery!=''?   <svg onClick={()=>setSearchQuery('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
</div>

        
          </div>
          
          <div className='testbtn' style={{marginRight:10,width:'30%',display:'flex',alignItems:'center',justifyContent:'flex-end',}}>
                                        <a download target="_blank" href={`https://trakitrak-prod.s3.eu-west-1.amazonaws.com/payments/merchants/${modalData.file}`} className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ cursor: "pointer",}}>
                                            <svg style={{marginRight:5}} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="red" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                        </a>
        </div>
        <div className="downloadd align-items-center" style={{marginRight:10}}>
                                        <a download target="_blank" href={`https://trakitrak-prod.s3.eu-west-1.amazonaws.com/payments/merchants/${modalData.file}`} className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "red",maxWidth:130, cursor: "pointer",}}>
                                            <svg style={{marginRight:5}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            <p className="text-white ms-1" style={{ fontSize: "13px", }}>Download</p>
                                        </a>
        </div>
      </div>

      <div className="modal-body" style={{ backgroundColor: "rgb(242, 242, 242)" }}>
        <div className="pb-5">
        <p className="font-weight-bold titlepayment" style={{ fontFamily: "Poppins-Regular" }}>General information</p>
        <div className='titlepayment' style={{ backgroundColor: "white", padding: "5px 10px", borderRadius: "10px",}}>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0">Issue date</p>
              <p className="m-0">{formatDate(modalData.date)} at {convertTo12HourFormat(modalData.date.substring(11, 16) )}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pay-detail-contx">
              <p className="m-0 font-weight-bold">Period</p>
              <p className="m-0 font-weight-bold">{formatDate(modalData.from)} to {formatDate(modalData.to)}</p>
            </div>
            
          </div>
     
          <p className="font-weight-bold titlepayment" style={{  fontFamily: "Poppins-Regular" }}>Settlement details</p>
          <div className='titlepayment'style={{ backgroundColor: "white", padding: "5px 10px", borderRadius: "10px" }}>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0">Sales</p>
              <p className="m-0">${modalData.prix}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Received</p>
              <p className="m-0 text-muted">${modalData.prix}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Commission of TrakiTrak</p>
              <p className="m-0 text-muted">-${modalData.com}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Tax</p>
              <p className="m-0 text-muted">-${modalData.tax}</p>
            </div>
            {modalData.fixed!=0&&(
              <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contx">
              <p className="m-0 text-muted">Fixed Commission</p>
              <p className="m-0 text-muted">-${modalData.fixed}</p>
            </div>
            )}
            
            
            <div className="d-flex justify-content-between align-items-center pay-detail-contx">
              <p className="m-0 font-weight-bold">Paid</p>
              <p className="m-0 font-weight-bold">${Number(Number(modalData.prix)-Number(modalData.final)).toFixed(2)}</p>
            </div>
          </div>
          <p className="font-weight-bold titlepayment" style={{  fontFamily: "Poppins-Regular" }}>Orders ({orders.length})</p>
          <div className="row g-3 orderpayment" style={{ margin: "0px 0px 0px" }}>
            {filteredOrders.map((order) => {
              const total = order.checkoutbranch?.productstotal;
              const comtotal = parseFloat((total * (modalData.comiss / 100)).toFixed(2));
              const tax = parseFloat((comtotal * (modalData.taxperc / 100)).toFixed(2));
             // const fixed = parseFloat(modalData.fixed).toFixed(2);
              const finaltotal = parseFloat((total - comtotal - tax).toFixed(2));

              return (
                <PaymentDetails
                order={order}
    total={total}
    comtotal={comtotal}
    finaltotal={finaltotal}
    tax={tax}
    fixed={0}
    />
              );
            })}
          </div>
        </div>
      </div>
    </div>
 

       </Modal>
    </div>
  )
}
