import React, {useState, useEffect} from 'react'
import { fetchData, sendData } from '../../utils';
import { useNavigate } from 'react-router-dom';

const ProductsSearched = () => {
    const [loading, setLoading]= useState(true);
    const [branches, setBranches] = useState([]);
    const [data, setData] = useState([]);
    const [branchid, setBranchid] = useState('0');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const navigate = useNavigate();

    const getData = async () => {
        if(!loading) setLoading(true)
        const {status, data, msg} = await sendData(`/getreportsdata`, JSON.stringify(
            {
            type: 'products-searched', 
            storeid: localStorage.getItem("storeid"),
            userid: localStorage.getItem("userid"),
            branchid,
            from,
            to
            }
        ), navigate);
        if(status){
            // let newData = [...data];
            // newData.forEach((product) => {
            //     product.perc = parseFloat(((product.cart5s.filter(x=>x.CartProdStatus == 'rejected' && x.rejectReason == 'OUT_OF_STOCK').length / product.cart5s.length) * 100).toFixed(2));
            // })
            setData(data);
            setLoading(false);
        }
    }

    const getBranches = async () => {
        const {status, data, msg} = await fetchData(`/branches/dropdown/${localStorage.getItem('storeid')}/${localStorage.getItem("userid")}`, navigate);
        if(status){
            setBranches(data);
        }
    }

    useEffect(() => {
        getData();
    }, [branchid, from, to])

    useEffect(() => {
        getBranches();
    }, [])


  return (
    <div style={{flex: 1, overflow: 'hidden'}}>
    <div className='d-flex h-100'>
        <aside className="sidebar-containerx border-end">
        <div className="py-4">
        <div className="px-3">

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Branch</p>
                    <select onChange={e => setBranchid(e.target.value)} value={branchid} name="branchid" id="branchid" className="form-select mt-2">
                    <option value="0">All branches</option>
                        {branches.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                    </select>

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>From</p>
                    <input value={from} onChange={e => setFrom(e.target.value)} type="date" name="from" id="from" className="form-control mt-2" />

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>To</p>
                    <input value={to} onChange={e => setTo(e.target.value)} type="date" name="to" id="to" className="form-control mt-2" />

        </div>

        </div>
    </aside>

    <main className="main-containerx" style={{width: '80%', position:'relative', overflow: 'scroll' }}>

        {loading ?
        <div className="py-3 px-4 d-flex justify-content-center align-items-center" style={{marginTop:'10%'}}>

            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        : 
        <div className='py-3 px-4' style={{height: '100vh', position:'absolute', left:0, top: 0,width: '100%'}}>
            {data.length > 0 ? (
                <>
                    <div class="p-2 bg-white table-container">
                        <table class="table">

                        <thead class="pnf-thead">
                            <tr class="pnf-tr-head">
                            <th scope="col">Searches</th>
                            <th scope="col">Number of searches</th>
                            <th scope="col">Branch</th>
                            <th scope="col">Last search</th>
                            </tr>
                        </thead>
                        <tbody class="table-body">
                        {data.map(product => {
                            return (
                                <tr className='pnf-tr bg-white'>
                                    <th scope="row">{product.query}</th>
                                    <td>{product.query_count}</td>
                                    <td>{product.branch?.name}</td>
                                    <td>{product.updated_atx}</td>
                                </tr>
                            )
                        }) }
                        </tbody>

                        </table>
                    </div>
                    <div style={{paddingBottom: '30px'}}>

                    </div>
                </>
            )
        :
        (
            <div style={{width:'100%',marginTop:'5%', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <p style={{fontSize:32,color:'#2b2b2b',fontWeight:'900'}}>We're sorry</p>
           <p style={{fontSize:18,color:'#888b90',fontWeight:'500'}}>We were unable to obtain the products at your store</p>
           <button onClick={()=>{getData();}} style={{backgroundColor:'#e05b0e',border:'1px solid rgba(0,0,0,0.1)',height:40,minWidth:120,padding:'9px 12px',marginTop:'1%',cursor:'pointer',borderRadius:6,boxShadow:'0 1px 3px -2px rgba(0,0,0,1)',color:'white',fontWeight:'600'}}>
            <p style={{color:'white'}}>Try Again</p>
           </button>
               </div>
        )
        }
        </div>
        }

    </main>

</div>
</div>
  )
}

export default ProductsSearched