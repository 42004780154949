import React, {useMemo, useState,useEffect} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { hasJWT } from "./AuthedRoute";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import inv from '../inv.png'
import bag from '../bag.png'
import user from '../user.png'
import groc from '../groc.png'
import stat from '../stat.png'
import store from '../store.png'
import invred from '../invred.png'
import bagred from '../bagred.png'
import userred from '../userred.png'
import grocred from '../grocred.png'
import statred from '../statred.png'
import storered from '../storered.png'

import { fetchData } from "../utils";
import { us } from "@mapbox/mapbox-gl-geocoder/lib/exceptions";

const ProtectedRoute1 = ({ component: Component, ...params }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [storename, setStorename] = useState(false);
    const { hash, pathname, search } = location;


    useEffect(()=>{
setTimeout(() => {
    let name = localStorage.getItem('storename')
    let id = localStorage.getItem('storeid')
    window.Intercom('update')
    window.Intercom('update', {"name": name,"id":id});
}, 150);
      },[])
    const fetchStores= async (signal)=>{
        console.log('fetching stores')
        const {status, data, msg} = await fetchData(`/stores/${localStorage.getItem('userid')}`);
if(status){
 
    if(data.length>0){
        console.log('fetching stores12')
        console.log(data)
        setStorename(data[0].name)
        localStorage.setItem('storeid',data[0].id)
        localStorage.setItem('storename',data[0].name)
        setShow(!show)
    }

}else{
    console.log(msg)
}
       
        }
      useEffect(()=>{
        if(localStorage.getItem('storeid')){
         console.log('test')
         window.Intercom('update')
         setShow(!show)
        }else{
      setTimeout(() => {
        fetchStores()
      }, 330);
            window.Intercom('update')
            setShow(!show)
        }
       
      },[])
    if (hasJWT()) return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>


            {/* //! HEADER */}
            <Navbar collapseOnSelect expand='lg' className="nav-auth-shadow">
                <Container fluid={true} style={{padding: '0 10%'}}>
       
      
                    <Navbar.Brand onClick={()=>navigate("/")}  style={{fontSize: 30}}>
                        <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/merchant.png'}  className="brandimage"/>
                        </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                      
                            <NavDropdown renderMenuOnMount={true} title="Account" id="collapsible-nav-dropdown" className="tt-protected-dropdown">
                                <NavDropdown.Item href="https://help-stores.trakitrak.com/en/" target="_blank" rel="noopener noreferrer">
                                <div style={{display:'flex'}}>
                                <svg style={{marginRight:10}}version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#a1a1a1" stroke="none">
<path d="M2315 5109 c-597 -61 -1141 -318 -1563 -739 -1002 -1000 -1002 -2620
0 -3620 406 -405 901 -648 1493 -732 119 -17 512 -16 635 1 580 80 1089 330
1489 732 464 467 723 1061 748 1719 26 711 -242 1393 -749 1900 -393 394 -891
643 -1453 726 -122 18 -477 26 -600 13z m390 -1280 c469 -79 674 -474 444
-855 -57 -94 -134 -179 -255 -280 -67 -56 -129 -118 -149 -148 -43 -64 -65
-148 -65 -246 0 -138 -49 -184 -194 -184 -101 0 -140 18 -167 77 -26 59 -26
235 0 339 39 153 100 236 276 378 172 139 214 196 223 306 12 158 -118 254
-330 242 -135 -7 -197 -43 -283 -164 -73 -101 -153 -119 -254 -56 -128 79
-109 268 42 416 159 156 429 223 712 175z m-111 -1974 c88 -42 145 -131 146
-224 0 -83 -19 -130 -75 -188 -104 -107 -260 -108 -362 -1 -130 136 -77 360
102 426 43 16 143 9 189 -13z"/>
</g>
</svg>
<p style={{color:'black'}}>Help</p>
                                  </div>
                           

                                    
                                    </NavDropdown.Item>
                                <NavDropdown.Item href="https://help-stores.trakitrak.com/en/articles/8476862-terms-and-conditions-for-stores-lebanon" target="_blank" rel="noopener noreferrer">
                                    <div style={{display:'flex'}}>
                                    <svg style={{marginRight:10}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#a1a1a1" stroke="none">
<path d="M765 4514 c-5 -1 -22 -5 -37 -8 -41 -9 -95 -61 -113 -109 -14 -38
-15 -183 -13 -1459 l3 -1416 31 -39 c55 -68 79 -76 250 -84 177 -9 226 -14
389 -40 440 -72 821 -252 1098 -518 l47 -45 0 1439 c0 792 -4 1457 -9 1478
-24 102 -259 362 -431 477 -255 169 -581 279 -920 309 -129 12 -280 19 -295
15z"/>
<path d="M4185 4510 c-420 -25 -755 -128 -1045 -321 -172 -113 -407 -374 -431
-476 -5 -21 -9 -686 -9 -1477 l0 -1439 53 49 c285 268 653 441 1092 513 163
26 212 31 389 40 171 8 195 16 250 84 l31 39 3 1411 c2 985 0 1423 -8 1449
-12 44 -52 93 -89 112 -40 21 -94 24 -236 16z"/>
<path d="M105 3861 c-48 -22 -69 -44 -90 -94 -13 -32 -15 -208 -15 -1522 0
-1614 -3 -1523 53 -1583 54 -59 113 -66 269 -33 597 125 1225 142 1703 46 65
-13 121 -23 123 -21 6 7 -95 90 -178 145 -207 138 -474 236 -783 287 -48 8
-183 20 -300 28 -130 9 -228 20 -254 30 -140 50 -250 165 -293 305 -19 61 -20
99 -20 1247 l0 1184 -87 0 c-64 0 -99 -6 -128 -19z"/>
<path d="M4800 2696 c0 -1148 -1 -1186 -20 -1247 -43 -140 -153 -255 -293
-305 -26 -10 -122 -21 -252 -30 -115 -7 -241 -18 -280 -24 -310 -48 -597 -152
-805 -291 -82 -55 -184 -138 -178 -145 2 -2 58 8 123 21 478 96 1106 79 1703
-46 156 -33 215 -26 269 33 56 60 53 -31 53 1585 0 1422 -1 1490 -19 1528 -36
78 -91 105 -216 105 l-85 0 0 -1184z"/>
</g>
</svg>
                                         <p style={{color:'black'}}>Terms & Conditions</p>
                                    </div>
                                   
                                    </NavDropdown.Item>

                                <NavDropdown.Item onClick={()=>{
                                    localStorage.removeItem('userid')
                                    localStorage.removeItem('storeid')
                                    localStorage.removeItem('storename')
                                    localStorage.removeItem('token')
                                    
                                navigate('/login',{replace:true})}}>
                                        <div style={{display:'flex'}}>
                                        <svg style={{marginRight:10}} xmlns="http://www.w3.org/2000/svg" version="1.0" width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#a1a1a1" stroke="none">
<path d="M325 5106 c-148 -37 -271 -159 -311 -310 -11 -42 -14 -384 -14 -2021 0 -2157 -3 -2031 57 -2135 37 -62 111 -134 169 -163 27 -13 350 -125 719 -248 l670 -224 90 0 c110 0 172 20 255 81 67 49 121 123 150 207 16 46 20 82 20 202 l0 145 353 0 c402 0 442 5 577 71 162 79 293 244 334 419 23 97 23 1033 1 1088 -67 160 -299 170 -383 17 -15 -26 -18 -89 -22 -520 l-5 -490 -25 -45 c-14 -24 -45 -58 -68 -75 l-44 -30 -359 -3 -359 -3 -2 1653 -3 1653 -32 67 c-52 110 -122 175 -242 225 l-56 23 527 -3 526 -2 44 -30 c23 -17 54 -51 68 -75 l25 -45 5 -387 5 -386 30 -43 c45 -64 104 -91 186 -87 76 4 127 32 169 95 l25 37 3 397 c3 448 0 475 -66 609 -54 110 -168 223 -282 279 -152 74 -81 71 -1455 70 -943 -1 -1242 -4 -1280 -13z"/>
<path d="M3965 4031 c-51 -23 -101 -79 -114 -128 -7 -23 -11 -169 -11 -370 l0 -333 -448 0 -448 0 -53 -26 c-90 -45 -134 -140 -110 -238 16 -67 85 -138 151 -154 32 -8 181 -12 477 -12 l431 0 0 -331 c0 -234 4 -344 12 -373 25 -81 118 -146 209 -146 80 1 107 24 567 483 461 460 492 497 492 575 0 95 6 88 -479 575 -301 301 -470 464 -497 477 -51 25 -126 25 -179 1z"/>
</g>
</svg>
                                        <p style={{color:'black'}}>Logout</p>
                                    </div>
                                    </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

           

            {/* //! HEADER */}

            {/* //! PAGE */}
            {!location.pathname.includes("reports")?
            <Container>
                <Component {...params} />
            </Container> : <Component {...params} /> }
            {/* //! PAGE */}

        </div>
    );
    
    return <Navigate to={"/login"} replace />;
}

export default ProtectedRoute1

