// export const generateURI = (path) => {
//     if(process.env.NODE_ENV === 'production') return `https://merchant.trakitrak.com${path}`;
//     return `http://merchant.localhost:8000${path}`;
// }

export const generateURI = (path) => {
    
    return `https://api.trakitrak.com/merchant${path}`;
    //return `http://merchant.localhost:8000/merchant${path}`;
}

export const sendData = async (uri, body, navigate, method='POST') => {
    try{
        //
        const req = await fetch(`${generateURI(uri)}`,
            {
                method, 
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body
            }
        );
        if(req.status === 200){
            const res = await req.json();
            return {status: true, data: res}
        } else {
            if(req.status === 498){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
            const res = await req.json();
            return {status: false, msg: res.message}
        }
    } catch(err){
        return { status: false, msg: "Server error" };
    }
} 
export const fetchData = async (uri, navigate) => {
    try{
        let userid =localStorage.getItem('userid')
        const req = await fetch(`${generateURI(uri)}?userid=${userid}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        if(req.status === 200){
            const res = await req.json();
            return {status: true, data: res}
        } else {
            if(req.status === 498){
                localStorage.removeItem('token');
                navigate('/login', {replace: true});
                return;
            }
            const res = await req.json();
            return {status: false, msg: res.message}
        }
    } catch(err){
        return { status: false, msg: "Server error" };
    }
  }