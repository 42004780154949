import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData } from '../utils';
import UserItem from './UserItem';


export default function Users() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  // const fetchUsers= async (signal)=>{
  //   const {status, data, msg} = await fetchData(`/users/${localStorage.getItem('storeid')}`);
  //  console.log({data})
  //   setUsers(data);
  //   setLoading(false)
   
  //   }
  // useEffect(()=>{
  //   fetchUsers()
  // },[])
  
  return (
    <div className="container" style={{ marginTop: '3%' }}>
       <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg> */}
<div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
{/* <svg style={{marginTop:'5%'}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="156.000000pt" height="156.000000pt" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M1785 2376 c-59 -11 -59 -12 -26 -19 52 -11 83 -34 79 -60 -3 -23 -9
-24 -93 -11 -34 5 -45 2 -69 -21 -26 -24 -28 -31 -24 -82 4 -46 10 -61 32 -80
43 -37 108 -25 135 26 9 15 4 17 -37 17 -41 -1 -47 2 -50 21 -4 27 18 40 86
49 36 4 57 3 67 -6 40 -34 -5 -143 -75 -177 -55 -28 -82 -29 -136 -2 -125 60
-124 258 1 316 25 12 26 13 5 9 -14 -3 -60 -11 -102 -18 -71 -10 -94 -21 -68
-30 6 -2 23 -72 37 -155 22 -126 24 -154 14 -167 -8 -9 -17 -16 -22 -16 -5 0
-51 39 -101 86 -51 47 -94 82 -96 76 -2 -6 3 -44 11 -85 9 -47 11 -81 5 -91
-9 -17 -43 -22 -52 -8 -10 17 -57 297 -52 315 4 17 1 18 -26 13 -17 -3 -39 -6
-50 -6 -20 0 -20 0 0 -17 17 -14 26 -48 46 -162 13 -79 22 -150 19 -158 -3 -8
-16 -13 -32 -11 -25 3 -27 7 -54 153 -21 116 -25 154 -16 169 10 17 8 18 -21
12 -18 -3 -41 -6 -51 -6 -18 0 -18 -1 -1 -18 19 -19 68 -269 60 -308 -2 -11
-13 -20 -26 -22 -27 -4 -34 13 -52 118 l-13 75 -26 -55 c-15 -32 -34 -56 -45
-58 -11 -3 -35 12 -61 38 l-42 41 15 -90 c13 -74 14 -92 3 -105 -33 -40 -50
-10 -76 135 -28 152 -30 185 -12 193 6 3 1 4 -13 2 -14 -2 -61 -10 -104 -17
l-79 -12 42 -13 c23 -6 49 -18 58 -26 25 -20 51 -87 58 -148 8 -73 -14 -116
-77 -148 -90 -45 -175 3 -198 112 -21 104 -5 154 65 198 l30 19 -35 -6 c-171
-29 -231 -43 -195 -44 51 -1 87 -25 83 -54 -3 -20 -8 -22 -64 -19 -55 3 -63 1
-78 -19 -19 -28 -20 -41 -5 -109 16 -74 69 -97 121 -53 26 23 63 13 63 -17 0
-33 -56 -70 -107 -70 -83 0 -131 48 -148 148 -13 78 -5 109 38 156 l27 30 -25
-16 c-30 -18 -70 -84 -70 -117 0 -56 113 -658 129 -686 20 -35 85 -75 121 -75
14 0 165 25 335 55 330 58 337 59 316 46 -11 -7 -4 -61 40 -312 29 -167 54
-306 55 -308 4 -5 218 30 226 38 9 8 -100 613 -112 621 -5 3 153 35 353 69
199 35 379 70 400 79 44 17 73 51 87 102 8 30 0 94 -49 369 -56 314 -61 335
-88 363 -53 57 -96 66 -208 44z m-167 -498 c13 -17 63 -295 55 -313 -12 -32
-50 -12 -138 70 -49 46 -90 83 -91 82 -1 -1 5 -41 13 -90 16 -96 14 -117 -16
-117 -29 0 -36 19 -62 170 -34 203 -21 213 119 84 50 -47 92 -82 92 -77 0 4
-7 46 -15 92 -11 62 -12 88 -4 98 14 16 33 17 47 1z m-353 -78 c50 -24 70 -57
84 -137 10 -52 9 -68 -5 -102 -22 -56 -66 -86 -126 -86 -85 0 -135 60 -145
172 -4 60 -2 72 19 103 24 36 79 70 112 70 11 0 38 -9 61 -20z m-311 -55 c43
-21 73 -76 81 -150 13 -116 -41 -180 -144 -172 -47 3 -59 9 -86 39 -40 44 -64
146 -46 201 11 34 53 80 81 90 29 11 82 7 114 -8z m-261 -37 c17 -25 0 -47
-40 -53 -49 -8 -67 -18 -71 -40 -6 -25 10 -36 59 -39 79 -6 121 -83 84 -155
-17 -34 -57 -52 -121 -54 -40 -2 -49 1 -52 17 -5 25 7 35 64 49 40 10 50 16
52 36 5 29 -12 41 -59 41 -62 0 -110 63 -95 124 11 43 40 67 96 81 63 15 70
15 83 -7z m444 -384 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M1159 1732 c-18 -15 -22 -28 -21 -66 3 -65 25 -114 56 -122 37 -9 57
-2 79 28 18 24 19 32 9 85 -18 86 -69 118 -123 75z"/>
<path d="M834 1666 c-20 -31 -10 -126 16 -158 22 -27 72 -28 101 -2 18 16 21
27 16 77 -7 80 -27 107 -77 107 -31 0 -43 -5 -56 -24z"/>
<path d="M1368 2303 l-87 -15 32 -26 c18 -14 65 -57 105 -96 60 -58 72 -66 68
-46 -30 147 -33 186 -13 193 21 9 -13 6 -105 -10z"/>
<path d="M908 2222 c-49 -9 -87 -18 -87 -21 2 -11 129 -124 134 -120 2 3 20
40 39 82 40 88 48 83 -86 59z"/>
<path d="M561 2074 c-22 -16 -23 -22 -18 -83 4 -55 9 -69 32 -88 34 -30 60
-29 92 3 23 23 25 30 19 77 -4 29 -15 63 -25 77 -22 31 -67 37 -100 14z"/>
</g>
</svg> */}
          <img src={'./work-in-progress.png'} alt='trakitrak shoppers logo'  style={{height:'20%',width:'50%', objectFit:'contain' }} />

<div style={{width:'50%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
<h1 style={{fontWeight:'900'}}>Coming Soon</h1>
  <p style={{textAlign:'center',marginBottom:10}}>Our user registration page is under construction. We will notify you once we have finished. In the meantime, you can enjoy the different functions that we currently have available.</p>
  <a onClick={()=> navigate(`/stores/${localStorage.getItem('storeid')}`)} class="home-button">Go to Store</a>

</div>
 </div>
{/* <p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents users will appear here.</p> */}
         </div>
    {/* <div className="d-flex justify-content-between align-items-center">
      <h3>Admins</h3>
      <a  className="btn buttonx text-white" >+New user</a>
    </div>

    {loading?
            <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>

       <div style={{position:'absolute',left:'45%',top:'45%'}} className="spinner-border" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
     </div>:
<>
{users.length==0?
     <>
       <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No results</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents users will appear here.</p>
         </div>
     </>:
         <div className="table-responsive mt-4" style={{ borderRadius: '10px', boxShadow: '0 2px 4px -2px rgba(0,0,0,.2), 0 4px 8px -4px rgba(0,0,0,.2)', borderRadius: '10px' }}>

     <table className='table'  style={{ boxShadow: '0 2px 4px -2px rgba(0,0,0,.2), 0 4px 8px -4px rgba(0,0,0,.2)', borderRadius: '10px' }}>
        <thead style={{ lineHeight: '70px',marginLeft:'5%' }}>
          <tr > 
            <th scope="col" width="40%">Name</th>
            <th scope="col" width="25%">Role</th>
            <th scope="col" width="25%">Branches</th>
            <th scope="col" width="10%"></th>
          </tr>
        </thead>
     
   
     <tbody>
  
     {users.map(admin => (
       <UserItem admin={admin} fetchUsers={fetchUsers}/>
     ))}
   </tbody>
   
      
       

      </table>
      </div>
}

      
      
</>
}   */}

  </div>
  )
}
