import React ,{useState,useEffect} from 'react'
import { fetchData } from '../utils';
import { useNavigate, Link ,useParams} from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown';
import { Alert, Modal, Button } from 'react-bootstrap';

export default function EditStore() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [storeinfo, setStoreInfo] = useState();
    const [citiesarray, setCitiess] = useState([]);
    const [loader, setLoader] = useState(true);
    const [modal, setModal] = useState(false);
    const handleClose = () => setModal(false);

    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [storecategories, setStorecategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [cities, setCities] = useState([]);
    const [headquarters, setHeadquarters] = useState('');
    const [legalName, setLegalName] = useState('');
    const [govId, setGovId] = useState('');
    const [registernumber, setregisternumber] = useState('');
    const [phone, setPhone] = useState(); // assuming it's a number input
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [email, setEmail] = useState('');
    const [slogan, setSlogan] = useState('');
    const [description, setDescription] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [ispublic, setIspublic] = useState('');
    const [signature, setSignature] = useState('');
     const [selectedNumProds, setSelectedNumProds] = useState('50');
     const [color, setColor] = useState('#ff0000');
     const [logoImage, setLogoImage] = useState(null);
     const [bannerImage, setBannerImage] = useState(null);
     const [commercialLicense, setCommercialLicense] = useState(false);
     const [receipt, setReceipt] = useState(true);
     const [sanitary, setSanitary] = useState(false);
     const [alcohol, setAlcohol] = useState(false);
     const [payPersonal, setPayPersonal] = useState(false);
     const [ownBags, setOwnBags] = useState(false);
     const [returnPolicy, setReturnPolicy] = useState(false);
     const [numBranches, setNumBranches] = useState(1); 
     const [bannerchanged, setBannerchanged] = useState(false);
     const [logochanged, setLogochanged] = useState(false);

     const fetchCities= async (signal)=>{
      const {status, data, msg} = await fetchData(`/cities/dropdown`);
      setCitiess(data)
      }
      const fetchStoreDetials= async (signal)=>{
        
        const {status, data, msg} = await fetchData(`/storeonly/${id}/${localStorage.getItem('userid')}`);
      console.log({data})
      setStoreInfo(data)
      setCompany(data.company)
      setHeadquarters(data.hq)
      setGovId(data.govid)
      setIspublic(data.defpublic?'yes':'no')
      setSignature(data.elecsig?'yes':'no')
      setAlcohol(data.alcohol_license)
      setBannerImage(data.banner)
      setNumBranches(data.branchnum)
      setSelectedOption(data.cat.id)
      setColor(data.color)
      setCommercialLicense(data.commercial_license==1?true:false)
      setDescription(data.des)
      setEmail(data.email)
      setFacebook(data.facebook)
      setLogoImage(data.image)
      setInstagram(data.insta)
      setLegalName(data.legal_name?data.legal_name:data.company)
      setName(data.name)
      setOwnBags(data.own_bags==1?true:false)
      setPayPersonal(data.pay_personal==1?true:false)
      setPhone(data.phone)
      setWebsite(data.website)
      setReceipt(data.receipt==1?true:false)
      setSelectedNumProds(data.prodtypnum)
      setregisternumber(data.regnum)
      setReturnPolicy(data.return_policy)
      setSanitary(data.sanitary_license)
      setSlogan(data.slogan)
      setTwitter(data.twiter)
      setLoader(false)
          }
      useEffect(()=>{
        fetchStoreDetials()
        fetchCities()
      },[])
     const handleLogoChange = (event) => {
       const selectedFile = event.target.files[0];
   
       // Do any additional checks or processing if needed
       setLogochanged(true)
       setLogoImage(selectedFile);
     };
     const handlebannerChange = (event) => {
        const selectedFile = event.target.files[0];
    
        // Do any additional checks or processing if needed
        setBannerchanged(true)
        setBannerImage(selectedFile);
      };
     const handleColorChange = (event) => {
       setColor(event.target.value);
       document.getElementById('color-label').innerText = event.target.value;
     };
    const handleNumProdsChange = (event) => {
      setSelectedNumProds(event.target.value);
    };
    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);
    };
    const handleOptionChangesignature = (event) => {
        setSignature(event.target.value);
      };
    const handleOptionChangeOpenTopublic = (event) => {
        setIspublic(event.target.value);
      };
    const fetchCategories= async (signal)=>{
        const {status, data, msg} = await fetchData(`/storecategories/dropdown`);
        setStorecategories(data);
       
        }
      useEffect(()=>{
        fetchCategories()
      },[])
      useEffect(()=>{
setErrors(errors)
setTimeout(() => {
    console.log({errors})  
}, 1500);
      },[errors])
      const isFormValid = () => {
          let x=[]
    
        // Validate string fields
        if (!name) x.push('Name is required');
        if (!company) x.push('Company name is required');
        if (!selectedOption) x.push('Store category is required');
        if (!cities) x.push('Cities is required');
        if (!headquarters) x.push('Headquarters is required');
        if (!ispublic) x.push('Public information is required');
        if (!signature) x.push('Signature is required');
        if (!legalName) x.push('Legal name is required');
        if (!govId) x.push('Government ID is required');
        if (!selectedNumProds) x.push('Number of products is required');
        if (!registernumber) x.push('Register number is required');
        if (!email) x.push('Email is required');
        if (!slogan) x.push('Slogan is required');
        if (!description) x.push('Description is required');
        if (!color) x.push('Color is required');
      
        // Validate numeric fields
        if (!phone) x.push('Phone must be a positive number');
        if (numBranches < 1) x.push('Number of branches must be at least 1');
      
        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) x.push('Invalid email');
      
        // Validate images
         if (!logoImage) x.push('Logo image is required');
         if (!bannerImage) x.push('Banner image is required');
      
  
        if (x.length > 0) {
          console.log('Validation Errors:', x);
          setErrors(x)
          return false;
        }
      if(x.length==0){
        setErrors([])
        return true;
      }
        // If all validations pass, return true
  
   
      };
      const handleSubmit = async() => {
        setErrors([])

  
        if (isFormValid()) {
          setLoader(true)
setError(false)
          try {
    
             let userid =localStorage.getItem('userid')
             console.log(JSON.stringify(cities))
            const formData = new FormData();

            formData.append('storid', id);
            formData.append('name', name);
            formData.append('userid', localStorage.getItem('userid'));
           formData.append('company', company);
           formData.append('categoryid', selectedOption);
           formData.append('cities', JSON.stringify(cities));
           formData.append('hq', headquarters);
          formData.append('is_public', ispublic=='yes'?1:ispublic=='no'?0:0);
          formData.append('signature', signature=='yes'?1:signature=='no'?0:0);
          formData.append('legal_name', legalName);
          formData.append('govid', govId);
          formData.append('numprods', selectedNumProds);
          formData.append('regnum', registernumber);
          formData.append('numbranches', numBranches);
          formData.append('code', '961');
          formData.append('phone', phone.toString());
          formData.append('website', website?website:null);
          formData.append('facebook', facebook?facebook:null);
          formData.append('twitter', twitter?twitter:null);
          formData.append('instagram', instagram?instagram:null);
          formData.append('email', email);
          formData.append('slogan', slogan);
          formData.append('description', description);
          formData.append('color', color);
          formData.append('logo', logoImage);
          formData.append('banner', bannerImage);
          formData.append('logochanged', logochanged?1:0);
          formData.append('bannerchanged', bannerchanged?1:0);

          formData.append('commercial_license', commercialLicense ? 1 : 0);
          formData.append('receipt', receipt ? 1 : 0);
          formData.append('sanitary', sanitary ? 1 : 0);
          formData.append('alcohol', alcohol ? 1 : 0);
          formData.append('pay_personal', payPersonal ? 1 : 0);
          formData.append('own_bags', ownBags ? 1 : 0);
          formData.append('return_policy', returnPolicy ? 1 : 0);
       
          const req = await fetch("https://api.trakitrak.com/merchant/stores/edit", {
            method:'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              //'Content-Type': 'multipart/form-data'
       
            },
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setLoader(false)
        // localStorage.setItem('isfirstbranch','true')
        // localStorage.setItem('storename',json.storename)
        // localStorage.setItem('storeid',json.storid)
        navigate('/stores', {replace: true});

        // navigate('/stores/added', {replace: true});

    }else{
        const json = await req.json()
        console.log(json) 
        setShowalert(true)
        setMsg(json.message)
        setLoader(false)
    }
          } catch (error) {
            
          }
       
        } else {
            setError(true)

        }
      };
      const onSelect=(selectedList, selectedItem) =>{
        cities.push(selectedItem.id)
                  }
      const onRemove=(selectedList, selectedItem) =>{
    
                    let indexToRemove = cities.indexOf(selectedItem.id);
        
        if (indexToRemove !== -1) {
          cities.splice(indexToRemove, 1);
        }
        
                              }
                              const showAlerts = () =>{
                                return (
                                  <>
                              {showalert&&(
 <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
                                {msg}
 </Alert>
                              )}
                                 
                                </>
                                )
                              }
  return (

    <div className="container mainhomecontainer">
   {loader?
     <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>:
   <>

         {storeinfo?.reasonn&&(
          <div className="alert alert-danger alert-dismissible fade show w-100 mt-3" style={{ backgroundColor: 'gray',border:'none', color: 'white', padding: '10px' }} role="alert">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <svg width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
            </svg>
            <strong className="ms-1" style={{ fontSize: '14px' }}>Application Rejected</strong>
          </div>
          <button  className="text-white" type="button" style={{ border: 'none', backgroundColor: 'transparent' }}>
            <div onClick={()=>setModal(true)} className="d-flex align-items-center rounded p-1 px-2 alert-btn"><span style={{ fontSize: '14px' }}>View Reason</span>
              <svg style={{ transition: 'all 0.3s', transform: 'rotate(270deg)'  }} height="1.5em" viewBox="0 0 24 24" fill="none">
                <path d="M7 10L12 15L17 10" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
             )}
                 <div className="card mb-5" style={{marginTop:'3%'}}>
                  
                 <div className="card-header d-flex justify-content-between align-items-center" >
                    <p style={{margin:0}}>Edit Store</p>
                    <input  disabled={commercialLicense==false?true:receipt==false?true:false} type="submit" onClick={handleSubmit} className="btn btn-outline-primary buttonx-outline" value="Update" />
                </div>
                {showAlerts()}
        
      
     <div className="card-body">
    
        
      
            {error && (
            <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
                <div className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                </svg>
                <strong className="ms-1">{errors[0]}</strong>
                </div>
                <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                </svg>
                </button>
            </div>
            )}
             <label className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
            <input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />
    
            <label className="form-label mt-4">Company name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
            <input required type="text" className="form-control" name="company" value={company} onChange={(e) => setCompany(e.target.value)}  />
    
          <label className="form-label mt-4">Store category <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
          <select className="form-control" value={selectedOption} onChange={handleSelectChange}>
          <option value="" disabled>Select an option</option>
          {storecategories.map((option, index) => (
            <option key={index} value={option.id}>{option.name}</option>
          ))}
        </select>
            
    
            <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
            <path d="M480 48c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48V96H224V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V96H112V24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144v96V464c0 26.5 21.5 48 48 48H304h32 96H592c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H480V48zm96 320v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM240 416H208c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM560 256c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32zM256 176v32c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM112 160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32zM256 304c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM112 320H80c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm304-48v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM400 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zm16 112v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16z"/>
            </svg>
    
            <label className="form-label mt-4">In which cities is your store located? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
            <Multiselect
            style={{chips:{background:'gray'}}}
    options={citiesarray} // Options to display in the dropdown
    //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
    onSelect={onSelect}
     onRemove={onRemove}
    displayValue="name" // Property name to display in the dropdown options
    />
    
            <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
            <path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zM448 0c-17.7 0-32 14.3-32 32V512h64V192H624c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16H480c0-17.7-14.3-32-32-32z"/>
             </svg>
    
            <label className="form-label mt-4">In what city is your headquarters located? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
            <input required type="text" className="form-control" name="headquarters" value={headquarters} onChange={(e) => setHeadquarters(e.target.value)}  />
    
    
                <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                <path d="M547.6 103.8L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.1 0 49.3-11.4 65.2-29c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4zM499.7 254.9l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3V384H128V250.6c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V384v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 252.6c-4 1-8 1.8-12.3 2.3z"/>
                  </svg>
    
                <label className="form-label mt-4 mb-0">Is your store open to the public? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span> </label>
                <p className="mb-2" style={{color: 'gray', fontSize: '13px'}}>For your store to be in Trakitrak, you need to have a physical store that Shoppers can go to. This excludes showrooms, collection points, and outlets at a different store.</p>
               <div>
               <label style={{ marginRight: '10px' }}>
      <input
        type="radio"
        style={{ marginRight: '5px' }}
        value="yes"
        checked={ispublic === 'yes'}
        onChange={handleOptionChangeOpenTopublic}
      />
      Yes
                </label>
                    <label>
                    <input
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value="no"
                        checked={ispublic === 'no'}
                        onChange={handleOptionChangeOpenTopublic}
                    />
                    No
                    </label>
               </div>
                    <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
                        <path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/></svg>
                      <label className="form-label mt-4 mb-0">Does the legal representative of your company have a registered electronic signature? <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
                      <div>
               <label style={{ marginRight: '10px' }}>
      <input
        type="radio"
        style={{ marginRight: '5px' }}
        value="yes"
        checked={signature === 'yes'}
        onChange={handleOptionChangesignature}
      />
      Yes
                </label>
                    <label>
                    <input
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value="no"
                        checked={signature === 'no'}
                        onChange={handleOptionChangesignature}
                    />
                    No
                    </label>
               </div>
    
               <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
          </svg>
          <label className="form-label mt-4">What is the name of your company's legal representative? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required type="text" className="form-control" name="legal_name" value={legalName} onChange={(e) => setLegalName(e.target.value)}  />
    
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
            <path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
          </svg>
          <label className="form-label mt-4">What is the government ID of your company's legal representative? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required type="text" className="form-control" name="govid" value={govId} onChange={(e) => setGovId(e.target.value)}  />
    
          <label className="form-label mt-4 mb-0">How many different products do you sell in your store? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <select required name="numprods" className="form-select" id="prodnum"value={selectedNumProds} onChange={handleNumProdsChange} >
            <option value="50">20 to 50 products</option>
            <option value="100">51 to 100 products</option>
            <option value="1000">101 to 1000 products</option>
            <option value="1001">more than 1000 products</option>
          </select>
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} height="1em" viewBox="0 0 24 24" fill="rgb(30,48,80)" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2L8.93417 2C8.04768 1.99995 7.28387 1.99991 6.67221 2.08215C6.01669 2.17028 5.38834 2.36902 4.87868 2.87868C4.36902 3.38835 4.17027 4.0167 4.08214 4.67221C3.9999 5.28387 3.99995 6.04769 4 6.93417L4 7V10.5V16.1707C2.83481 16.5825 2 17.6938 2 19C2 20.6569 3.34315 22 5 22H15.9966L16 22C17.6569 22 19 20.6569 19 19V9.00001V7.00001H19.5C20.8807 7.00001 22 5.88072 22 4.50001C22 3.11929 20.8807 2.00001 19.5 2.00001C19.3961 2.00001 19.2937 2.00634 19.1932 2.01865C19.1307 2.00641 19.0661 2 19 2H9ZM13.1707 20C13.0602 19.6872 13 19.3506 13 19V18H5C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H13.1707ZM19 5.00001H19.5C19.7761 5.00001 20 4.77615 20 4.50001C20 4.22386 19.7761 4.00001 19.5 4.00001C19.2239 4.00001 19 4.22386 19 4.50001V5.00001ZM8 7C8 6.44772 8.44772 6 9 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H9C8.44772 8 8 7.55228 8 7ZM9 10C8.44772 10 8 10.4477 8 11C8 11.5523 8.44772 12 9 12H14C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10H9Z" fill="#000000"></path>
          </g>
        </svg>
          <label className="form-label mt-4 mb-0">Company registration number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required type="text" className="form-control" name="regnum" value={registernumber} onChange={(e) => setregisternumber(e.target.value)}  />
          <label className="form-label mt-4 mb-0">How many branches do you have in total? <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required min="1" type="number" className="form-control"  name="numbranches"   value={numBranches}
            onChange={(e) => setNumBranches(e.target.value)} />
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
          </svg>
    
          <label className="form-label mt-4 mb-0">Country code <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <div className="my-select">
            <select required name="code" className="small-drop small-select">
              <option value="961">+961</option>
            </select>
          </div>
    
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
          <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
        </svg>
    
          <label className="form-label mt-4">Phone number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required min="0" type="number" className="form-control" name="phone " value={phone} onChange={(e) => setPhone(e.target.value)} />
       
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <style>{`svg{fill:#1e3050}`}</style>
            <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
          </svg>
    
          <label className="form-label mt-4">Website</label>
          <input type="text" className="form-control" name="website" value={website} onChange={(e) => setWebsite(e.target.value)} />
    
    
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} height="1em" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title>Facebook-color</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Color-" transform="translate(-200.000000, -160.000000)" fill="#4460A0">
                <path d="M225.638355,208 L202.649232,208 C201.185673,208 200,206.813592 200,205.350603 L200,162.649211 C200,161.18585 201.185859,160 202.649232,160 L245.350955,160 C246.813955,160 248,161.18585 248,162.649211 L248,205.350603 C248,206.813778 246.813769,208 245.350955,208 L233.119305,208 L233.119305,189.411755 L239.358521,189.411755 L240.292755,182.167586 L233.119305,182.167586 L233.119305,177.542641 C233.119305,175.445287 233.701712,174.01601 236.70929,174.01601 L240.545311,174.014333 L240.545311,167.535091 C239.881886,167.446808 237.604784,167.24957 234.955552,167.24957 C229.424834,167.24957 225.638355,170.625526 225.638355,176.825209 L225.638355,182.167586 L219.383122,182.167586 L219.383122,189.411755 L225.638355,189.411755 L225.638355,208 L225.638355,208 Z" id="Facebook"></path>
              </g>
            </g>
          </g>
        </svg>
          <label className="form-label mt-4">Facebook</label>
          <input type="text" className="form-control" name="facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
    
          <svg  style={{marginRight:'0.5%',fill: '#1da1f2'}}xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
          <style>{`svg{fill:#1da1f2}`}</style>
          <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
        </svg>
          <label className="form-label mt-4">Twitter</label>
          <div className="input-group">
            <div className="input-group-text">@</div>
            <input type="text" className="form-control" name="twitter" value={twitter} onChange={(e) => setTwitter(e.target.value)}/>
          </div>
          <svg  style={{marginRight:'0.5%'}} height="1em" viewBox="0 0 200 200">
            <defs>
              <linearGradient id="gradient1" x1=".8" y1=".8" x2="0">
                <stop offset="0" stopColor="#c92bb7" />
                <stop offset="1" stopColor="#3051f1" />
              </linearGradient>
              <radialGradient id="gradient2" cx=".2" cy="1" r="1.2">
                <stop offset="0" stopColor="#fcdf8f" />
                <stop offset=".1" stopColor="#fbd377" />
                <stop offset=".25" stopColor="#fa8e37" />
                <stop offset=".35" stopColor="#f73344" />
                <stop offset=".65" stopColor="#f73344" stopOpacity="0" />
              </radialGradient>
              <rect id="logoContainer" x="0" y="0" width="200" height="200" rx="50" ry="50" />
            </defs>
            <use xlinkHref="#logoContainer" fill="url(#gradient1)" />
            <use xlinkHref="#logoContainer" fill="url(#gradient2)" />
            <rect x="35" y="35" width="130" height="130" rx="30" ry="30" fill="none" stroke="#fff" strokeWidth="13" />
            <circle cx="100" cy="100" r="32" fill="none" stroke="#fff" strokeWidth="13" />
            <circle cx="140" cy="62" r="9" fill="#fff" />
          </svg>
    
          <label className="form-label mt-4">Instagram</label>
          <div className="input-group">
            <div className="input-group-text">@</div>
            <input type="text" className="form-control" name="instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
          </div>
          <svg style={{fill: 'rgb(30,48,80)',marginRight:'0.5%'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <style>{`svg{fill:#e05b0e}`}</style>
            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
          </svg>
    
          <label className="form-label mt-4">Email <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input  required type="email" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
    
          <label className="form-label mt-4">Slogan <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <input required type="text" className="form-control" maxLength="30" name="slogan" value={slogan} onChange={(e) => setSlogan(e.target.value)}/>
    
          <label className="form-label mt-4">Description <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <textarea required type="text" className="form-control" maxLength="140" name="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
    
          <hr />
          <label className="form-label mt-4">Store color <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <div className="d-flex flex-column">
            <label className="form-control" style={{ color: 'gray' }}>
              <span id="color-label">{color}</span>
              <input
                required
                type="color"
                name="color"
                id="color"
                style={{ width: 0, height: 0, visibility: 'hidden' }}
                onChange={handleColorChange}
              />
            </label>
          </div>
          <label className="form-label mt-4">Logo <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <div className="bg-primary rounded d-flex p-2">
            <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <style>{`svg{fill:#ffffff}`}</style>
              <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
            </svg>
            <div style={{ width: '95%', color: 'white' }}>
              <p>We're going to generate a black and white version of the logo. For this reason, it must meet some requirements:</p>
              <ol>
                <li>It must be in PNG format.</li>
                <li>It must have a transparent background.</li>
                <li>Its width must be at least 1,000 px.</li>
              </ol>
            </div>
          </div>
    
          <div className="d-flex mt-3 rounded my-shadow w-75 m-auto" style={{ position: 'relative' }}>
            <div className="camera-div">
              <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="logo">
                <input required name="logo" id="logo" type="file" accept="image/*" className="d-none" onChange={handleLogoChange} />
                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                  <style>{`svg{fill:#ffffff}`}</style>
                  <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                </svg>
              </label>
            </div>
    
            <div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#b2b2b2', width: '50%',  borderTopRightRadius: '0 !important', borderBottomRightRadius: '0 !important' }}>
            {logoImage ?(
    
            <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
              <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(1000%)'}} src={!logochanged?logoImage:URL.createObjectURL(logoImage)} alt="Logo Preview" />
            </div>
          ):
          <svg xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
          <style>{`svg{fill:#ffffff}`}</style>
          <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
        </svg>
          }
             
    
            </div>
    
            <div className="rounded d-flex justify-content-center align-items-center logodiv11" style={{ backgroundColor: '#f8f8f9', width: '50%',  borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0 !important' }}>
            {logoImage ?(
       <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%'}}>
             <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(0%)'}} src={!logochanged?logoImage:URL.createObjectURL(logoImage)} alt="Logo Preview" />
            </div>
          ):
              <svg style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="6em" viewBox="0 0 512 512">
                <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
              </svg>
            }
            </div>
          </div>
          <label className="form-label mt-5">Banner <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
          <div className="bg-primary rounded d-flex p-2">
            <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <style>{`svg{fill:#ffffff}`}</style>
              <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
            </svg>
            <div style={{ width: '95%', color: 'white' }}>
              <p>The banner needs to match the following conditions:</p>
              <ol>
                <li>The width must be at least 1296px.</li>
                <li>The height must be at least 540px.</li>
              </ol>
            </div>
          </div>
    
          <div className="mt-3 logodiv22" style={{ width: '90%',marginLeft:'5%',  backgroundColor: '#b2b2b2', position: 'relative' }}>
            <div id="banner_cameradiv" className="camera-div" style={{zIndex:10}}>
              <label className="w-100 h-100 d-flex align-items-center justify-content-center" htmlFor="banner">
                <input required name="banner" id="banner" type="file" accept="image/*" className="d-none" onChange={handlebannerChange} />
                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                  <style>{`svg{fill:#ffffff}`}</style>
                  <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                </svg>
              </label>
            </div>
           
            <div className="rounded d-flex justify-content-center align-items-center bannerdiv" style={{ backgroundColor: '#b2b2b2', width: '100%', height: '100%' }}>
            {bannerImage &&(
       <div style={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100%',height:'100%',}}>
             <img style={{width:'85%',height:'100%',objectFit:'contain',filter: 'brightness(100%)'}} src={!bannerchanged?bannerImage:URL.createObjectURL(bannerImage)} alt="Logo Preview" />
            </div>
          )}
            </div>
          </div>
    
          <div className="bg-secondary rounded d-flex p-2 mt-4">
            <svg style={{ width: '5%' }} height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <style>{`svg{fill:#ffffff}`}</style>
              <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ffffff"></path>
            </svg>
            <div style={{ width: '95%', color: 'white' }}>
              <p style={{ fontSize: '18px' }}>Check the appropriate boxes if any of the following options apply to your store</p>
              <p>At a later step, we'll ask you for documents that verify the options you checked. For more information, you can visit the Help Center.</p>
            </div>
          </div>
          <label className="form-label mt-5" style={{ fontFamily: 'Poppins-SemiBold' }}>Legal documents</label>
    
    <div className="border p-3" style={{ borderBottom: '0 !important' }}>
      <div className="form-check">
        <input disabled={true} name="commercial_license" className="form-check-input" type="checkbox" id="flexCheckDefault1" value={commercialLicense} checked={commercialLicense}   onChange={() => setCommercialLicense(!commercialLicense)} />
        <label className="form-check-label" htmlFor="flexCheckDefault1">
          Commercial license for each branch.
        </label>
      </div>
    </div>
    
    <div className="border p-3" style={{ borderBottom: '0 !important' }}>
      <div className="form-check">
        <input disabled={true} name="receipt" className="form-check-input" type="checkbox" id="flexCheckDefault2" value={receipt} checked={receipt}   onChange={() => setReceipt(!receipt)} />
        <label className="form-check-label" htmlFor="flexCheckDefault2">
          Physical sales receipt 
        </label>
      </div>
    </div>
    
    <div className="border p-3" style={{ borderBottom: '0 !important' }}>
      <div className="form-check">
        <input name="sanitary" className="form-check-input" type="checkbox" id="flexCheckDefault3"  value={sanitary} checked={sanitary}    onChange={() => setSanitary(!sanitary)}/>
        <label className="form-check-label" htmlFor="flexCheckDefault3">
          Sanitary authorization permit for each branch. <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
        </label>
      </div>
    </div>
    
    <div className="border p-3">
      <div className="form-check">
        <input name="alcohol" className="form-check-input" type="checkbox" id="flexCheckDefault4" value={alcohol} checked={alcohol}   onChange={() => setAlcohol(!alcohol)} />
        <label className="form-check-label" htmlFor="flexCheckDefault4">
          Alcohol license  <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
        </label>
      </div>
    </div>
    
    <label className="form-label mt-3" style={{ fontFamily: 'Poppins-SemiBold' }}>Payment information</label>
    
    <div className="border p-3">
      <div className="form-check">
        <input name="pay_personal" className="form-check-input" type="checkbox" id="flexCheckDefault5" value={payPersonal}  checked={payPersonal}   onChange={() => setPayPersonal(!payPersonal)}  />
        <label className="form-check-label" htmlFor="flexCheckDefault5">
          I want to receive payments in my personal account <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
        </label>
      </div>
    </div>
    
    <label className="form-label mt-3" style={{ fontFamily: 'Poppins-SemiBold' }}>Other specifications about my store</label>
    
    <div className="border p-3" style={{ borderBottom: '0 !important' }}>
      <div className="form-check">
        <input name="own_bags" className="form-check-input" type="checkbox" id="flexCheckDefault6" value={ownBags} checked={ownBags}    onChange={() => setOwnBags(!ownBags)} />
        <label className="form-check-label" htmlFor="flexCheckDefault6">
          Has own bags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
        </label>
      </div>
    </div>
    
    <div className="border p-3">
      <div className="form-check">
        <input name="return_policy" className="form-check-input" type="checkbox" id="flexCheckDefault7" value={returnPolicy} checked={returnPolicy}      onChange={() => setReturnPolicy(!returnPolicy)} />
        <label className="form-check-label" htmlFor="flexCheckDefault7">
          Has return policy <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span>
        </label>
      </div>
    </div>
            </div>
   
    
    </div>
    <Modal  scrollable     centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={modal} onHide={handleClose}>
  <Modal.Header>
  <Modal.Title>Application details</Modal.Title>
        <div className="modal-header-buttons">
        <Button onClick={handleClose}  variant="outline-secondary" >Close</Button>
        </div>
  </Modal.Header>
<Modal.Body>
<div className="fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: '#107afa', color: 'white', padding: '10px',borderRadius:7,height:50,marginBottom:10}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">Rejected application</strong>
            </div>
        </div>
<span style={{color:'#888b90',fontSize:14,fontWeight:'600',marginTop:10}}>
  REASONS FOR REJECTION
</span>
<div className='reason' style={{marginBottom:10}}>
<div dangerouslySetInnerHTML={{ __html: storeinfo?.reasonn }}></div>

</div>
<span style={{color:'#888b90',fontSize:14,fontWeight:'600',marginTop:10}}>
GENERAL INFORMATION
</span>
<div className='reason' style={{marginBottom:10,display:'flex',alignItems:'center'}}>
<div  style={{ backgroundColor:storeinfo?.color,width:100,height:100,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:7}}>
           <img src={storeinfo.image} alt={storeinfo.name} style={{width:'90%',height:'90%',objectFit:'contain'}} />
          </div>
          <p style={{marginLeft:10,fontSize:22,color:'black'}}>{localStorage.getItem('storename')}</p>

</div>
</Modal.Body>

</Modal>
    </>
   
 
     }
{/*  */}
    </div>
  )
}
