import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav } from 'react-bootstrap'

export const hasJWT = () => {

    let flag = false;

    //check if the user has JWT token
    localStorage.getItem("token") ? (flag = true) : (flag = false);
    return flag;
  };

function AuthedRoute({ component: Component }) {
  const navigate = useNavigate()
useEffect(()=>{
  window.Intercom('shutdown')
  setTimeout(() => {
    window.Intercom('shutdown')
  }, 1000);
},[])
  if (!hasJWT()){
    return (
      <div>

        <Navbar collapseOnSelect expand='lg' className="bg-body-tertiary nav-auth-shadow">
          <Container fluid={true} style={{padding: '0 10%'}}>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto">
                      <Nav.Link onClick={()=>navigate("/login")} style={{color:'#e05b0e',fontSize:16,fontWeight:'bold'}}>Login</Nav.Link>
                   <div style={{backgroundColor:'#e05b0e',borderRadius:20}}>
                   <Nav.Link style={{padding:10,color:'white',fontSize:16,fontWeight:'bold'}} eventKey={2}
                   onClick={()=>navigate("/register")}
                >
                          Get Started
                      </Nav.Link>
                   </div>
                  </Nav>
              </Navbar.Collapse>
          </Container>
        </Navbar>
        <Component />
      </div>
    )
  }  
  else return <Navigate to={"/home"} replace />;
}

export default AuthedRoute;
