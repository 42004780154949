import React ,{useState,useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { Alert, Button } from 'react-bootstrap'
import { fetchData, sendData } from '../../utils';
import PhoneInput, {isValidPhoneNumber, isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
export default function Register() {  
  const navigate = useNavigate()
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [bname, setBname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cityid, setCityid] = useState();
  const [catid, setCatid] = useState();
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [storecategories, setStorecategories] = useState([]);
  const [citiesarray, setCitiess] = useState([]);
  const [value, setValue] = useState()

  const fetchCategories= async (signal)=>{
        const {status, data, msg} = await fetchData(`/storecategories/dropdown`);
        setStorecategories(data);
        setCatid(data[0].id)
       
        }
        const fetchCities= async (signal)=>{
            const {status, data, msg} = await fetchData(`/cities/dropdown`);
            setCitiess(data)
            setCityid(data[0].id)
            }
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setCityid(selectedValue);
      };
      const handleSelectChange1 = (event) => {
        const selectedValue = event.target.value;
        setCatid(selectedValue);
      };
      useEffect(()=>{
        fetchCities()
        fetchCategories()
      },[])
      const isValidLebaneseNumber = (phoneNumber) => {
        // Regular expression for Lebanese phone numbers
        const lebaneseNumberRegex = /^(?:\+961|961)?(3\d{6}|7\d{7})$/;
      
        // Check if the phone number matches the Lebanese format
        return lebaneseNumberRegex.test(phoneNumber);
      };
      const isValidStrongPassword = (password) => {
        // Regular expression for a strong password
        //const strongPasswordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
        const strongPasswordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z\d]{8,}$/;

        // Check if the password matches the strong password criteria
        return strongPasswordRegex.test(password);
      };
      const isFormValid = () => {
        let x=[]
          
              // Validate string fields
              if (!bname) x.push('Business Name is required');
              // if (!cityid) x.push('Address is required');
              // if (!catid) x.push('Store Category is required');
              if (!phone) x.push('Phone Number is required');
              if (!email) x.push('Email is required');
              if (!password) x.push('Password is required');
              if (!confirmpassword) x.push('Confirm Password is required');
              // if(!isValidPhoneNumber(phone))x.push('Invalid Phone Number');
              if(!isValidLebaneseNumber('+961'+phone))x.push('Invalid Phone Number');
              if(password!=confirmpassword){x.push('Password and Confirm Password do not match');}
              if (!isValidStrongPassword(password)) {
                x.push('Password should contain at least 1 digit, 1 letter (uppercase or lowercase), and be at least 8 characters long.');
              }
           
            
              // Validate email
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(email)) x.push('Invalid email');  
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
            const handleSubmit = async() => {
              setErrors([])
              const {status, data, msg} = await sendData('/register', JSON.stringify({name:bname, password:password,email:email,phone:phone}), navigate);
              if(status){
                console.log(data)
                // localStorage.setItem("token", data.token);
                // localStorage.setItem("userid", data.userid);
                navigate('/login', {replace: true});
              }else{
                setShowalert(true)
                setMsg(msg)
              }
        
              if (isFormValid()) {
           
                setError(false)

             
             
              } else {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth" // Use smooth scrolling animation
                });
                  setError(true)
      
              }
            };
            const showAlerts = () =>{
              return (
                <>
            {showalert&&(
          <Alert key={'warning'} variant={'warning'} onClose={() => setShowalert(false)} dismissible>
              {msg}
          </Alert>
            )}
               
              </>
              )
            }
  return (
    <>

      
                 {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}
        {showAlerts()}
        <img src={'./merchant.png'}  style={{height:55,width:370, objectFit:'contain',marginTop:'5%',marginBottom:'1%' }} />
        <div style={{width:'100%',marginBottom:'5%',paddingLeft:'3%',alignSelf:'center',flexDirection:'column',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e05b0e'}}>  
            <h1 style={{color:'white',fontFamily: 'Poppins',paddingBottom:15,paddingTop:15}}>Unlock more profits with TrakiTrak</h1>
     <div style={{zIndex:10,padding:16,marginBottom:-16,color:'rgb(25, 25, 25)',marginRight:'3.5%',backgroundColor:'white',borderRadius:8,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px'}}>
        <div>
            <p>
            Partner with TrakiTrak to help drive growth and take your business to the next level.
            </p>
            <div>
               <input placeholder='Business Name' value={bname} onChange={(e)=>setBname(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',marginTop:'4%',height:45,borderRadius:8}} />
               <input placeholder='Business Phone' value={phone} onChange={(e)=>setPhone(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',marginTop:'2%',height:45,borderRadius:8}} />

               {/* <select style={{border:'none',paddingLeft:5,color:'black',backgroundColor:'#ebebed',width:'100%',marginTop:'2%',height:35,borderRadius:8}} value={cityid} onChange={handleSelectChange}>
                        <option style={{color:'gray'}} value="" disabled>Business Address</option>
                        {citiesarray.map((option, index) => (
                            <option key={index} value={option.id}>{option.name}</option>
                        ))}
             </select> */}
               {/* <input placeholder='Business Address' style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',marginTop:'2%',height:35,borderRadius:8}} /> */}
               {/* <div style={{display:'flex',marginTop:'2%',justifyContent:'space-between',alignItems:'center'}}> */}
               {/* <select style={{border:'none',paddingLeft:5,color:'black',backgroundColor:'#ebebed',width:'49%',height:35,borderRadius:8}} value={catid} onChange={handleSelectChange1}>
                        <option style={{color:'gray'}} value="" disabled>Select your business type</option>
                        {storecategories.map((option, index) => (
                            <option key={index} value={option.id}>{option.name}</option>
                        ))}
             </select> */}
             {/* <PhoneInput
             style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'49%',height:35,borderRadius:8}}
            placeholder="Enter phone number"
            value={phone ? phone : ''}
            defaultCountry='LB'
            initialValueFormat='national'
            className='form-control'
            onChange={setPhone}
          /> */}
               {/* <input placeholder='Business Phone' value={phone} onChange={(e)=>setPhone(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'49%',height:35,borderRadius:8}} />
               </div> */}
               <input placeholder='Email Address' value={email} onChange={(e)=>setEmail(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',marginTop:'2%',height:45,borderRadius:8}} />
               <div style={{display:'flex',marginTop:'2%',justifyContent:'space-between',alignItems:'center'}}>
               <div style={{ display: 'flex', alignItems: 'center', width: '49%', }}>
               <input type={showPassword ? 'text' : 'password'} placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',height:45,borderTopLefttRadius: 8, borderBottomLeftRadius: 8 }} />
                  <div onClick={()=>setShowPassword(!showPassword)}  style={{ border: 'none', backgroundColor: '#ebebed',display:'flex',alignItems:'center',justifyContent:'center', height: 45,width:35, borderTopRightRadius: 8, borderBottomRightRadius: 8, cursor: 'pointer' }}>
                  <img src={showPassword?'./eyeopenx.png':'./eyeclosex.png'}  style={{width:20,height:20}}              />

                </div>
               </div>
               <div style={{ display: 'flex', alignItems: 'center', width: '49%', }}>
               <input  type={showPassword1 ? 'text' : 'password'} placeholder='Confirm Password ' value={confirmpassword} onChange={(e)=>setConfirmPassword(e.target.value)} style={{border:'none',paddingLeft:10,backgroundColor:'#ebebed',width:'100%',height:45,borderTopLefttRadius: 8, borderBottomLeftRadius: 8}} />
               <div onClick={()=>setShowPassword1(!showPassword1)}  style={{ border: 'none', backgroundColor: '#ebebed',display:'flex',alignItems:'center',justifyContent:'center', height: 45,width:35, borderTopRightRadius: 8, borderBottomRightRadius: 8, cursor: 'pointer' }}>
                  <img src={showPassword1?'./eyeopenx.png':'./eyeclosex.png'}  style={{width:20,height:20}}              />

                </div>
                   </div>
               </div>
               <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button onClick={handleSubmit} style={{ borderRadius: 20, marginTop: '2%',  }}>
                    Get Started
                </Button>
                </div>
                <h6>Already on TrakiTrak? <a onClick={()=>navigate('/login')}  style={{color:'#e05b0e'}}>Login</a></h6>

               {/* Thank you!
We are reviewing your submission and will get back to you soon. */}
            </div>
        </div>
     </div>
         </div>
    </>
    
  )
}
