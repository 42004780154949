import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Deal() {
    const navigate = useNavigate()
  return (
    <div className="container w-75" style={{marginTop:'12%'}}>
      
    <div className="container mainhomecontainer">
        <div className="card mb-5">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <a href="products-agree"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></a>
                    <p className="ms-2" style={{margin:0}}>Self-evaluation</p>
                </div>
                <a className="btn btn-outline-primary buttonx-outline"
                 onClick={()=>navigate('/stores/add-store',{replace:true})} >Agree and continue</a>
            </div>

            <div className="card-body pb-4">
            <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" style={{fill:'#e05b0e'}} height="4em" viewBox="0 0 512 512"><path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"/></svg>
            </div>
            <h5 className="text-center mt-4" style={{ fontFamily: 'Poppins-SemiBold' }}>So here's the deal</h5>

                <div className="d-flex flex-column align-items-center justify-content-center mt-5" >
    
                    <div className="d-flex  w-75">
                    <svg style={{marginRight:10,marginTop:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="17pt" height="17pt" viewBox="0 0 256.000000 256.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M1853 2550 c-12 -5 -26 -18 -32 -29 -7 -12 -11 -119 -11 -295 l0
-276 -280 0 c-267 0 -281 -1 -300 -20 -19 -19 -20 -33 -20 -300 l0 -280 -280
0 c-267 0 -281 -1 -300 -20 -19 -19 -20 -33 -20 -300 l0 -280 -279 0 c-260 0
-280 -1 -302 -19 l-24 -19 -3 -309 c-4 -346 0 -375 50 -392 43 -15 2412 -16
2456 0 20 7 34 21 41 41 15 43 15 2413 0 2456 -7 20 -21 34 -41 41 -38 13
-623 14 -655 1z"/>
</g>
</svg>
                    <div className="ms-2" style={{width:'90%'}}>
                       
                            <a style={{ fontFamily: 'Poppins-SemiBold' }}>Combined commission</a>
                            <p style={{ fontSize: '15px' }}>
                            Registering your store is completely free. We work with a type of commission called "combined commission". This is a percentage of each order's value,  The higher the order value, the lower the total commission percentage.
                            </p>
                            </div>
                        
                    </div>
                    <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8488621-tiered-commission-system-for-stores' style={{textAlign:'start',fontSize:16.7,color:'#007aff'}}>I want to learn more about the commissions system and see examples</a>
                    <div className="d-flex align-items-center w-75" style={{marginTop:10}}>
                
                
                    </div>




            </div>

        </div>
    </div>
</div>
</div>
  )
}
