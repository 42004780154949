import React,{useState,useEffect} from 'react'
import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Alert, Modal} from 'react-bootstrap'

export default function AddBankAccount() {
  const navigate = useNavigate()
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
    const [loader, setLoader] = useState(false);
    const [banks, setBanks] = useState([]);
    const [moneytransfer, setMoneyTransfer] = useState([]);
    
    const [name, setName] = useState('');
    const [rut, setRut] = useState('');
    const [email, setEmail] = useState('');
    const [accountnumber, setAccountnumber] = useState('');
    const [accountType, setAccountType] = useState('checking account');
    const [bankid, setBankid] = useState('');
    const [moneyid, setMoneyid] = useState('');








    const [moneyTransfers, setMoneyTransfers] = useState([]);
    const [useBank, setUseBank] = useState(true);
    const [modal, setModal] = useState(false);
    const [useMoneyTransfer, setUseMoneyTransfer] = useState(false);




    const fetchBanks= async (signal)=>{
      const {status, data, msg} = await fetchData(`/banks/dropdown`);
      if(status){
        console.log(data)
        setBanks(data.banks)
        setMoneyTransfer(data.moneytransfer_services)
      }else{

      setShowalert(true)
      setMsg(msg)

  
        setBanks([])
      }

      }
      const showAlerts = () =>{
        return (
          <>
      {showalert&&(
    <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
    </Alert>
      )}
         
        </>
        )
      }
      const isFormValid = () => {
        let x=[]
          
              // Validate string fields
              if (!name) x.push('Account Holder is required');
              if (!rut) x.push('ID Number is required');
              if (!email) x.push('Email is required');
              if (!bankid) x.push('Bank is required');
              if (!accountnumber) x.push('Bank account Nº is required');
              if (!accountType) x.push('Account Type is required');
           
             
              
            
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
      const sendDataa = async () => {
        setLoader(true)
if(isFormValid()){


   
        let body={}
    body=JSON.stringify({name:name,acctyp:useMoneyTransfer?null:accountType,rut:rut,email:email,accnum:accountnumber,bankid:bankid,storeid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),email:email,admin:1,})
        
        console.log(body)
        const {status, data, msg} = await sendData('/bankaccounts/create', body,);
        if(status){
          setLoader(false)
            navigate('/bankaccount', {replace: true});
        }else{

      setShowalert(true)
      setMsg(msg)
   
          setLoader(false)
        }
      }else{
        setError(true)
        setError(true)
        window.scrollTo({
          top: 0,
          behavior: "smooth" // Use smooth scrolling animation
        });
        setLoader(false)
      }
      }
      useEffect(()=>{
        fetchBanks()
      },[])
  return (
    <div>
        {loader?
       <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

       <div className="spinner-border" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
     </div>
     :
 <>
   {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}
               {showAlerts()}
       <label className="form-label mt-4">
        Account holder <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        value={name}
        onChange={(e)=>setName(e.target.value)}
        required
        placeholder="-"
        type="text"
        className="form-control"
        name="name"
      />

      <label className="form-label mt-4">
        ID Number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        value={rut}
        onChange={(e)=>setRut(e.target.value)}
        required
        placeholder="000012345678"
        type='number'
        className="form-control"
        name="rut"
      />

      <label className="form-label mt-4">
        Email <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        required
        value={email}
        onChange={(e)=>setEmail(e.target.value)}
        placeholder="name@example.com"
        type="email"
        className="form-control"
        name="email"
      />
 <Form.Group className='mt-3'>
          <Form.Label>Choose Payment methods</Form.Label>
        </Form.Group>

        <ul>
          <li style={{listStyle:'none'}}><input type='checkbox' checked={useBank} onChange={(e) => {setUseBank(e.target.checked)
            setUseMoneyTransfer(false)}} style={{marginRight: 5}} />Bank</li>
       
          <li style={{listStyle:'none'}}><input type='checkbox' checked={useMoneyTransfer} style={{marginRight: 5}} onChange={(e) => {setUseMoneyTransfer(e.target.checked)
          setUseBank(false)}} />Money Transfer</li>
         

        </ul>
        {useMoneyTransfer && 
          <li style={{listStyle:'none'}}>
                <Form.Group className='mt-3'>
                    <Form.Label>Choose Money Transfer</Form.Label>
                    <select 
      value={bankid} 
      onChange={(e)=>setBankid(e.target.value)}
      required id="bankid" name="bankid" className="form-control">
        <option></option>
        {moneytransfer.map((bank) => (
          <option key={bank.id} value={bank.id}>
            {bank.name}
          </option>
        ))}
      </select>
                    
                </Form.Group>

                <Form.Group className='mt-3' style={{marginBottom:10}}>
                    <Form.Label>Account number</Form.Label>
                          <input
              value={accountnumber}
              onChange={(e)=>setAccountnumber(e.target.value)}
              placeholder="-"
              required
              type="number"
              className="form-control"
              name="accnum"
            />
                   
                </Form.Group>
          </li>
          }
      {useBank&&(
        <>

        <label className="form-label mt-4">
        Bank <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <select 
      value={bankid} 
      onChange={(e)=>setBankid(e.target.value)}
      required id="bankid" name="bankid" className="form-control">
        <option></option>
        {banks.map((bank) => (
          <option key={bank.id} value={bank.id}>
            {bank.name}
          </option>
        ))}
      </select>

      <label className="form-label mt-4">
        Bank account Nº <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
         value={accountnumber}
         onChange={(e)=>setAccountnumber(e.target.value)}
        placeholder="-"
        required
        type="number"
        className="form-control"
        name="accnum"
      />

      <label className="form-label mt-4">
        Account type <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <div className="my-select mb-5">
        <select
          value={accountType}
          onChange={(e)=>setAccountType(e.target.value)}
          required
          name="acctyp"
          className="small-drop small-select form-control"
        >
          <option value="checking account">Checking account</option>
          <option value="saving account">Saving account</option>
          <option value="view account">View account</option>
        </select>
      </div>
      </>
      )}

      <input disabled={loader?true:false} onClick={()=>{sendDataa()}} className="btn buttonx w-100 text-white mb-5" type="submit" value="Add Bank account" />
      </>}
    </div>
  )
}
