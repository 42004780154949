import React, {useState, useEffect, useRef} from 'react'
import OTPInput from 'react-otp-input'
import { Button, Alert } from 'react-bootstrap';
// import { uri } from '../utils';
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(120);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startTimer, setStartTimer] = useState(true);
  const [idx, setIdx] = useState(null);
  const navigate = useNavigate();
  
  const verify = async () => {
    try{
      if(otp.length !== 4) return;
      setLoading(true);
      if(error) setError(null)
      //setLoading(true);
      const req = await fetch('https://api.trakitrak.com/merchant/verifyotp', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({code: otp, idx})
      });
      const res = await req.json();
      if(req.status === 200){
        localStorage.setItem("hasstore", 'no');
           navigate('/stores/add', {replace: true});
      } else if(req.status === 498) {
        localStorage.removeItem("token");
        navigate('/login', {replace: true});
    } else {
          setError(res.message);
          setLoading(false);
      }
    } catch(err) {
      console.log(err);
      setError("Server Error");
      setLoading(false);
    }
  }

  
  const requestOtp = async () => {
    try{
      if(error) setError(null)
      //setIdx(null)
      setLoading(true);
      const req = await fetch('https://api.trakitrak.com/merchant/requestotp', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const res = await req.json();
      if(req.status === 200){
          setIdx(res.idx);
          setStartTimer(true);
      } else if(req.status === 498) {
        localStorage.removeItem("token");
        navigate('/login', {replace: true});
    } else {
          setError(res.message);
      }
      setLoading(false);
    } catch(err) {
      console.log(err);
      setError("Server Error");
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   if(seconds === 120)
  //   requestOtp();
  // },[seconds])

  useEffect(() => {
    let timer;
    if(startTimer)
    timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        console.log(prevSeconds)
        if (prevSeconds === 0) {
          clearInterval(timer);
          setStartTimer(false);
        } else if(prevSeconds === 120) {
         requestOtp()
        };

        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

  return () => clearInterval(timer);
  }, [seconds, startTimer]);

  const logout = () => {
    localStorage.removeItem('token');
    navigate("/login", {replace: true});
  }
  if(loading) return <Spinner />
  return (
    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100vh'}}>
      <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
        <h4 style={{textAlign: 'center'}}>Verify</h4>
        {error && <Alert variant='danger'>{error}</Alert> }
        <p style={{textAlign: 'center'}}>Your code was sent to you via email</p>
        <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span style={{ width: "10px" }}></span>}
              inputStyle={{
                height: '45px',
                width: '42px',
                borderRadius: '6px',
                outline: 'none',
                fontSize: '1.125rem',
                textAlign: 'center',
                border: '1px solid #ddd',
              }}
              renderInput={(props) => <input type='number' {...props} />}
        />
        <Button disabled={otp.length !== 4 || !idx} 
        onClick={verify} 
        className='mt-4 tt-button'>Verify</Button>
        <p className='mt-3'>Didn't receive code? <span onClick={()=> {
          setStartTimer(true)
          setSeconds(120)
        }} style={{color: 'rgb(13,110,253)', textDecoration:'underline', cursor: seconds === 0 ? 'pointer' :'default', opacity: seconds === 0 ? 1 : 0.6}}>Request again</span> in {seconds} seconds</p>
              <Button onClick={logout} className='mt-4 tt-button'>Cancel</Button>
      </div>
    </div>
  )
}

export default Otp