import React, {useState, useEffect, useRef} from 'react'
import { sendData } from '../../utils';
import { useNavigate } from 'react-router-dom';
import Map, { Marker, Popup, Source, Layer } from "react-map-gl";
import MapBranchIcon from "../../components/MapBranchIcon";
import "mapbox-gl/dist/mapbox-gl.css";

const OrdersHeatmap = () => {
    const [loading, setLoading]= useState(true);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [popup, setPopup] = useState({show: false, data: null});
    //const [showPopup, setShowPopup] = useState(false);
    const mapRef = useRef(null);

    const getData = async () => {
        if(!loading) setLoading(true)
        const {status, data, msg} = await sendData(`/getreportsdata`, JSON.stringify(
            {
            type: 'orders-heatmap', 
            storeid: localStorage.getItem("storeid"),
            userid: localStorage.getItem("userid"),
            }
        ), navigate);
        if(status){
            setData(data);
            setLoading(false);
        }
    }


    useEffect(() => {
        getData();
    }, [])



  if(loading || data?.branches.length == 0)return (
    <div className="d-flex justify-content-center align-items-center" style={{marginTop:'10%'}}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  )
  else return (
    <div style={{flex: 1}}>
    <Map

        mapStyle="mapbox://styles/mapbox/streets-v11"
        ref={mapRef}
        style={{width:'100%',height:'100%'}}
        mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
        initialViewState={{longitude: data.branches[0].longg, latitude: data.branches[0].lat, zoom: 16}}
      >
        {data.branches.map(branch => {
            return (
            <Marker 
            key={branch.id}
            latitude={branch.lat}
            longitude={branch.longg}
            anchor='bottom'
            draggable={false}
            onClick={() => setPopup({show: true, data: {lat: branch.lat, longg: branch.longg, name: branch.name}})}
            >
                <MapBranchIcon />

            </Marker>
            )
        })}

        {popup.show && popup.data &&
          <Popup 
          anchor='bottom'
          latitude={popup.data.lat} 
          longitude={popup.data.longg}
          closeOnClick={false}
          onClose={() => setPopup({show: false, data: null})}
          offset={45}
          >
            <p>{popup.data.name}</p>
          </Popup>
          }

          {data.branchcoords.length > 0 &&
          <Source type='geojson' data={{
            type: 'FeatureCollection',
            features: data.branchcoords.map(function(order) {
              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [order.longitude, order.latitude]
                }
              };
            })
          }} >
            <Layer 
            id='heatmapLayer'
            type='heatmap'
            paint={{"heatmap-opacity": 0.8, "heatmap-intensity": 1.5}}
            />
          </Source>
          }

      </Map>

    </div>
  )
}

export default OrdersHeatmap;