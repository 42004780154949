import React,{useState,useEffect} from 'react'
import { fetchData, sendData } from '../utils';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

export default function AddBillingInformation() {
  const navigate = useNavigate()
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loader, setLoader] = useState(false);
  const [busname, setbusname] = useState();
  const [activity, setActivity] = useState('');
  const [rut, setRut] = useState('');
  const [street, setStreet] = useState('');
  const [unit, setUnit] = useState('');
  const [suite, setSuite] = useState('');
  const [city, setCity] = useState('');
  const sendDataa = async () => {
    setLoader(true)
if(isFormValid()){
  let body={}
  body=JSON.stringify({busname:busname,activit:activity,rut:rut,street:street,unit:unit,city:city,storeid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),numsuite:suite,})
      
      console.log(body)
      const {status, data, msg} = await sendData('/billinginfo/create', body,);
      if(status){
        setLoader(false)
          navigate('/billinginformation', {replace: true});
      }else{
        setShowalert(true)
        setMsg(msg)
        setLoader(false)
      }
}else{
setError(true)
setError(true)
window.scrollTo({
  top: 0,
  behavior: "smooth" // Use smooth scrolling animation
});
  setLoader(false)
}


  }
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  const isFormValid = () => {
    let x=[]
      
          // Validate string fields
          if (!busname) x.push('Business name is required');
          if (!rut) x.push('Rut is required');
          if (!activity) x.push('Activity is required');
          if (!street) x.push('Street is required');
          if (!unit) x.push('Unit is required');
          if (!suite) x.push('Suite is required');
          if (!city) x.push('City is required');
         
          
        
    
          if (x.length > 0) {
            console.log('Validation Errors:', x);
            setErrors(x)
            return false;
          }
        if(x.length==0){
          setErrors([])
          return true;
        }
          // If all validations pass, return true
    
     
        };
  return (
    <div style={{marginTop:'3%'}}>
       <h3>Add Billing Information</h3>
       {loader?
       <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

       <div className="spinner-border" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
     </div>
     :
 <>
             {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}
        {showAlerts()}
      <label className="form-label mt-4">
        Business/Company name<span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        value={busname}
        onChange={(e)=>setbusname(e.target.value)}
        required
        placeholder="-"
        type="text"
        className="form-control"
        name="busname"
      />

      <label className="form-label mt-4">
        ID Number <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
         value={rut}
           onChange={(e)=>setRut(e.target.value)}
        required
        placeholder="1234567"
        type="text"
        className="form-control"
        name="rut"
      />

      <label className="form-label mt-4">
        Activity <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        required
        value={activity}
        onChange={(e)=>setActivity(e.target.value)}
        placeholder="-"
        type="text"
        className="form-control"
        name="activit"
      />

      <div className="row g-2">
        <div className="col-md-6">
          <div>
            <label className="form-label mt-4">
              Street <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
            </label>
            <input
              required
              value={street}
              onChange={(e)=>setStreet(e.target.value)}
            //  value={data ? data.street : ''}
              placeholder="-"
              type="text"
              className="form-control"
              name="street"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div>
            <label className="form-label mt-4">
              Unit <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
            </label>
            <input
              required
              value={unit}
              onChange={(e)=>setUnit(e.target.value)}
              placeholder="-"
              type="text"
              className="form-control"
              name="unit"
            />
          </div>
        </div>
        <div className="col-md-3">
          <div>
            <label className="form-label mt-4">Suite/Unit <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
            <input
              required
              value={suite}
              onChange={(e)=>setSuite(e.target.value)}
              placeholder="-"
              type="text"
              className="form-control"
              name="numsuite"
            />
          </div>
        </div>
      </div>

      <label className="form-label mt-4">
        City <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span>
      </label>
      <input
        required
        value={city}
        onChange={(e)=>setCity(e.target.value)}
        placeholder="-"
        type="text"
        className="form-control"
        name="city"
      />

      <input
      disabled={loader?true:false}
      onClick={()=>sendDataa()}
        className="btn buttonx w-100 text-white mt-4 mb-5"
        type="submit"
        value="Add Billing Information"
      />
      </>
}
    </div>
  )
}
