import React, { useState,useEffect } from 'react'
import { fetchData } from '../utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
export default function BillingInformation() {
  const navigate = useNavigate()
  const [billInfo, setBillInfo] = useState([]);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [modalData, setModalData] = useState({id: '', rut:'', name:'', activity: '', street: '', unit: '', numsuite: '', city: '' })
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const fetchBillInfo= async (signal)=>{
    const {status, data, msg} = await fetchData(`/billinginfo/${localStorage.getItem('userid')}/${localStorage.getItem('storeid')}`);
    if(status){
      setBillInfo(data)
      setLoading(false)

    }else{

      setShowalert(true)
      setMsg(msg)
      setBillInfo([])
      setLoading(false)
    }

    }
    useEffect(()=>{
      fetchBillInfo()
    },[])
    const showAlerts = () =>{
      return (
        <>
    {showalert&&(
  <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
      {msg}
  </Alert>
    )}
       
      </>
      )
    }
  return (
    <div style={{marginTop:'3%'}}>
        <Modal dialogClassName="custom-modal" centered show={showModal} onHide={handleClose}>
       
       <Modal.Body>
       <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
  <div className="modal-content">
    <div className="modal-header">
      <h4 style={{ fontFamily: 'Poppins-SemiBold' }}>Billing information</h4>
      <div>
        <a onClick={()=>navigate(`/billinginformation/edit/${modalData.id}`)}  className="btn btn-danger" style={{ marginRight: '5px' }}>
          Edit
        </a>
        <button onClick={()=>{setShowModal(false)}} data-bs-dismiss="modal" className="btn btn-primary">
          Close
        </button>
      </div>
    </div>
    <div className="modal-body" style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
      <div className="p-3">
        <p className="mb-4">GENERAL INFORMATION</p>
        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Business/Company Name</p>
          <p style={{ fontSize: '15px' }}>{modalData.name}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>ID Number</p>
          <p style={{ fontSize: '15px' }}>{modalData.rut}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Activity</p>
          <p style={{ fontSize: '15px' }}>{modalData.activity}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Street</p>
          <p style={{ fontSize: '15px' }}>{modalData.street}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Number</p>
          <p style={{ fontSize: '15px' }}>{modalData.unit}</p>
        </div>

        <div className="mb-4 d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>Suite/Unit</p>
          <p style={{ fontSize: '15px' }}>{modalData.numsuite}</p>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <p style={{ fontFamily: 'Poppins-Regular' }}>City</p>
          <p style={{ fontSize: '15px' }}>{modalData.city}</p>
        </div>
      </div>
    </div>
  </div>
</div>
       </Modal.Body>
     
     </Modal>
        <div className="row align-items-center border-bottom pb-2">
          {showAlerts()}
          <div className="col-md-8">
            <h3 className="mb-0">Billing information</h3>
            <p>Set up your store's billing information. You can use different billing information for different branches.</p>
          </div>
          <div className="col-md-4">
            <a 
            onClick={()=>navigate('/billinginformation/add')}
             style={{ float: 'right', fontSize: '15px' }} className="btn buttonx text-white">
              + Add billing information
            </a>
          </div>
        </div>
        {loading?
         <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

         <div className="spinner-border" role="status">
         <span className="visually-hidden">Loading...</span>
       </div>
       </div>
        :
     <>
     {billInfo.length==0?
     
     <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No results</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Your recents Billing Information will appear here.</p>
         </div>
 :

        <div className="bank-accounts-container mt-3">
      {billInfo.map((account, i) => (
        <div
          onClick={() => {
            setModalData({
              id: account.id,
              rut: account.comprat,
              name: account.busname,
              activity: account.activit,
              street: account.street,
              unit: account.unit,
              numsuite: account.numsuite,
              city: account.city,
            });
            setShowModal(true)
          }}
          className="account-container mb-3 rounded"
          data-bs-toggle="modal"
          data-bs-target="#payModal"
          key={i}
        >
          <div className="account-content">
            <h5 style={{ fontSize: '18px', fontFamily: 'Poppins-Regular' }}>{account.busname}</h5>
            <div className="d-flex justify-content-between align-items-center">
              <div className="acc-flex">
                <strong className="acc-title">Activity</strong>
                <span className="acc-flex-detail">{account.activit}</span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">Address</strong>
                <span className="acc-flex-detail">
                  {account.street}, {account.unit}
                  {account.numsuite ? ', ' + account.numsuite : ''}, {account.city}
                </span>
              </div>

              <div className="acc-flex">
                <strong className="acc-title">ID Number</strong>
                <span className="acc-flex-detail">{account.comprat}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div> 
    
  }
    </>
     }

    </div>
  )
}

