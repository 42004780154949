import React,{useState}from 'react'
import { useNavigate } from 'react-router-dom'

export default function Terms() {
    const navigate =  useNavigate()
    const [isAr, setIsAr] = useState(false);
  return (
    <div className="container w-75" style={{ marginTop: '1%' }}>
    <div className="container mainhomecontainer">
      <div className="card mb-5">
        <div className="card-header d-flex justify-content-between align-items-center">
          <p style={{margin:0}}>Terms and conditions</p>
          <div style={{display:'flex',alignItems:'center'}} >
          <svg style={{marginRight:5}} id="locale-picker-globe" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="shrink-0"><path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 15C9.39949 15 10.534 11.866 10.534 8C10.534 4.13401 9.39949 1 8 1C6.60051 1 5.466 4.13401 5.466 8C5.466 11.866 6.60051 15 8 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.448 5.75989H14.524" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.448 10.2402H14.524" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <select value={isAr?'ar':'en'} onChange={(e)=>{
              if(e.target.value=='ar'){
setIsAr(true)
            }else{
              setIsAr(false)
            }
            }} style={{border:'none',backgroundColor:'#f9f9f9'}}>
              <option value={'en'}>Englsih</option>
              <option value={'ar'}>Arabic</option>
            </select>
                {/* <input name="own_bags" className="form-check-input" type="checkbox" id="flexCheckDefault6" value={isAr}   onChange={() => setIsAr(!isAr)} /> */}
               
            </div>
        </div>
       




        <div className="card-body pb-4">
       
        <div className="text-center">
           
  
        <iframe style={{width:'95%',height:500,borderRadius:5}} src={isAr?'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/%D8%B9%D9%82%D8%AF++trakitrak+%D9%84%D9%84%D9%85%D8%AA%D8%A7%D8%AC%D8%B1.pdf':'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/TRAKITRAK+STORE+CONTRACT+(4).pdf'} />

            </div>
          

            <div style={{width:'70%',marginLeft:'15%',height:75,display:'flex',justifyContent:'space-around',alignItems:'center',cursor:'pointer'}}>
                <div onClick={()=>{navigate('/stores/added')}} style={{backgroundColor:'#fafbff',borderRadius:10,width:'40%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <p style={{margin:0,paddingTop:5,paddingBottom:5,color:'black'}}>I do not agree</p>
                </div>
                <div onClick={()=>{navigate('/stores/added')}}  style={{backgroundColor:'red',borderRadius:10,width:'40%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                        <p style={{margin:0,paddingTop:5,paddingBottom:5,color:'white'}}>Agree and submit</p>
                </div>
</div>
        </div>
   
      </div>

    </div>
  </div>
  )
}
