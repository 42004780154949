import React, {useState, useEffect} from 'react'
import { fetchData, sendData } from '../../utils';
import { useNavigate } from 'react-router-dom';
import Select from "react-select"
import makeAnimated from "react-select/animated";

function findIdInData(idToFind, data, mainArrayIndex) {
    if (mainArrayIndex >= 0 && mainArrayIndex < data.data.length) {
      const subArray = data.data[mainArrayIndex];
      for (let j = 0; j < subArray.length; j++) {
        if (subArray[j].id === idToFind) {
          return { mainArrayIndex, subArrayIndex: j };
        }
      }
    }
    return null; // Return null if the ID is not found in the specified array
  }

const SalesByCategory = () => {
    const [loading, setLoading]= useState(true);
    const [branches, setBranches] = useState([]);
    const [data, setData] = useState([]);
    const [branchid, setBranchid] = useState('0');
    const [categories, setCategories] = useState([]);
    const [categoryid, setCategoryid] = useState([{label:'All Categories', value: '0'}]);
    const [cats, setCats] = useState([]);
    const [catnames, setCatnames] = useState([]);
    const [period, setPeriod] = useState('week');
    const navigate = useNavigate();
    const customStyles = {
        menuList: (provided) => ({
          ...provided,
          maxHeight: `210px`, // for 6 items, adjust based on your item height
        }),
      };
    const getData = async () => {
        if(!loading) setLoading(true)
        const {status, data, msg} = await sendData(`/getreportsdata`, JSON.stringify(
            {
            type: 'sales-by-category', 
            storeid: localStorage.getItem("storeid"),
            userid: localStorage.getItem("userid"),
            branchid,
            period,
            categoryid: categoryid.map(c => c.value)
            }
        ), navigate);

        if(status){
            setData(data);
            if(data.length > 0){
                let cats = data.data.filter((element) => Array.isArray(element) && element.length > 0) // Filter non-empty arrays
                .map((element) => element[0].id); 
                setCats([...new Set(cats)]);
                let catsnames = data.data.filter((element) => Array.isArray(element) && element.length > 0) // Filter non-empty arrays
                .map((element) => element[0].name);
                setCatnames([...new Set(catsnames)]);
            }
            //setTotalOrders(data.reduce((a, b)=>a+b.total, 0));
            setLoading(false);
        }

    }

    const getBranches = async () => {
        const {status, data, msg} = await fetchData(`/branches/dropdown/${localStorage.getItem('storeid')}/${localStorage.getItem("userid")}`, navigate);
        if(status){
            setBranches(data);
        }
    }

    const getCategories = async () => {
        const {status, data, msg} = await fetchData(`/categories/dropdown`, navigate);
        if(status){
            setCategories(data);
        }
    } 

    useEffect(() => {
        getData();
    }, [branchid, period, categoryid])

    useEffect(() => {
        getBranches();
        getCategories()
    }, [])


  return (
    <div style={{flex: 1, overflow: 'hidden'}}>
    <div className='d-flex h-100'>
        <aside className="sidebar-containerx border-end" style={{overflowY:'auto'}}>
        <div className="py-4">
        <div className="px-3" style={{paddingBottom:220}}>
                     {/* <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Branch</p>
                    <select onChange={e => setBranchid(e.target.value)} value={branchid} name="branchid" id="branchid" className="form-select mt-2">
                    <option value="0">All branches</option>
                        {branches.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                    </select> */}

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Branch</p>
                    <select onChange={e => setBranchid(e.target.value)} value={branchid} name="branchid" id="branchid" className="form-select mt-2">
                    <option value="0">All branches</option>
                        {branches.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                    </select>

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Period</p>
                    <select className='form-select' value={period} onChange={e => setPeriod(e.target.value)}>
                        <option value="day">By day</option>
                        <option value="week">Per week</option>
                        <option value="month">Per month</option>
                    </select>

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Category</p>
                    <Select 
                    options={categories}
                    isMulti={true}
                    value={categoryid}
                    onChange={e => {
                        setCategoryid(e);
                    }}
                    styles={customStyles}
                    />


        </div>

        </div>
    </aside>

    <main className="main-containerx" style={{width: '80%', position:'relative', overflow: 'scroll' }}>

        {loading ?
        <div className="py-3 px-4 d-flex justify-content-center align-items-center" style={{marginTop:'10%'}}>

            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        : 
        <div className='py-3 px-4' style={{height: '100vh', position:'absolute', left:0, top: 0,width: '100%'}}>
            {data.data.length > 0 && (
                <>
                    <div className="p-2 bg-white table-container">
                        <table className="table">

                        <thead className="pnf-thead">
                            <tr className="pnf-tr-head">
                            <th scope="col">Category</th>
                            {data.labels.map((l, i) => <th key={`${i}label`} scope='col'>{l}</th>)}
                            </tr>
                        </thead>
                        {cats.length>0? 
                        <tbody className="table-body">
                        {    
                            cats.map((cat, k)=>{
                            let cat_data = [0,0,0,0,0];
                            for(var x=0; x<5; x++){
                            let result = findIdInData(cat, data, x);
                            if(result) cat_data[x] = data.data[result.mainArrayIndex][result.subArrayIndex]['catcart5s_sum_totalx'];
                            }
                            return (
                                <tr key={k} className='pnf-tr'>
                                    <th scope="row">{catnames[k]}</th>
                                    <td>${cat_data[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>${cat_data[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>${cat_data[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>${cat_data[3].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>${cat_data[4].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                </tr>
                            )
                            })
                        }
                        </tbody>
                        :
                       null
                        }
                       

                        </table>
                        {cats.length==0&&(
                            <div style={{width:'100%',backgroundColor:'white',height:40,display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <p style={{color:'#333333',fontWeight:'500',fontSize:16}}>No data to show</p>
                      </div>
                        )}
                        
                    </div>
                    <div style={{paddingBottom: '30px'}}>

                    </div>
                </>
            )}
        </div>
        }

    </main>

</div>
</div>
  )
}

export default SalesByCategory