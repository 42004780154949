import React, {useState, useEffect} from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale
} from "chart.js"
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { fetchData, sendData } from '../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {

  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({delivered: 0, sales:0, average: 0, fulfillment: 0, found: 0 });
  const [data1, setData1] = useState([]);
  const [branchid, setBranchid] = useState('0');
  const [dateFilter, setDateFilter] = useState('0');
  const [branchid1, setBranchid1] = useState('0');
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const datahard = [
      { day: 'Monday', count: 0 },
      { day: 'Tuesday', count: 0 },
      { day: 'Wednesday', count: 1 },
      { day: 'Thursday', count: 0 },
      { day: 'Friday', count: 0 },
      { day: 'Saturday', count: 0 },
      { day: 'Sunday', count: 0 },
  ];

  const getBranches = async () => {
    const {status, data, msg} = await fetchData(`/branches/dropdown/${localStorage.getItem('storeid')}/${localStorage.getItem("userid")}`, navigate);
    if(status){
        setBranches(data);
    }
  }

  const getHomeData = async (type=1) => {
    if(!loading && type == 1) setLoading(true)
    else if(!loading1 && type == 2) setLoading1(true)
    const {status, data, msg} = await sendData(
      '/homedata',
      JSON.stringify({
        storeid: localStorage.getItem("storeid"), 
        userid: localStorage.getItem("userid"), 
        date: type == 1 ? dateFilter : null, 
        type,
        branchid: type == 1 ? branchid : branchid1
      }),
      navigate
     );
    if(status){
      if(type == 1){
        let fullrate = 0;
        let foundrate = 0;
        if(data.prods > 0) {
        fullrate = parseFloat((((data.replaceprods + data.foundprods) / (data.prods))*100).toFixed(2))
        foundrate = parseFloat((((data.foundprods)/(data.prods))*100).toFixed(2));
        }
        setData({...data, sales: data.total, delivered: data.delivered, average: data.receipt,
        fulfillment: fullrate, found: foundrate
        });
          setLoading(false);
      } else {
          setData1(data)
          setLoading1(false)
      }
    }
  }


  useEffect(() => {
    getHomeData();
  },[branchid, dateFilter])

  useEffect(() => {
    getHomeData(2);
  }, [branchid1]) 

  useEffect(() => {
    getBranches();
  }, []) 


  return (
    <div style={{marginTop: 50}}>

      <div className="d-flex justify-content-between">
        <h4 className="m-0">Main Indicators</h4>
      
        <div className="d-flex" >
            <select 
            onChange={(e)=> { setBranchid(e.target.value)}} 
            value={branchid} 
            className="form-select"
            >
                <option value="0">All Branches</option>
                {branches.map((branch) => <option key={`${branch.id}-1`} value={branch.id}>{branch.name}</option>)}
            </select>

            <select onChange={(e)=>setDateFilter(e.target.value)} value={dateFilter} className="form-select ms-2">
              <option value="0">Today</option>
              <option value="1">Yesterday</option>
              <option value="7">Last 7 days</option>
              <option value="14">Last 2 weeks</option>
              <option value="28">Last 4 weeks</option>
            </select>

        </div>

      </div>

      <hr />

      <div className="row gy-3" >

        <div className="col-md-4">
            <div className="card-container border rounded p-0 bg-white">
                <div className="card-header border-bottom px-3 d-flex align-items-center">
                    <p>Orders Delivered</p>
                </div>
                <div className={`card-main-body px-3 d-flex align-items-center ${loading ? 'justify-content-center': ''}`}>
                    {!loading ? <p>${data.delivered}</p>
                    : 
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    }
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card-container border rounded p-0 bg-white">
                <div className="card-header border-bottom px-3 d-flex align-items-center">
                    <p>Total Sales</p>
                </div>
                <div className={`card-main-body px-3 d-flex align-items-center ${loading ? 'justify-content-center': ''}`}>
                    {!loading ?
                       <p>${`${parseFloat(data.sales.toFixed(2))}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    :
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card-container border rounded p-0 bg-white">
                <div className="card-header border-bottom px-3 d-flex align-items-center">
                    <p>Average Receipt</p>
                </div>
                <div className={`card-main-body px-3 d-flex align-items-center ${loading ? 'justify-content-center': ''}`}>
                    {!loading ?
                        <p>${`${parseFloat(data.average.toFixed(2))}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    : 
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card-container border rounded p-0 bg-white">
                <div className="card-header border-bottom px-3 d-flex align-items-center">
                    <p>Fulfillment Rate</p>
                </div>
                <div className={`card-main-body px-3 d-flex align-items-center ${loading ? 'justify-content-center': ''}`}>
                    {!loading ?
                    <p>${data.fulfillment}%</p>
                    : 
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card-container border rounded p-0 bg-white">
                <div className="card-header border-bottom px-3 d-flex align-items-center">
                    <p>Found Rate</p>
                </div>
                <div className={`card-main-body px-3 d-flex align-items-center ${loading ? 'justify-content-center': ''}`}>
                    {!loading ?
                    <p>${data.found}%</p>
                    : 
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                </div>
            </div>
        </div>




      </div>

      <div className="d-flex justify-content-between mt-5">
        <h4>Last 7 days orders</h4>

        <div className="d-flex" >
            <select value={branchid1} onChange={(e)=>{
                setBranchid1(e.target.value);
            }} className="form-select">
                <option value='0'>All Branches</option>
                {branches.map((branch)=> <option value={branch.id}>{branch.name}</option>)}
            </select>
        </div>
      </div>

      <hr />

      <div 
        className={`mt-2 mb-5 rounded my-shadow ${loading1 ? 'd-flex align-items-center justify-content-center':''} p-4`} 
        style={{height: loading1 ? '100px' : '400px', backgroundColor: 'white'}}
      >
        {/* <canvas id="7daysorders"></canvas> */}
        
        {!loading1 ? (
          <Line 
            data={
              {
                labels: data1.map(d => d.day),
                datasets: [
                  {
                    label: 'Orders',
                    data: data1.map(d => d.count),
                    backgroundColor: '#e05b0e',
                    borderColor: '#e05b0e'
                  }
                ]
              }
            }

            options={{
              interaction:{mode:'nearest', axis:'xy', intersect:false},
              responsive: true,
              maintainAspectRatio: false,
              plugins:{
                  legend:{
                      position:'left',
                      labels:{
                          boxWidth:0
                      }
                  }
              }
            }}
          />
        )
        :
        (<div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>)
        }

      </div>

      <div className="help-container bg-white text-center rounded">
        <h2 style={{fontFamily: 'Poppins-SemiBold'}}>We're here to assist you</h2>
        <p className="my-3" >Our customer service for stores is ready every day of the week to help you with anything you need.</p>
        <button onClick={()=>window.Intercom('show')} className="btn btn-primary buttonx">Chat with an agent</button>
      </div>

    </div>
  )
}

export default Home