import React,{useEffect,useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Alert, Spinner } from 'react-bootstrap';
import validbarcode from "barcode-validator";
import { isValid } from 'gtin'
export default function AddProduct() {
    const navigate = useNavigate()
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [isempty, setIsempty] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [Barcode, setBarcode] = useState('');
    const [isvalidbarcode, setvalidbarcode] = useState(true);
    
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);

    const GetProdBarcode = async () => {
      setShow2(true)
      const {status, data, msg} = await sendData('/prodbarcode/check', JSON.stringify({userid: localStorage.getItem('userid'),storeid: localStorage.getItem('storeid'),barcode:Barcode}));
      if(status){
         console.log(data)
         if(data.found==false){
          setIsempty(true)
         }else{
          setShow1(true)
          setIsempty(false)
         }
        setShowalert(false)
        setShow2(false)
      }else{
        setShowalert(true)
        setMsg(msg)
        setShow2(false)

   
      }
    }
    
    useEffect(()=>{
      //GetProdBarcode()
      if(Barcode){
        try {
          setvalidbarcode(isValid(Barcode))
        } catch (error) {
          console.log(error)
          setvalidbarcode(false)
        }
       
      }

    },[Barcode])
    const showAlerts = () =>{
      return (
        <>
    {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
      {msg}
</Alert>
    )}
       
      </>
      )
    }
  return (
    <>
    {/* 7804000001691 */}
        {showAlerts()}
      <Modal  dialogClassName="custom-modal"  centered show={show} onHide={handleClose}>
       <Modal.Header>
          <h4 style={{color:'black',fontFamily:'Poppins-Regular',margin:0}}>What type of product is it?</h4>
       </Modal.Header>
        <Modal.Body>
          <div style={{width:865,height:290,display:'flex',justifyContent:'space-between'}}>

          <div onClick={()=>{navigate('/product/add')}} className="variantbox rounded  bg-primary  align-items-center px-3" style={{ height: '290px',width:'425px',cursor:'pointer' }}>
          <div style={{display:'flex',alignItems:'center'}}>
                    <input
                        id="unit-check"
                        style={{ marginLeft: '10px' }}
                        value="unit"
                        type="radio"
                        name="unitmeasure"
                    />
                    <div style={{width:'80%',marginLeft:'5%',flexDirection:'column',justifyContent:'center',display:'flex',height:'100px'}}>
                        <p style={{fontWeight:'bold',fontSize:19,fontFamily:'Poppins-Regular'}}>Single Product</p>
                        <p>Example: Meat, Tomato, ...</p>
                    </div>
                    </div>
          
            <div style={{height:'190px',width:'100%',}} >
                    <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/sav+food+(3).png'} alt="soda-can" style={{ width: '100%',height:'100%',objectFit:'cover' }} />
                    </div>
          </div>
          
          <div  onClick={()=>{navigate('/product/add/variant')}}className="variantbox1 rounded  bg-primary  align-items-center px-3" style={{ height: '290px',width:'425px',cursor:'pointer' }}>
         
          <div style={{display:'flex',alignItems:'center'}}>
                    <input
                        id="unit-check"
                        style={{ marginLeft: '10px' }}
                        value="unit"
                        type="radio"
                        name="unitmeasure"
                    />
                    <div style={{width:'80%',marginLeft:'5%',flexDirection:'column',justifyContent:'center',display:'flex',height:'100px'}}>
                        <p style={{fontWeight:'bold',fontSize:17,fontFamily:'Poppins-Regular'}}>Product families (With variants)</p>
                        <p>Example: Clothes, shoes , ...</p>
                    </div>
                    </div>
         <div style={{height:'190px',width:'100%',}} >
                 <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/sav+cloth.png'} alt="soda-can" style={{ width: '100%',height:'100%',objectFit:'cover' }} />
                 </div>
       </div>
           

          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>{navigate('/product/add')}}>
            No
          </Button>
          <Button variant="primary" onClick={()=>{navigate('/product/add/variant')}}>
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal  dialogClassName="custom-modal3"  centered show={show1} onHide={handleClose1}>
        <Modal.Body>
        <h4 style={{color:'#24292e',fontFamily:'Poppins-SemiBold',margin:'10px 0 20px 0',textAlign:'center',fontSize:22,}}>Product Found</h4>
         <p style={{textAlign:'center',color:'#8e8e93',margin:'0 16px 16px 16px'}}>We have information about this item, the Information manually added will be replaced.</p>
          <div onClick={()=>navigate(`/product/add/barcode/${Barcode}`)} style={{padding:'3px 10px 3px 10px',cursor:'pointer',width:308,height:44,marginTop:14,backgroundColor:'#e05b0e',justifyContent:'center',alignItems:'center',display:'flex',borderRadius:10}}>
            <p style={{color:'white'}}>Understood</p>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>{navigate('/product/add')}}>
            No
          </Button>
          <Button variant="primary" onClick={()=>{navigate('/product/add/variant')}}>
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal  dialogClassName="custom-modal2" centered show={show2} onHide={handleClose2}>
 
        <div className="modal-content" style={{height:173,backgroundColor:'#fafbff'}}>
                  <div style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <Spinner animation="border" style={{marginBottom:20}} role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p style={{fontSize:16,color:'#4a4a4a',width:132,height:65,fontWeight:'900',textAlign:'center'}}>Searching in our database</p>
                  </div>
                    </div>
   

      
      </Modal>
   
      <div className="container" style={{ marginTop: '5%' }}>
      {isempty?
                                 <div style={{display:'flex',width:'75%',marginLeft:'12.5%',alignItems:'center',backgroundColor:'#00000080',borderRadius:10,padding:'5px 15px 5px 15px',marginBottom:15}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                <div style={{marginLeft:10}}>
                <p style={{color:'white',fontSize:14}}>Product not registered in our database </p>
                <p style={{color:'white',fontSize:13}}>Help us to complete its information.</p>
                </div>
                                 </div>
                            :null}
    <div className="d-flex justify-content-center">
      <div className="card w-75">
        <div className="card-header">
          Add product
        </div>
        <div className="card-body">
          <div>
            <p className="card-barcode">Barcode</p>
            <div className="d-flex justify-content-between">
              <input className="form-control" style={{ width: '83%' }} value={Barcode} onChange={(e)=>{setBarcode(e.target.value)
            
              }} placeholder="Example: 38941298332" />
              <a onClick={()=>{
                if(validbarcode(Barcode)){
                  GetProdBarcode()
                 // setvalidbarcode(true)
                }else{
                 // setvalidbarcode(false)
                }
         
              }} className="btn btn-primary buttonx" style={{ width: '15%' }}>Search</a>
            </div>
           {!isvalidbarcode&&(<p style={{color:'red'}}>Invalid Barcode</p>)}
            <div style={{backgroundColor:'red',display:'flex',alignItems:'center',height:50,marginTop:10,borderRadius:7}}>
              <svg style={{margin:0,marginLeft:5}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
              <p className="text-white ms-2 info-card-text">If this product already exists in Trakitrak, we will load the information for you.</p>
            </div>
            <a onClick={()=>{setShow(true)}} style={{ backgroundColor: '#f5f5f5', textDecoration: 'none', color: 'black', cursor: 'pointer' }} className="rounded p-2 mt-4 d-flex align-items-center justify-content-center">
              <p className="me-1" style={{ fontSize: '16px',color:'black' }}>This product does not have a barcode</p>
              <div className="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path stroke="black" strokeWidth="1" fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </a>
          </div>
        
        </div>
      </div>
    </div>
  </div>
    </>
  
  )
}
