import React, { useEffect, useState , useRef } from 'react'
import { useNavigate, Link,useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
export default function StoreDetail() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [storeinfo, setStoreInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(false);
  const [branchid, setBranchid] = useState();
  const [branchname, setBranchname] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isopen, setIsopen] = useState(false);
  const [showww, setShowww] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmation1, setConfirmation1] = useState(false);
  const [showmsg1, setshowmsg1] = useState(false);
  const [showmsg, setshowmsg] = useState(false);
  const [message, setMessage] = useState('');
  const handleCloseww = () => setShowww(false);
  const disable = async (conf2) => {
    setShow(false)
    const {status, data, msg} = await sendData('/branches/status', JSON.stringify({conf:conf2, branchid:branchid,storeid:id,userid:localStorage.getItem('userid')}), navigate);
    if(status){
console.log(data)

fetchStores()
    }else{
      setShowalert(true)
      setMsg(msg)
    }
  }
  const handleShifts = async () => {
    setShow(false)
    const {status, data, msg} = await sendData('/branches/shifts', JSON.stringify({
      conf:isopen?0:1, id:branchid,msg:isopen?0:'High Demand'
    }), navigate);
    if(status){
fetchStores()
setMessage('')
setConfirmation(false)
setConfirmation1(false)
if(isopen){
  setshowmsg1(true)
}else{
  setshowmsg(true)
}

// The branch will not receive any more orders for the rest of the day
    }else{
      setShowalert(true)
      setMsg(msg)
    }
  }
  const fetchStores= async (signal)=>{
    const {status, data, msg} = await fetchData(`/stores/${id}/${localStorage.getItem('userid')}`);

    if(status){
      setStoreInfo(data);
      setLoading(true)
    }else{
      setLoading(true)

    }
   
   
    }
  useEffect(()=>{
      fetchStores()
  },[])
  const DayContainer = ({ day, startTime, endTime, isClosed }) => (
    <OverlayTrigger
    key={'top'}
    placement={'top'}
    overlay={
      <Tooltip id={`tooltip-top`}>
{isClosed ? 'Closed' : `${startTime} - ${endTime}`}
      </Tooltip>
    }
  >
    <div
 
      className={`day-container ${isClosed ? 'day-container-closed' : 'day-container-open'} ms-1`}
    >
      <p style={{margin:0}}>{day}</p>
    </div>
    </OverlayTrigger>
  );
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  return (
<div className="mainhomecontainer container" style={{marginTop:'5%'}}>
{loading?
  <div className="card">
    {showAlerts()}
<div className="card-header d-flex justify-content-between align-items-center prodnumber">
    <p className="branchname">
      {storeinfo.store.name} has {storeinfo.store.storprods_count} products in {storeinfo.store.branches_count} branches
    </p>
    <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
    <a onClick={()=>navigate(`/rate/${id}`)} className="btn buttonx text-white"  style={{marginRight:10}}>Exchange Rate</a>
    <a onClick={()=>navigate('/branch/add')} className="btn buttonx text-white" >+Add Branch</a>

    </div>
</div>

<div className="card-body p-0">

<div className="banner-container">
        <img style={{width:'100%',height:'100%'}} src={storeinfo.store.banner}  />

        <div className="d-flex banner-logo-overlay">
            <div className="d-flex justify-content-center align-items-center blox" style={{backgroundColor:storeinfo.store.color}}>
                <img  style={{width:'80%',height:'100%',objectFit:'contain'}}src={storeinfo.store.image} />
            </div>
            <div className="px-1 blox2" >
               <div style={{display:'flex',flexDirection:'column',marginLeft:'1%',marginTop:'2%'}}>
<h1>
<p   style={{color:storeinfo.store.color,fontSize:32}} >{storeinfo.store.name} </p>
</h1>               
<h2>
<span style={{fontSize:24,fontWeight:'900',color:'#4a4a4a'}}>  {storeinfo.store.cat.name}</span>
</h2>
              <h5 style={{fontSize:18,color:'#4a4a4a'}}>  {storeinfo.store.des}</h5>
                </div>
            </div>
        </div>

</div>
<div  className="banner-containerr">
  <div style={{height:184,width:'100%',borderTopRightRadius:8,borderTopLeftRadius:8,display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:storeinfo.store.color}}>
  <img  style={{width:'70%',height:'70%',objectFit:'contain'}}src={storeinfo.store.image} />

  </div>
  <div style={{height:205,width:'100%',display:'flex',padding:16,flexDirection:'column',justifyContent:'center',backgroundColor:'#f0f0f0'}}>
  <p   style={{color:'#4a4a4a',fontSize:32,fontFamily:'Poppins-SemiBold'}} >{storeinfo.store.name} </p>
  <p style={{fontSize:24,fontWeight:'900',color:'#4a4a4a',fontFamily:'Poppins-Regular'}}>  {storeinfo.store.cat.name}</p>
  <h5 style={{fontSize:18,color:'#4a4a4a',marginTop:5}}>  {storeinfo.store.des}</h5>

  </div>
        {/* <div className="d-flex banner-logo-overlay">
            <div className="d-flex justify-content-center align-items-center blox" style={{backgroundColor:storeinfo.store.color}}>
                <img  style={{width:'80%',height:'100%',objectFit:'contain'}}src={storeinfo.store.image} />
            </div>
            <div className="px-1 blox2" >
               <div style={{display:'flex',flexDirection:'column',marginLeft:'1%',marginTop:'2%'}}>
<h1>
<p   style={{color:storeinfo.store.color,fontSize:32}} >{storeinfo.store.name} </p>
</h1>               
<h2>
<span style={{fontSize:24,fontWeight:'900',color:'#4a4a4a'}}>  {storeinfo.store.cat.name}</span>
</h2>
              <h5 style={{fontSize:18,color:'#4a4a4a'}}>  {storeinfo.store.des}</h5>
                </div>
            </div>
        </div> */}

</div>

    <div className='storedetails'  >
    <h3>Branches</h3>
    <a onClick={()=>navigate('/branch/add')} className="btn buttonx addbranch text-white" >+Add Branch</a>
    </div>
<hr/>
<div >
      {storeinfo.branches.map(branch => (
        <div
        style={{cursor:'pointer',backgroundColor:branch.conf2!==1||branch.conf==2||branch.conf==0?'#f2f2f3':null}}
      onClick={()=>navigate(`/branch/edit/${branch.id}`)}

          className={`store-branch-container rounded mb-3 p-4 ${
            branch.conf2 === 0 ? 'bg-gray-200' : ''
          }`}
          key={branch.id}
        >
          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex align-items-center m-0">
            <h5 className='m-0' style={{color:'#4a4a4a',fontSize:20}}>{branch.name} </h5>
            {branch.conf==2? <span style={{marginLeft:5,color:'#888b90',fontSize:16}} dangerouslySetInnerHTML={{ __html: branch.reason }}></span>:
            branch.conf==0?<span style={{marginLeft:5,color:'#888b90',fontSize:16}}>Pending</span> :''}
            </div>
         <div style={{display:'flex'}}>
         <button
            style={{zIndex:10,marginRight:10}}
              className="branch-disable-btn"
              data-branchid={branch.id}
              data-conf2={branch.shifts_stopped}
              onClick={e => {
                e.stopPropagation();
                if(branch.shifts_stopped===1){
                setIsopen(true)
                setConfirmation1(true)
                }else{
                  setIsopen(false)
                  setConfirmation(true)
                }
                setBranchid(branch.id)
                setBranchname(branch.name)
             
                 //setShowww(!showww);
        
              }}
            >
              {branch.shifts_stopped === 1 ? 'Start receiving orders' : 'Stop receiving orders'}
            </button>
            <button
            style={{zIndex:10}}
              className="branch-disable-btn"
              data-branchid={branch.id}
              data-conf2={branch.conf2}
              onClick={e => {
                e.stopPropagation();
                if(branch.conf2===1){
                setEnable(false)
                }else{
                  setEnable(true)
                }
                setBranchid(branch.id)
                setBranchname(branch.name)
          setShow(!show);
        
              }}
            >
              {branch.conf2 === 1 ? 'Disable branch' : 'Enable branch'}
            </button>
        </div>
          </div>

          <div className='branchlisting'>
          {/* f8f8f9 */}
            <div className="d-flex align-items-center listing">
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <g id="style=linear"> <g id="email"> <path id="vector" d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#000000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> <path id="vector_2" d="M18.7698 7.7688L13.2228 12.0551C12.5025 12.6116 11.4973 12.6116 10.777 12.0551L5.22998 7.7688" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/> </g> </g> </g>

</svg>
              <p className="ms-1 branch-detail-text" style={{margin:0, fontSize: '13px' }}>
                {branch.email}
              </p>
            </div>

            <div className="d-flex align-items-center listing" >
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"/>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
<g id="SVGRepo_iconCarrier"> <g id="Navigation / Map_Pin"> <g id="Vector"> <path d="M5 9.92285C5 14.7747 9.24448 18.7869 11.1232 20.3252C11.3921 20.5454 11.5281 20.6568 11.7287 20.7132C11.8849 20.7572 12.1148 20.7572 12.271 20.7132C12.472 20.6567 12.6071 20.5463 12.877 20.3254C14.7557 18.7871 18.9999 14.7751 18.9999 9.9233C18.9999 8.08718 18.2625 6.32605 16.9497 5.02772C15.637 3.72939 13.8566 3 12.0001 3C10.1436 3 8.36301 3.7295 7.05025 5.02783C5.7375 6.32616 5 8.08674 5 9.92285Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g> </g> </g>
</svg>
              <p className="ms-1 branch-detail-text" style={{margin:0, fontSize: '13px' }}>
                {branch.address}
              </p>
            </div>

            <div className="d-flex align-items-center listing2" >
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"/>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
<g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.8284 6.75736C12.3807 6.75736 12.8284 7.20507 12.8284 7.75736V12.7245L16.3553 14.0653C16.8716 14.2615 17.131 14.8391 16.9347 15.3553C16.7385 15.8716 16.1609 16.131 15.6447 15.9347L11.4731 14.349C11.085 14.2014 10.8284 13.8294 10.8284 13.4142V7.75736C10.8284 7.20507 11.2761 6.75736 11.8284 6.75736Z" fill="#0F1729"/> </g>
</svg>
             {/* Monday */}
    <DayContainer
      day="M"
      startTime={branch.monday1}
      endTime={branch.monday2}
      isClosed={branch.monday1 === '' && branch.monday2 === ''}
    />

    {/* Tuesday */}
    <DayContainer
      day="T"
      startTime={branch.tuesday1}
      endTime={branch.tuesday2}
      isClosed={branch.tuesday1 === '' && branch.tuesday2 === ''}
    />

    {/* Wednesday */}
    <DayContainer
      day="W"
      startTime={branch.wedneday1}
      endTime={branch.wedneday2}
      isClosed={branch.wedneday1 === '' && branch.wedneday2 === ''}
    />

    {/* Thursday */}
    <DayContainer
      day="T"
      startTime={branch.thursday1}
      endTime={branch.thursday2}
      isClosed={branch.thursday1 === '' && branch.thursday2 === ''}
    />

    {/* Friday */}
    <DayContainer
      day="F"
      startTime={branch.friday1}
      endTime={branch.friday2}
      isClosed={branch.friday1 === '' && branch.friday2 === ''}
    />

    {/* Saturday */}
    <DayContainer
      day="S"
      startTime={branch.saturday1}
      endTime={branch.saturday2}
      isClosed={branch.saturday1 === '' && branch.saturday2 === ''}
    />

    {/* Sunday */}
    <DayContainer
      day="S"
      startTime={branch.sunday1}
      endTime={branch.sunday2}
      isClosed={branch.sunday1 === '' && branch.sunday2 === ''}
    />

     

            </div>
          </div>
        </div>
      ))}
    </div>

  </div>

</div>:
<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
<Spinner style={{marginTop:'15%'}} animation="grow" />
  </div>

}
<Modal  centered show={showmsg} onHide={()=>{setshowmsg(false)}}>
     
       <Modal.Body>
      <div style={{width:'100%'}}>
        <p style={{textAlign:'center',fontSize:18,color:'#4a4a4a'}}>
        The branch will not receive any more orders for the rest of the day    
            </p>
            <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:10}}/>

            <div style={{width:'100%',height:50,display:'flex',alignItems:'center'}}>
             <div onClick={()=>setshowmsg(false)} style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                <p style={{color:'red'}}>Close</p>
            </div>
            </div>
      </div>
       </Modal.Body>
     
     </Modal>
     <Modal  centered show={showmsg1} onHide={()=>{setshowmsg1(false)}}>
     
       <Modal.Body>
      <div style={{width:'100%'}}>
        <p style={{textAlign:'center',fontSize:18,color:'#4a4a4a'}}>
        The branch is available to receive orders 
            </p>
            <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:10}}/>

            <div style={{width:'100%',height:50,display:'flex',alignItems:'center'}}>
             <div onClick={()=>setshowmsg1(false)} style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                <p style={{color:'red'}}>Close</p>
            </div>
            </div>
      </div>
       </Modal.Body>
     
     </Modal>
     

     <Modal  centered show={confirmation1} onHide={()=>{setConfirmation1(false)}}>
     
       <Modal.Body>
      <div style={{width:'100%'}}>
        <p style={{textAlign:'center',fontSize:18,color:'#4a4a4a'}}>
        Do you want to start receiving orders for today?
        </p>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:10}}/>
            <div style={{width:'100%',height:50,display:'flex',alignItems:'center'}}>
            <div onClick={()=>{setConfirmation1(false)}} style={{width:'50%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                 <p style={{color:'red'}}>No</p>
            </div>
            <div style={{height:'100%',width:1,backgroundColor:'#d1d1d1',marginTop:5}}/>
            <div onClick={handleShifts} style={{width:'50%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                <p style={{color:'red'}}>Yes</p>
            </div>
            </div>
      </div>
       </Modal.Body>
     
     </Modal>
<Modal  centered show={confirmation} onHide={()=>{setConfirmation(false)}}>
     
       <Modal.Body>
      <div style={{width:'100%'}}>
        <p style={{textAlign:'center',fontSize:18,color:'#4a4a4a'}}>
        Users will not be able to place orders for the rest of the day. If you already have pending orders for today, they will follow their usual course.
        </p>
        <p style={{textAlign:'center',fontSize:18,color:'#4a4a4a'}}>Are you sure you want to stop receiving orders for today?</p>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:10}}/>
            <div style={{width:'100%',height:50,display:'flex',alignItems:'center'}}>
            <div onClick={()=>{setConfirmation(false)}} style={{width:'50%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                 <p style={{color:'red'}}>No</p>
            </div>
            <div style={{height:'100%',width:1,backgroundColor:'#d1d1d1',marginTop:5}}/>
            <div onClick={handleShifts} style={{width:'50%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                <p style={{color:'red'}}>Yes</p>
            </div>
            </div>
      </div>
       </Modal.Body>
     
     </Modal>
<Modal dialogClassName="custom-modal" centered show={showww} onHide={handleCloseww}>
       <Modal.Header closeButton>
        <Modal.Title>{`${branchname} ${isopen?'Open Shifts':'Close Shifts'}`}</Modal.Title>
       </Modal.Header>
       <Modal.Body>
      <div>
     {!isopen&&(
      <>
         <label>Message</label>
         <input placeholder='Message' className='form-control' value={message} onChange={(e)=>setMessage(e.target.value)}/>
         </>  
     )}
     <Button disabled={!isopen?message==''?true:false:false} style={{marginTop:10}} onClick={handleShifts}>Save</Button>
      </div>
       </Modal.Body>
     
     </Modal>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{branchname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to {enable?'Enable':'Disable'} this branch?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>{disable(enable?1:2)}}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
