import React,{useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';

export default function AfterAddStore() {
    const navigate = useNavigate()
    const [isBack, setIsBack] = useState(false);

  
  


    const StepItem = ({ icon, title, children }) => {
        return (
          <div className="d-flex align-items-center w-75">
             {/* <svg style={{width:'10%',fill:'#e05b0e'}} xmlns="http://www.w3.org/2000/svg" height="2.5em" viewBox="0 0 576 512">
            {icon}
             </svg> */}
            <div className="ms-2" style={{ width: '90%' }}>
              {/* <a style={{ fontFamily: 'Poppins-SemiBold' }}>{title}</a> */}
              <p className='m-0' style={{ fontSize: '15px',textAlign:'center' }}>{children}</p>
            </div>
          </div>
        );
      };
     
  return (
    <div className="container w-75" style={{ marginTop: '5%' }}>
    <div className="container mainhomecontainer">
      <div className="card mb-5">
        <div className="card-header d-flex justify-content-between align-items-center">
          <p style={{margin:0}}> Congratulations! 🎉</p>
          <a onClick={()=>{
            localStorage.setItem("hasstore",'yes');
            navigate('/home')
        }} className="btn btn-outline-primary buttonx-outline">Close
          </a>
        </div>
       




        <div className="card-body pb-4">
        <div className="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  height="3em" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#e05b0e" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>
            </div>
          <h5 className="text-center" style={{ fontFamily: 'Poppins-SemiBold',marginTop:10 }}>
          You've successfully added your store!
          {/* To continue setting up your business, let's add your first branch. Simply click on the "Add Branch" button. */}
          </h5>

          <div className="d-flex flex-column align-items-center justify-content-center mt-5">
            {/* Step 1 */}
            <StepItem icon={<path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"/>} title="Self-evaluation">
            You're one step closer. We just need to review your store, and then we'll let you Know the next steps in approximately 24 hours!
                        </StepItem>

        
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
