import React, { useState, useRef,useEffect, useCallback } from 'react'
import { useNavigate, Link,useParams } from 'react-router-dom'
import Map, { GeolocateControl, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// import Geocoder from 'react-map-gl-geocoder'
import { fetchData } from '../utils';
import Spinner from 'react-bootstrap/Spinner';
import GeocoderControl from './geocoder-control';
import debounce from 'lodash.debounce';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { Alert } from 'react-bootstrap';
export default function EditBranch() {
  const navigate = useNavigate();


    const {id} = useParams();
    const mapRef = useRef();
    const [extraimg, setExtraimg] = useState([]);
    const [interiorImage, setInteriorImage] = useState(null);
    const [imgs, setimgs] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [search, setsearch] = useState([]);
    const [showsearch, setshowsearch] = useState(true);
    const [zoom, setZoom] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [citys, setCitys] = useState([]);
    const [subcitys, setSubCitys] = useState([]);
    const [city, setCity] = useState('');
    const [subcity, setsubcity] = useState('');
    const [unit, setUnit] = useState('');
    const [address, setAddress] = useState('');
    const [reference, setReference] = useState('');
    const [viewport, setViewport] = useState({
        latitude: 37.7749,
        longitude: -122.4194,
        zoom: 16,
      });
      const [viewport1, setViewport1] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 16,
      });

      const [logoImage, setLogoImage] = useState(null);

      const [monday1, setMonday1] = useState('');
      const [monday2, setMonday2] = useState('');
      const [tuesday1, setTuesday1] = useState('');
      const [tuesday2, setTuesday2] = useState('');
      const [wednesday1, setWednesday1] = useState('');
      const [wednesday2, setWednesday2] = useState('');
      const [thursday1, setThursday1] = useState('');
      const [thursday2, setThursday2] = useState('');
      const [friday1, setFriday1] = useState('');
      const [friday2, setFriday2] = useState('');
      const [saturday1, setSaturday1] = useState('');
      const [saturday2, setSaturday2] = useState('');
      const [sunday1, setSunday1] = useState('');
      const [sunday2, setSunday2] = useState('');




      const [branchInfo, setBranchInfo] = useState([]);
      const [loading, setLoading] = useState(false);
      const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setCity(selectedValue);
        GetArea(selectedValue)
      };
      const handleSelectChangee = (event) => {
        const selectedValue = event.target.value;
        setsubcity(selectedValue);
      };
      const fetchBranchinfo= async (signal)=>{
      
        const {status, data, msg} = await fetchData(`/branches/${id}/${localStorage.getItem('userid')}`);
        console.log(data)
        setBranchInfo(data);
        setName(data.name)
        setsubcity(data.subcityid)
        setCity(data.cityid)
        GetArea(data.cityid)
        setAddress(data.address)
        setEmail(data.email)
        setUnit(data.unitt)
        setReference(data.reference)
        setMonday1(data.monday1)
        setMonday2(data.monday2)

        setTuesday1(data.tuesday1)
        setTuesday2(data.tuesday2)

        setWednesday1(data.wedneday1)
        setWednesday2(data.wedneday2)
        setThursday1(data.thursday1)
        setThursday2(data.thursday2)

        setFriday1(data.friday1)
        setFriday2(data.friday2)
        setSaturday1(data.saturday1)
        setSaturday2(data.saturday2)
        setSunday1(data.sunday1)
        setSunday2(data.sunday2)

        setViewport({
            latitude:data.lat,
            longitude:data.longg,
            zoom: 16,
        })
        setLoading(true)
       
        }
        const fetchCity= async (signal)=>{
  
          const {status, data, msg} = await fetchData(`/cities/dropdown`);
            console.log({data})
            setCitys(data);
      
           
            }
      useEffect(()=>{
        fetchBranchinfo()
        fetchCity()
      },[])
      const handleHoursChange = (e) => {
        const hoursInput = e.target.value.replace(/\D/g, ''); // Extract only digits
        const formattedHours = hoursInput.substring(0, 2); // Keep only the first two characters
        setMonday1(`${formattedHours}${monday1.substring(2)}`);
      };
    
      // Function to handle changes in the minutes input
      const handleMinutesChange = (e) => {
        const minutesInput = e.target.value.replace(/\D/g, ''); // Extract only digits
        const formattedMinutes = minutesInput.substring(0, 2); // Keep only the first two characters
        setMonday1(`${monday1.substring(0, 2)}${formattedMinutes}`);
      };
      const delayedGecode = debounce(() => {
        if (address !== '') {
          // Call your gecode function here
          gecodex();
        }
      }, 500); // Adjust the delay time as needed (e.g., 1000 milliseconds = 1 second)
    
      useEffect(() => {
        // This will be called each time the address changes
        delayedGecode();
    
        // Cleanup the debounce function on component unmount
        return () => delayedGecode.cancel();
      }, [address])
      const GetArea= async (x)=>{
      //  console.log(`/subcities/dropdown/${x}`)
       const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
        setSubCitys(data)
        console.log(data)
        setCitys(data);
        }
              const gecode = async (f) => {
                let x =
                  'https://maps.googleapis.com/maps/api/geocode/json?address=' +
                  f +
                  '&key=AIzaSyBCd9yNUYE6BQKavXhy265DDQ0DHkBmVvc'
            
                console.log(x)
                await fetch(x)
                  .then(response => response.json())
                  .then(json => {
              
                    mapRef.current?.flyTo({center: [json.results[0].geometry.location.lng, json.results[0].geometry.location.lat], duration: 2000,zoom:16})
                    setsearch([])
          setTimeout(() => {
            setsearch([])
        setshowsearch(true)
          }, 2000);
        
            
                    console.log(json.results[0].geometry.location.lat)
                    console.log(json.results[0].geometry.location.lng)
            
                  })
                  .catch(error => {})
              }
              const gecodex = async () => {
                const {status, data, msg} = await fetchData(`/addressgeo/${address}`);
                console.log(data.predictions)
                setsearch(data.predictions)
                // setCitys(data);
        
                
        
              }
              const isFormValid = () => {
                let x=[]
                  
                      // Validate string fields
                      if (!name) x.push('Name is required');
                      if (!Email) x.push('Email is required');
                      if (!city) x.push('City is required');
                      if (!subcity) x.push('Sub City is required');
                      if (!address) x.push('Address is required');
                      if (!unit) x.push('Unit is required');
                      if (!reference) x.push('reference is required');
                      if (!viewport) x.push('latlng is required');
                     
                    
                      // Validate email
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!emailRegex.test(Email)) x.push('Invalid email');
                    
                      // Validate images
             
                    
                
                      if (x.length > 0) {
                        console.log('Validation Errors:', x);
                        setErrors(x)
                        return false;
                      }
                    if(x.length==0){
                      setErrors([])
                      return true;
                    }
                      // If all validations pass, return true
                
                 
                    };
                    const handleSubmit = async() => {
                      setErrors([])
              
                
                      if (isFormValid()) {
                        setLoading(false)
              setError(false)
                        try {
                  
                           let userid =localStorage.getItem('userid')
                           let storeid =localStorage.getItem('storeid')
                          const formData = new FormData();
                           formData.append('storeid', storeid);
                           formData.append('name', name);
                           formData.append('email', Email);
                           formData.append('cityid', city);
                           formData.append('subcityid', subcity);
                           formData.append('unit', unit);
                           formData.append('address', address);
                           formData.append('reference', reference);
                           formData.append('lat', viewport.latitude);
                           formData.append('long', viewport.longitude);
                         
                           formData.append('userid', userid);
                           formData.append('branchid', id);

                           formData.append('monday1', monday1);
                           formData.append('monday2', monday2);
                           formData.append('tuesday1', tuesday1);
                           formData.append('tuesday2', tuesday2);
                           formData.append('wednesday1', wednesday1);
                           formData.append('wednesday2', wednesday2);
                           formData.append('thursday1', thursday1);
                           formData.append('thursday2', thursday2);
                           formData.append('friday1', friday1);
                           formData.append('friday2', friday2);
                           formData.append('saturday1', saturday1);
                           formData.append('saturday2', saturday2);
                           formData.append('sunday1', sunday1);
                           formData.append('sunday2', sunday2);
                          
                           if(extraimg.length>0){
                            for (let i = 0; i < extraimg.length; i++) {
                              formData.append(`extra_${i+1}`, extraimg[i]);
                              
                            }
                           }
                     
                        const req = await fetch("https://api.trakitrak.com/merchant/branches/edit", {
                          method:'POST',
                          headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            //'Content-Type': 'multipart/form-data'
                     
                          },
                          body: formData,
                  })
                  if(req.status==200){
                      const json = await req.json()
                      console.log(json)
              
                      setLoading(true)
                      navigate(`/stores/${localStorage.getItem('storeid')}`, {replace: true});
              
                  }else{
                      const json = await req.json()
                      console.log(json) 
                      setShowalert(true)
                      setMsg(json.message)
                      setLoading(true)
                  }
                        } catch (error) {
                          
                        }
                     
                      } else {
                          setError(true)
              
                      }
                    };
                    

                    const showAlerts = () =>{
                      return (
                        <>
                    {showalert&&(
                  <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
                      {msg}
                  </Alert>
                    )}
                       
                      </>
                      )
                    }

  return (

   <div className="container mainhomecontainer">
           {showAlerts()}
    {loading?
     <div className="card mb-5">
     <div className="card-header d-flex justify-content-between align-items-center">
         <p>Edit Branch</p>
         <input onClick={handleSubmit} type="submit" className="btn btn-outline-primary buttonx-outline" value="Save" />
     </div>

 <div className="card-body">
 {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
     <label className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />

     <label className="form-label mt-4">Email <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company" disabled={true} value={Email} onChange={(e) => setEmail(e.target.value)}  />

     <label className="form-label mt-4">City <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <select className="form-control" value={city} onChange={handleSelectChange}>
      <option value="" disabled>Select an option</option>
      {citys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
      ))}
    </select>
    <label className="form-label mt-4">Area <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <select className="form-control" value={subcity} onChange={handleSelectChangee}>
      <option value="" disabled>Select an option</option>
      {subcitys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
      ))}
    </select>
     <label className="form-label mt-4">Address <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company" value={address} onChange={(e) => setAddress(e.target.value)}  />
     {search.length>0&&showsearch&&address?
<div className="form-control" style={{marginTop:'1%'}}>
{search.map((item,i)=>(
    <div onClick={()=>{
        setAddress(item.description)
        setshowsearch(false)
        gecode(item.description)
    setsearch([])
    }} style={{borderBottomWidth:1,display:'flex',alignItems:'center',borderColor:'gray',cursor:'pointer',width:'100%',height:40,}}>
    <p>{item.description}</p>
</div>
))}
</div>:null}
     <label className="form-label mt-4">Unit <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company" value={unit} onChange={(e) => setUnit(e.target.value)}  />

     <label className="form-label mt-4">Reference <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company" value={reference} onChange={(e) => setReference(e.target.value)}  />
     {viewport.latitude && viewport.longitude && (
     <Map
     ref={mapRef}
     style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
     mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
      initialViewState={viewport}
      //viewState={viewport1}
      onMouseOut={()=>setZoom(false)}
      onMove={evt => setViewport(evt.viewState)}
    //  onMoveEnd={evt => setViewport1(evt.viewState)}

onClick={()=>setZoom(true)}
      onTouchEnd={()=>setZoom(false)}
      
     scrollZoom={zoom}  // Disable scroll zooming
         mapStyle="mapbox://styles/mapbox/streets-v11"
   >

{/* <GeocoderControl mapboxAccessToken={"pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"} position="top-left" /> */}


    {/* <Geocoder
          //mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={evt => setViewport(evt.viewState)}
          mapboxApiAccessToken={`pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q`}
          position="top-left"
        /> */}
<Marker

           longitude={viewport.longitude}
           latitude={viewport.latitude}
         />
   </Map>
     )}
                 <h5 className="text-center text-secondary mt-3">If the location does not correspond to your branch, move the map to the correct location.</h5>
                 <h6 className="mt-5">Opening times <span style={{color:'gray',fontSize:13}}>(This is the opening schedule time when our Shoppers can visit your store)</span></h6>

<div id="time-error" className="alert alert-danger alert-dismissible fade show w-100 mt-3 justify-content-between align-items-center" style={{ backgroundColor: 'red', color: 'white', padding: '10px', display: 'none' }}
role="alert">
 <div className="d-flex align-items-center">
 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
 </svg>
 <strong id="err-text" className="ms-1"></strong>
 </div>
</div> 
<div className="mt-3 table-responsive">
<table className="table">
<thead>
 <tr className="head-tr">
   <th scope="col">Day</th>
   <th scope="col">Opening time</th>
   <th scope="col">Closing time</th>
 </tr>
</thead>
<tbody>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(monday1&&monday2){
            setMonday1('')
            setMonday2('')
        }else{
            setMonday1('09:00')
            setMonday2('17:00')
        }
    
    }}
     checked={ !monday1 && !monday2}
   />
   <label className="form-check-label">Monday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: monday1 === '' && monday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
          <TimePicker 
           onChange={setMonday1} 
           value={monday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
     {/* <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={monday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={monday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     /> */}
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setMonday2} 
           value={monday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(tuesday1&&tuesday2){
            setTuesday1('')
            setTuesday2('')
        }else{
            setTuesday1('09:00')
            setTuesday2('17:00')
        }
    
    }}
     checked={!tuesday1 && !tuesday2}
   />
   <label className="form-check-label">Tuesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: tuesday1 === '' && tuesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setTuesday1} 
           value={tuesday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday1 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setTuesday2} 
           value={tuesday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ wednesday1 === '' && wednesday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(wednesday1&&wednesday2){
            setWednesday1('')
            setWednesday2('')
        }else{
            setWednesday1('09:00')
            setWednesday2('17:00')
        }
    
    }}
     checked={( !wednesday1 && !wednesday2)}
   />
   <label className="form-check-label">Wednesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: wednesday1 === '' && wednesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setWednesday1} 
           value={wednesday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setWednesday2} 
           value={wednesday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ thursday1 === '' && thursday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(thursday1&&thursday2){
            setThursday1('')
            setThursday2('')
        }else{
            setThursday1('09:00')
            setThursday2('17:00')
        }
    
    }}
     checked={( (!thursday1 && !thursday2))}
   />
   <label className="form-check-label">Thursday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: thursday1 === '' && thursday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  thursday1 === '' && thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setThursday1} 
           value={thursday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display: thursday1 === '' &&thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setThursday2} 
           value={thursday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ friday1 === '' && friday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(friday1&&friday2){
            setFriday1('')
            setFriday2('')
        }else{
            setFriday1('09:00')
            setFriday2('17:00')
        }
    
    }}
     checked={(( !friday1 && !friday2))}
   />
   <label className="form-check-label">Friday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: friday1 === '' && friday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setFriday1} 
           value={friday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setFriday2} 
           value={friday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ saturday1 === '' && saturday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(saturday1&&saturday2){
            setSaturday1('')
            setSaturday2('')
        }else{
            setSaturday1('09:00')
            setSaturday2('17:00')
        }
    
    }}
     checked={( ( !saturday1 && !saturday2))}
   />
   <label className="form-check-label">Saturday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: saturday1 === '' && saturday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday1} 
           value={saturday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday2} 
           value={saturday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={sunday1 === '' &&sunday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(sunday1&&sunday2){
            setSunday1('')
            setSunday2('')
        }else{
            setSunday1('09:00')
            setSunday2('17:00')
        }
    
    }}
     checked={( (!sunday1 && !sunday2))}
   />
   <label className="form-check-label">Sunday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display:sunday1 === '' &&sunday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display: sunday1 === '' &&sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday1} 
           value={sunday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  sunday1 === '' && sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday2} 
           value={sunday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
</tbody>
{/* <tbody>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(monday1&&monday2){
            setMonday1('')
            setMonday2('')
        }else{
            setMonday1(branchInfo.monday1)
            setMonday2(branchInfo.monday2)
        }
    
    }}
     checked={ !monday1 && !monday2}
   />
   <label className="form-check-label">Monday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: monday1 === '' && monday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"

       onChange={handleHoursChange}

       value={monday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={monday1.substring(3, 5) }
       onChange={handleMinutesChange}
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayclose1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ monday2.substring(0, 2) }
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayclose2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={ monday2.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(tuesday1&&tuesday2){
            setTuesday1('')
            setTuesday2('')
        }else{
            setTuesday1(branchInfo.tuesday1)
            setTuesday2(branchInfo.tuesday2)
        }
    
    }}
     checked={!tuesday1 && !tuesday2}
   />
   <label className="form-check-label">Tuesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: tuesday1 === '' && tuesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ tuesday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={tuesday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday1 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayclose1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ tuesday2.substring(0, 2) }
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayclose2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={ tuesday2.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ wednesday1 === '' && wednesday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(wednesday1&&wednesday2){
            setWednesday1('')
            setWednesday2('')
        }else{
            setWednesday1(branchInfo.wedneday1)
            setWednesday2(branchInfo.wedneday2)
        }
    
    }}
     checked={( !wednesday1 && !wednesday2)}
   />
   <label className="form-check-label">Wednesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: wednesday1 === '' && wednesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ wednesday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={wednesday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayclose1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ wednesday2.substring(0, 2) }
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayclose2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={ wednesday2.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ thursday1 === '' && thursday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(thursday1&&thursday2){
            setThursday1('')
            setThursday2('')
        }else{
            setThursday1(branchInfo.thursday1)
            setThursday2(branchInfo.thursday2)
        }
    
    }}
     checked={( (!thursday1 && !thursday2))}
   />
   <label className="form-check-label">Thursday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: thursday1 === '' && thursday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  thursday1 === '' && thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ thursday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={thursday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>

<td className="time-row" style={{ display: thursday1 === '' &&thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayclose1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={thursday2.substring(0, 2) }
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayclose2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={thursday2.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ friday1 === '' && friday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(friday1&&friday2){
            setFriday1('')
            setFriday2('')
        }else{
            setFriday1(branchInfo.friday1)
            setFriday2(branchInfo.friday2)
        }
    
    }}
     checked={(( !friday1 && !friday2))}
   />
   <label className="form-check-label">Friday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: friday1 === '' && friday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ friday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={friday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
     <input
       name="mondayclose1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={ friday2.substring(0, 2) }
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayclose2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={ friday2.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ saturday1 === '' && saturday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(saturday1&&saturday2){
            setSaturday1('')
            setSaturday2('')
        }else{
            setSaturday1(branchInfo.saturday1)
            setSaturday2(branchInfo.saturday2)
        }
    
    }}
     checked={( ( !saturday1 && !saturday2))}
   />
   <label className="form-check-label">Saturday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: saturday1 === '' && saturday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday1} 
           value={saturday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday2} 
           value={saturday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={sunday1 === '' &&sunday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(sunday1&&sunday2){
            setSunday1('')
            setSunday2('')
        }else{
            setSunday1(branchInfo.sunday1)
            setSunday2(branchInfo.sunday2)
        }
    
    }}
     checked={( (!sunday1 && !sunday2))}
   />
   <label className="form-check-label">Sunday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display:sunday1 === '' &&sunday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display: sunday1 === '' &&sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday1} 
           value={sunday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  sunday1 === '' && sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday2} 
           value={sunday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
</tbody> */}
</table>
</div>

 </div>
 </div>
    :
<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
<Spinner style={{marginTop:'15%'}} animation="grow" />
  </div>

}
  
    </div>
  )
}
