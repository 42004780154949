import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData, sendData } from '../utils';
import { Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

export default function EditProductsAvailability() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [sameav, setSameav] = useState(false);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [prodinfo, setProdInfo] = useState();
    const [branches, setBranches] = useState([]);
    // const [Availability, setAvailability] = useState('');
    const fetchAvailability= async (signal)=>{
        const {status, data, msg} = await fetchData(`/productbranches/${id}`);
      if(status){
        console.log(data)
        setBranches(data)
      }else{
        setShowalert(true)
        setMsg(msg)
   
      }
        }
        const fetchProduct = async (productid) => {
            const {status, data, msg} = await sendData('/getproduct', JSON.stringify({  productid: id}));
            if(status){
          console.log({data})
          setProdInfo(data);
        //    setModalImgPrev(data.img)
        //    setModalLoading(false);
            }else{
    
              setShowalert(true)
              setMsg(msg)
            }
          }
          useEffect(()=>{
    
            fetchAvailability()
            fetchProduct()
          },[])

  return (
    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',marginBottom:'5%'}}>
        {/* first div */}
        <div style={{width:'35%',marginRight:'5%',height:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Product</p>
            <div style={{width:'80%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={prodinfo?.img} style={{ height: 178, width: 178, objectFit: 'stretch', marginTop:20, borderRadius: 10,marginBottom:20 }} />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:50,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.name}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>Name</p>
                </div>
                {prodinfo?.brand?.name?
                <>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:50,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.brand?.name}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>Brand</p>
                </div>
                </>:
                null}
                
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:50,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.sku}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>SKU</p>
                </div>
            </div>
        </div>
        {/* second div */}
        <div style={{width:'35%',height:'auto',}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>All Branches</p>
            <div style={{width:'80%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
            {/* <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,alignItems:'center',justifyContent:'space-between'}}>
              <p style={{marginLeft:'5%'}}>Same Price</p>
                    <Form>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
          
                />
              
              </Form>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/> */}
            <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,alignItems:'center',justifyContent:'space-between'}}>
              <p style={{marginLeft:'5%'}}>Same availability</p>
                    <Form>
                <Form.Check 
                value={sameav}
                onChange={(e)=>setSameav(e.target.checked)}
                  type="switch"
                  id="custom-switch"
          
                />
             
              </Form>
            </div>
            {sameav&&(  
              <select value={'AVAILABLE'} style={{border:'none',marginBottom:'1.3%',width:'93%'}} >
                <option value="AVAILABLE">Available</option>
                <option value="UNAVAILABLE">Unavailable</option>
                <option value="OUT_OF_STOCK">Out of stock</option>
                <option value="OUT_OF_SEASON">Out of season</option>
              </select>)}
            </div>
            {branches.map((item,i)=>(
              <div style={{marginTop:'5%'}}>
          <div style={{display:'flex',flexDirection:'row',width:'100%',}}>
            <p style={{fontWeight:'bold',color:'black'}}>{item.branch.name}</p>
            <p style={{fontWeight:'bold',color:'gray',marginLeft:'2%'}}>{item.branch.conf2==1?'Active':'Inactive'}</p>
            </div>      
        <div style={{width:'80%',height:75, boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',justifyContent:'center'}}>
              <p style={{marginLeft:'0.9%',marginTop:'2%'}}>Availability</p>
              <select value={item.status} style={{border:'none',marginBottom:'1.3%'}} >
                <option value="AVAILABLE">Available</option>
                <option value="UNAVAILABLE">Unavailable</option>
                <option value="OUT_OF_STOCK">Out of stock</option>
                <option value="OUT_OF_SEASON">Out of season</option>
              </select>
              </div>
              </div>
            ))}
        </div>
    </div>
  )
}
