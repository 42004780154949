import React, { useEffect, useState , useRef } from 'react'
import { useNavigate, Link,useParams } from 'react-router-dom'
import { fetchData, sendData } from '../utils';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';

export default function ExchangeRate() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [rate, setRate] = useState();
    const [loading, setLoading] = useState(false);
    const fetchExchange= async (signal)=>{
        const {status, data, msg} = await fetchData(`/getExchangeRate/${id}`);
        if(status){
            setRate(data.exchange_rate);
          setLoading(true)
        }else{
          setLoading(true)
        }
        }
      useEffect(()=>{
          fetchExchange()
      },[])
      const showAlerts = () =>{
        return (
          <>
      {showalert&&(
    <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
        {msg}
    </Alert>
      )}
         
        </>
        )
      }
      const UpdateExchangeRate = async (conf2) => {
        setLoading(false)
        const {status, data, msg} = await sendData('/updateExchangeRate', JSON.stringify({store_id:id, exchange_rate:rate}), navigate);
        if(status){
            fetchExchange()
        }else{
          setShowalert(true)
          setMsg(msg)
          setLoading(true)
        }
      }
  return (
    <div>
      {loading?
            <div style={{width:'100%',height:'100%',display:'flex',marginTop:'5%',flexDirection:'column'}}>
                    {showAlerts()}
                <label>Exchange Rate</label>
                <input
                    min={0}
                    max={1000000}
                    value={rate}
                    onChange={(e)=>setRate(e.target.value)}
                    required
                    placeholder="90000"
                    type="text"
                    className="form-control"
                   
                />
                <button  disabled={rate==0||rate<0?true:false} onClick={()=>{UpdateExchangeRate()}}  style={{marginLeft:5,width:200,height:40,marginTop:'2%'}} className="btn btn-outline-primary custom-btn">Save</button>
            </div>
      :
      <div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Spinner style={{marginTop:'15%'}} animation="grow" />
        </div>
        }
    </div>
  )
}
