import React,{useState,useEffect, useRef, useMemo} from 'react'
import { useNavigate, Link,useParams } from 'react-router-dom'
import { fetchData } from '../utils';
import Map, { GeolocateControl, Marker } from "react-map-gl";
import debounce from 'lodash.debounce';

import "mapbox-gl/dist/mapbox-gl.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { Alert } from 'react-bootstrap';

function hourDifference(timeString1, timeString2) {
  // Assuming time strings are in the format HH:mm
  console.log({timeString1})
  console.log({timeString2})
  let time2='00'
  if(timeString2){
    time2 = timeString2.split(':').map(Number);
  }else{
    time2='00'
  }
  let time1='00'
  if(timeString1){
    time1 = timeString1.split(':').map(Number);
  }else{
    time1='00'
  }
  const [hours1, minutes1] = time1;
  const [hours2, minutes2] = time2;

  // Create Date objects with a fixed date and the given time
  const date1 = new Date(2000, 0, 1, hours1, minutes1);
  const date2 = new Date(2000, 0, 1, hours2, minutes2);

  // Check if date2 is earlier than date1 (next day)
  if (date2 < date1) {
    date2.setDate(date2.getDate() + 1); // Move date2 to the next day
  }

  // Calculate the hour difference
  const hourDiff = (date2 - date1) / (1000 * 60 * 60);

  return Math.abs(hourDiff); // Return the absolute value to handle negative differences
}

function isBefore(openTime, closeTime) {
  if(openTime==''&&closeTime==''){
    return true;
  }else{
    let close='00'
    let time1='00'
    if(openTime){
      time1 = openTime.split(':').map(Number);
    }else{
      time1='00'
    }
    const open = time1;
    if(closeTime){
    close = closeTime.split(':').map(Number);
    }else{
      close='00'
    }



  if (close[0] > open[0]) {
      // The close time is on the next day
      return true;
  } else if (close[0] === open[0] && close[1] >= open[1]) {
   // The close time is on the same day and at or after the open time
    return true;
    }
    return false;
  }
      // The close time is on the same day and before the open time
   
}


export default function AddBranch() {
  const navigate = useNavigate();
  const [isfirstbranch, setIsfirstbranch] = useState(localStorage.getItem('isfirstbranch'));
  const [loader, setLoader] = useState(false);
  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
    const mapRef = useRef()
    const [search, setsearch] = useState([]);
    const [showsearch, setshowsearch] = useState(true);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [extraimg, setExtraimg] = useState([]);
    const [interiorImage, setInteriorImage] = useState(null);
    const [imgs, setimgs] = useState([]);
    const [name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [citys, setCitys] = useState([]);
    const [subcitys, setSubCitys] = useState([]);
    const [city, setCity] = useState('');
    const [subcity, setsubcity] = useState('');
    const [unit, setUnit] = useState('');
    const [address, setAddress] = useState('');
    const [reference, setReference] = useState('');
    const [zoom, setZoom] = useState(false);
    const [viewport, setViewport] = useState({
      latitude: 33.888630,
      longitude: 35.495480,
      zoom: 16,
    });
    const [monday1, setMonday1] = useState('09:00');
    const [monday2, setMonday2] = useState('17:00');
    const [tuesday1, setTuesday1] = useState('09:00');
    const [tuesday2, setTuesday2] = useState('17:00');
    const [wednesday1, setWednesday1] = useState('09:00');
    const [wednesday2, setWednesday2] = useState('17:00');
    const [thursday1, setThursday1] = useState('09:00');
    const [thursday2, setThursday2] = useState('17:00');
    const [friday1, setFriday1] = useState('09:00');
    const [friday2, setFriday2] = useState('17:00');
    const [saturday1, setSaturday1] = useState('09:00');
    const [saturday2, setSaturday2] = useState('17:00');
    const [sunday1, setSunday1] = useState('09:00');
    const [sunday2, setSunday2] = useState('17:00');
    const [logoImage, setLogoImage] = useState(null);
  
      const fetchCity= async (signal)=>{
        const {status, data, msg} = await fetchData(`/cities/dropdown`);
        //console.log(data)
        setCitys(data);
        }
  useEffect(()=>{
    fetchCity()
  },[])
  const handleImages = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage && imgs.length < 3) {
        setimgs((prevImages) => [...prevImages, selectedImage]);
    }
  };
  const GetArea= async (x)=>{
    const {status, data, msg} = await fetchData(`/subcities/dropdown/${x}`);
    setSubCitys(data)
    setsubcity(data[0].id)
    //console.log(data)
    //setCitys(data);
    }
 
  const handleLogoChange = (event) => {
    const selectedFile = event.target.files[0];
    setLogoImage(selectedFile);
  };
  const handlebannerChange = (event) => {
     const selectedFile = event.target.files[0];
     setInteriorImage(selectedFile);
   };
 
   const handleSelectChangee = (event) => {
    const selectedValue = event.target.value;
    setsubcity(selectedValue);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setCity(selectedValue);
    GetArea(selectedValue)
  };

  const hasTimeError = useMemo(() => {
    const arr = [[monday1, monday2], [tuesday1, tuesday2], [wednesday1, wednesday2], [thursday1, thursday2], [friday1, friday2], [saturday1, saturday2], [sunday1, sunday2]];
    const notBefore = arr.some(day => !isBefore(day[0], day[1]));
    const hrDiff = arr.some(day => hourDifference(day[0], day[1]) < 2);
    // console.log({monday1, monday2})
    // console.log({notBefore, hrDiff})
    if(notBefore && !hrDiff) return 2;
    else if(!notBefore && hrDiff) return 1;
    else if(notBefore) return 1;
    else return false;
  },[monday1, monday2, tuesday1, tuesday2, wednesday1, wednesday2, thursday1, thursday2, friday1, friday2, saturday1, saturday2, sunday1, sunday2]);

  // console.log({hasTimeError})


    useEffect(() => {
      navigator.geolocation.getCurrentPosition((pos) => {
        setViewport({
          ...viewport,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          zoom: 16,
        });
      });
    }, []);
    const delayedGecode = debounce(() => {
        if (address !== '') {
          // Call your gecode function here
          gecodex();
        }
      }, 500); // Adjust the delay time as needed (e.g., 1000 milliseconds = 1 second)
    
      useEffect(() => {
        // This will be called each time the address changes
        delayedGecode();
    
        // Cleanup the debounce function on component unmount
        return () => delayedGecode.cancel();
      }, [address]);

    const gecode = async (f) => {
        let x =
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          f +
          '&key=AIzaSyBCd9yNUYE6BQKavXhy265DDQ0DHkBmVvc'
    
          // console.log(x)
        await fetch(x)
          .then(response => response.json())
          .then(json => {
      
            mapRef.current?.flyTo({center: [json.results[0].geometry.location.lng, json.results[0].geometry.location.lat], duration: 2000,zoom:16})
            setsearch([])
  setTimeout(() => {
    setsearch([])
setshowsearch(true)
  }, 2000);

    
            // console.log(json.results[0].geometry.location.lat)
            // console.log(json.results[0].geometry.location.lng)
    
          })
          .catch(error => {})
      }
      const gecodex = async () => {
        const {status, data, msg} = await fetchData(`/addressgeo/${address}`);
        //console.log(data.predictions)
        setsearch(data.predictions)
        // setCitys(data);

        

      }
      const isFormValid = () => {
        let x=[]
          
              // Validate string fields
              if (!name) x.push('Name is required');
              if (!Email) x.push('Email is required');
              if (!city) x.push('City is required');
              if (!subcity) x.push('Sub City is required');
              if (!address) x.push('Address is required');
              if (!unit) x.push('Unit is required');
              if (!reference) x.push('reference is required');
              if (!viewport) x.push('latlng is required');
             
            
              // Validate email
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(Email)) x.push('Invalid email');
            
              // Validate images
               if (!logoImage) x.push('Exterior image is required');
               if (!interiorImage) x.push('Interior image is required');
            
        
              if (x.length > 0) {
                console.log('Validation Errors:', x);
                setErrors(x)
                return false;
              }
            if(x.length==0){
              setErrors([])
              return true;
            }
              // If all validations pass, return true
        
         
            };
            const handleSubmit = async() => {
              setErrors([])
      
        
              if (isFormValid()) {
                setLoader(true)
      setError(false)
                try {
          
                   let userid =localStorage.getItem('userid')
                   let storeid =localStorage.getItem('storeid')
                  const formData = new FormData();
                   formData.append('storeid', storeid);
                   formData.append('name', name);
                   formData.append('email', Email);
                   formData.append('cityid', city);
                   formData.append('subcityid', subcity);
                   formData.append('unit', unit);
                   formData.append('address', address);
                   formData.append('reference', reference);
                   formData.append('lat', viewport.latitude);
                   formData.append('long', viewport.longitude);
                   formData.append('interior', interiorImage);
                   formData.append('exterior', logoImage);
                   formData.append('userid', userid);

                   formData.append('monday1', monday1);
                   formData.append('monday2', monday2);
                   formData.append('tuesday1', tuesday1);
                   formData.append('tuesday2', tuesday2);
                   formData.append('wedneday1', wednesday1);
                   formData.append('wedneday2', wednesday2);
                   formData.append('thursday1', thursday1);
                   formData.append('thursday2', thursday2);
                   formData.append('friday1', friday1);
                   formData.append('friday2', friday2);
                   formData.append('saturday1', saturday1);
                   formData.append('saturday2', saturday2);
                   formData.append('sunday1', sunday1);
                   formData.append('sunday2', sunday2);
                  
                   if(extraimg.length>0){
                    for (let i = 0; i < extraimg.length; i++) {
                      formData.append(`extra_${i+1}`, extraimg[i]);
                      
                    }
                   }
             
                const req = await fetch("https://api.trakitrak.com/merchant/branches/create", {
                  method:'POST',
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    //'Content-Type': 'multipart/form-data'
             
                  },
                  body: formData,
          })
          if(req.status==200){
              const json = await req.json()
              console.log(json)
              setLoader(false)
              let isfirstbranch = localStorage.getItem('isfirstbranch')
                if(isfirstbranch=='true'){
                  navigate(`/terms`, {replace: true});
                  localStorage.setItem('isfirstbranch','false')
                }else{
                  navigate(`/stores/${localStorage.getItem('storeid')}`, {replace: true});

                }

      
          }else{
              const json = await req.json()
              console.log(json) 
              setShowalert(true)
              setMsg(json.message)
              setLoader(false)
          }
                } catch (error) {
                  
                }
             
              } else {
                  setError(true)
      
              }
            };
            const showAlerts = () =>{
              return (
                <>
            {showalert&&(
          <Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
              {msg}
          </Alert>
            )}
               
              </>
              )
            }
  return (
    <div className="container mainhomecontainer">
           {showAlerts()}
       {loader?
       <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

       <div className="spinner-border" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
     </div>
     :
 <>
         <div className="card mb-5">
     <div className="card-header d-flex justify-content-between align-items-center">
         <p>{isfirstbranch=='true'?'Add Main Branch':'Add Branch'}</p>
         <input onClick={handleSubmit} type="submit" className="btn btn-outline-primary buttonx-outline" value="Save" />
            </div>
            <div className="card-body">
            {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
     <label className="form-label">Name <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)} />

     <label className="form-label mt-4">Email <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company"  value={Email} onChange={(e) => setEmail(e.target.value)}  />

     <label className="form-label mt-4">Interior and exterior photos of this branch <span style={{color:'gray',fontSize:13}}>(required)</span></label>
     <div id="main-img-div" className="rounded border p-2">
            <div className="border-bottom pb-2">
                <label htmlFor="exterior" className="w-100 label-input">
                    <input required accept="image/*" style={{ display: 'none' }} type="file" name="exterior" id="exterior" onChange={handleLogoChange} />
                    <div className="d-flex align-items-center">
                        <div>
                            <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                                <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                                {logoImage!=null?<img className="img-uploaded" src={URL.createObjectURL(logoImage)}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}

                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center ms-2">
                            <p>Exterior photo</p>
                            <span style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
                        </div>
                    </div>
                </label>
            </div>

            <div className="mt-2 border-bottom pb-2">
                <label className="w-100 label-input" htmlFor="interior">
                    <input required accept="image/*" style={{ display: 'none' }} type="file" name="interior" id="interior" onChange={handlebannerChange} />
                    <div className="d-flex align-items-center">
                        <div>
                            <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                                <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                                {interiorImage!=null?<img className="img-uploaded" src={URL.createObjectURL(interiorImage)}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}
                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center ms-2">
                            <p>Interior photo</p>
                            <span style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
                        </div>
                    </div>
                </label>
            </div>
            {extraimg.map((item,i)=>(
                            <div className="border-bottom pb-2">
                <label className="w-100 label-input"  style={{marginTop:'1%'}}>
            <input accept="image/*" style={{ display: 'none' }} type="file" onChange={handleImages}  />
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div>
                        <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                            <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                            </svg>
                            {imgs[i]?<img className="img-uploaded" src={URL.createObjectURL(imgs[i])}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}
                        </div>
                    </div>

                    <div className="d-flex flex-column justify-content-center ms-2">
                        <p>Add another interior or exterior photo</p>
                        <span style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
                    </div>
                </div>

                <div  onClick={(e)=>{
                    if (extraimg.length > 0) {
                        e.preventDefault()
                        setExtraimg(prevImages => prevImages.filter((_, index) => index !== i));
                    }
                }}  className='close-img'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                </div>
            </div>
        </label>
        </div>
            ))}


            <div onClick={()=>{
           if (extraimg.length < 3) {
            setExtraimg(prevImages => [...prevImages, null]);
        }
                }} id="add-img" className="p-2 mt-2 justify-content-between align-items-center" style={{ display: 'flex',cursor:'pointer' }}>
                <div className="d-flex align-items-center">
                    <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <title>plus-circle</title>
                            <desc>Created with Sketch Beta.</desc>
                            <defs></defs>
                            <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                                <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                                    <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup">
                                    </path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <p className="ms-2">Add another interior or exterior photo</p>
                </div>
                <svg height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path d="M10 7L15 12L10 17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                </svg>
            </div>
        </div>

        <label className="form-label mt-4">City <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <select className="form-control" value={city} onChange={handleSelectChange}>
      <option value="" disabled>Select an option</option>
      {citys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
      ))}
    </select>
    <label className="form-label mt-4">Area <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <select className="form-control" value={subcity} onChange={handleSelectChangee}>
      <option value="" disabled>Select an option</option>
      {subcitys.map((option, index) => (
        <option key={index} value={option.id}>{option.name}</option>
      ))}
    </select>
     <label className="form-label mt-4">Address <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" className="form-control" name="company" value={address} onChange={(e) => setAddress(e.target.value)}  />
     {search.length>0&&showsearch&&address?
<div className="form-control" style={{marginTop:'1%'}}>
{search.map((item,i)=>(
    <div onClick={()=>{
        setAddress(item.description)
        setshowsearch(false)
        gecode(item.description)
    setsearch([])
    }} style={{borderBottomWidth:1,display:'flex',alignItems:'center',borderColor:'gray',cursor:'pointer',width:'100%',height:40,}}>
    <p>{item.description}</p>
</div>
))}
</div>:null}

     <label className="form-label mt-4">Unit <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required type="text" placeholder="Example: building x, 2nd floor" className="form-control" name="company" value={unit} onChange={(e) => setUnit(e.target.value)}  />

     <label className="form-label mt-4">Reference <span style={{color: 'gray', fontSize: '13px'}}>(required)</span></label>
     <input required placeholder="Example: in front of the metro" type="text" className="form-control" name="company" value={reference} onChange={(e) => setReference(e.target.value)}  />
       {viewport.latitude && viewport.longitude && (
     <Map
     ref={mapRef}
     style={{width:'100%',height:350,marginTop:'3%',borderRadius:10}}
     mapboxAccessToken="pk.eyJ1IjoicmVwYXJ0ZWxvc2hvcHBlciIsImEiOiJjbDJqeGNhb2UwNHh4M2lxbGozNjZvbGFoIn0.4ZqBm6uBEo6EymXwgILB6Q"
      initialViewState={viewport}
       //viewState={viewport}
      onMouseOut={()=>setZoom(false)}
      onMove={evt => setViewport(evt.viewState)}

onClick={()=>setZoom(true)}
      onTouchEnd={()=>setZoom(false)}
      
     scrollZoom={zoom}  // Disable scroll zooming
         mapStyle="mapbox://styles/mapbox/streets-v11"
   >
<Marker

           longitude={viewport.longitude}
           latitude={viewport.latitude}
         />
     <GeolocateControl
       positionOptions={{ enableHighAccuracy: true }}
       trackUserLocation={true}
     />
   </Map>
     )} 
                 <h5 className="text-center text-secondary mt-3">If the location does not correspond to your branch, move the map to the correct location.</h5>


          {/* testing */}

          {/* testing */}


                <h6 className="mt-5">Opening times <span style={{color:'gray',fontSize:13}}>(This is the opening schedule time when our Shoppers can visit your store)</span></h6>

                {hasTimeError &&
                <div id="time-error" className="alert alert-danger alert-dismissible fade show w-100 mt-3 justify-content-between align-items-center" style={{ backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
                  <div className="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    </svg>
                    <strong id="err-text" className="ms-1">{hasTimeError == 1 ? 'The time between opening time and closing time must be at least 2 hours' : 'Closing time cannot be after 23:59' }</strong>
                  </div>
                </div> }

<div className="mt-3 table-responsive">
<table className="table">
<thead>
 <tr className="head-tr">
   <th scope="col">Day</th>
   <th scope="col">Opening time</th>
   <th scope="col">Closing time</th>
 </tr>
</thead>
<tbody>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(monday1&&monday2){
            setMonday1('')
            setMonday2('')
        }else{
            setMonday1('09:00')
            setMonday2('17:00')
        }
    
    }}
     checked={ !monday1 && !monday2}
   />
   <label className="form-check-label">Monday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: monday1 === '' && monday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
          <TimePicker 
           onChange={setMonday1} 
           value={monday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
     {/* <input
       name="mondayopen1"
       data-order="1"
       placeholder="--"
       inputMode="numeric"
       value={monday1.substring(0, 2)}
       autoComplete="off"
       max="23"
       min="0"
       type="number"
       className="form-control time-input"
     />
     <span>:</span>
     <input
       name="mondayopen2"
       data-order="2"
       placeholder="--"
       inputMode="numeric"
       value={monday1.substring(3, 5) }
       autoComplete="off"
       max="59"
       min="0"
       type="number"
       className="form-control time-input"
     /> */}
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  monday1 === '' && monday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setMonday2} 
           value={monday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ monday1 === '' && monday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(tuesday1&&tuesday2){
            setTuesday1('')
            setTuesday2('')
        }else{
            setTuesday1('09:00')
            setTuesday2('17:00')
        }
    
    }}
     checked={!tuesday1 && !tuesday2}
   />
   <label className="form-check-label">Tuesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: tuesday1 === '' && tuesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setTuesday1} 
           value={tuesday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  tuesday1 === '' && tuesday1 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setTuesday2} 
           value={tuesday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ wednesday1 === '' && wednesday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(wednesday1&&wednesday2){
            setWednesday1('')
            setWednesday2('')
        }else{
            setWednesday1('09:00')
            setWednesday2('17:00')
        }
    
    }}
     checked={( !wednesday1 && !wednesday2)}
   />
   <label className="form-check-label">Wednesday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: wednesday1 === '' && wednesday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setWednesday1} 
           value={wednesday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  wednesday1 === '' && wednesday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setWednesday2} 
           value={wednesday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ thursday1 === '' && thursday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(thursday1&&thursday2){
            setThursday1('')
            setThursday2('')
        }else{
            setThursday1('09:00')
            setThursday2('17:00')
        }
    
    }}
     checked={( (!thursday1 && !thursday2))}
   />
   <label className="form-check-label">Thursday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: thursday1 === '' && thursday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  thursday1 === '' && thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setThursday1} 
           value={thursday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display: thursday1 === '' &&thursday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setThursday2} 
           value={thursday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>


<tr data-checked={ friday1 === '' && friday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(friday1&&friday2){
            setFriday1('')
            setFriday2('')
        }else{
            setFriday1('09:00')
            setFriday2('17:00')
        }
    
    }}
     checked={(( !friday1 && !friday2))}
   />
   <label className="form-check-label">Friday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: friday1 === '' && friday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setFriday1} 
           value={friday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  friday1 === '' && friday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setFriday2} 
           value={friday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={ saturday1 === '' && saturday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(saturday1&&saturday2){
            setSaturday1('')
            setSaturday2('')
        }else{
            setSaturday1('09:00')
            setSaturday2('17:00')
        }
    
    }}
     checked={( ( !saturday1 && !saturday2))}
   />
   <label className="form-check-label">Saturday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display: saturday1 === '' && saturday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday1} 
           value={saturday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  saturday1 === '' && saturday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSaturday2} 
           value={saturday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
<tr data-checked={sunday1 === '' &&sunday2 === '' ? '0' : '1'}>
<th scope="row">
 <div className="form-check">
   <input
     name="monday"
     className="form-check-input"
     type="checkbox"
     onChange={()=>{
        if(sunday1&&sunday2){
            setSunday1('')
            setSunday2('')
        }else{
            setSunday1('09:00')
            setSunday2('17:00')
        }
    
    }}
     checked={( (!sunday1 && !sunday2))}
   />
   <label className="form-check-label">Sunday</label>
 </div>
</th>

<td colSpan="2" className="text-center closed-div" style={{ display:sunday1 === '' &&sunday2 === '' ? 'table-cell' : 'none' }}>
 <p>Closed</p>
</td>

<td className="time-row" style={{ display: sunday1 === '' &&sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday1} 
           value={sunday1} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>

<td className="time-row" style={{ display:  sunday1 === '' && sunday2 === '' ? 'none' : 'table-cell' }}>
 <div className="shift-input-container rounded d-flex justify-content-end align-items-center pe-1">
   <div className="d-flex align-items-center time-div">
   <TimePicker 
           onChange={setSunday2} 
           value={sunday2} 
           clearIcon={null}
           //clockIcon={null}
           disableClock={true}
           format='HH:mm'
           />
   </div>
 </div>
</td>
</tr>
</tbody>
</table>
</div>
            </div>
         </div>
         </>
         }
    </div>
  )
}