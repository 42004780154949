import React from 'react'
import { Card, Collapse, Button } from 'react-bootstrap';

export default function PaymentDetails(props) {
    const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>

    <div key={props.order.id} className="col-sm-12 col-md-6" >

    <Card className="accordion-item">

      <Card.Header style={{backgroundColor:'white',borderBottomLeftRadius:!isOpen?7:0,borderBottomRightRadius:!isOpen?7:0,borderTopRightRadius:!isOpen?7:7,borderTopLeftRadius:!isOpen?7:7,padding:17}}>
      {/* <p style={{color:'black',fontSize:14,fontWeight:'400'}} className="m-0 ">12CL-365452-5301391</p> */}

        <h2 className="accordion-header">
          <button
            onClick={() => {
              setIsOpen(!isOpen)}}
            aria-controls={`collapse-${props.order.id}`}
            aria-expanded={isOpen}
           
            className="accordion-button collapsed"
            variant="link"
          >
            <div className="d-flex justify-content-between align-items-center w-100">
            <p style={{color:'black',fontSize:14,fontWeight:'400'}} className="m-0 ">{props.order.checkoutbranch?.number}</p>
            <p style={{color:'#007aff',fontSize:16,fontWeight:'400'}} >${props.order.checkoutbranch.productstotal}</p>
          </div>
          <i style={{marginLeft:10}} className={`fas fa-chevron-down ${isOpen ? 'rotate-icon' : ''}`}></i>
          </button>

        </h2>
      </Card.Header>
      <Collapse in={isOpen}>
        <div id={`collapse-${props.order.id}`}>
          <Card.Body style={{backgroundColor:'white',padding:17,borderBottomLeftRadius:isOpen?7:0,borderBottomRightRadius:isOpen?7:0}} >
            <div className="border" style={{ borderRadius: "15px" }}>
              <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
                <p className="m-0 text-muted">Branch</p>
                <p className="m-0 text-muted">{props.order.checkoutbranch.branch.name}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
                <p className="m-0 text-muted">Total</p>
                <p className="m-0 text-muted">${props.total}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
                <p className="m-0 text-muted">Trakitrak commission</p>
                <p className="m-0 text-muted">-${props.comtotal}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
                <p className="m-0 text-muted">Tax</p>
                <p className="m-0 text-muted">-${props.tax}</p>
              </div>
              {props.fixed!=0&&(
                  <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
                  <p className="m-0 text-muted">Fixed Commission</p>
                  <p className="m-0 text-muted">-${props.fixed}</p>
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center pay-detail-contz">
                <p className="m-0 font-weight-bold">Final total</p>
                <p className="m-0 font-weight-bold">${props.finaltotal}</p>
              </div>
            </div>
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  </div>



  {/* <div key={props.order.id} className="col-sm-12 col-md-6">
  <div className="accordion" id="accordionExample">
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button  onClick={()=>setIsOpen(!isOpen)} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" >
          <div className="d-flex justify-content-between align-items-center w-100">
            <p className="m-0 font-weight-bold">{props.order.checkouttbranchid}</p>
            <p className="m-0">${props.order.checkoutbranch.productstotal}</p>
          </div>
        </button>
      </h2>

{isOpen&&(
      <div id={props.order.id}  data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <div className="border" style={{ borderRadius: "15px" }}>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
              <p className="m-0 text-muted">Branch</p>
              <p className="m-0 text-muted">{props.order.checkoutbranch.branch.name}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
              <p className="m-0 text-muted">Total</p>
              <p className="m-0 text-muted">${props.total}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
              <p className="m-0 text-muted">Trakitrak commission</p>
              <p className="m-0 text-muted">-${props.comtotal}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom pay-detail-contz">
              <p className="m-0 text-muted">Tax</p>
              <p className="m-0 text-muted">-${props.tax}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pay-detail-contz">
              <p className="m-0 font-weight-bold">Final total</p>
              <p className="m-0 font-weight-bold">${props.finaltotal}</p>
            </div>
          </div>
        </div>
      </div>
)
}
    </div>
  </div>
</div> */}
</>
  )
}
