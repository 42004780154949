import React,{useState,useEffect} from 'react'
import { fetchData, sendData } from '../utils';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { ModalTitle } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import Alert from 'react-bootstrap/Alert';
export default function AddUser() {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showAlert, setShowalert] = useState(false);
    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
  
    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);
    const [branches, setBranches] = useState([]);
    const [branchess, setBranchess] = useState([]);
    const [email, setEmail] = useState([]);
    const [selectedAdminRole, setSelectedAdminRole] = useState('1'); // Initial value can be '1' for 'Store Admin'

    const fetchBranch= async (signal)=>{
        const {status, data, msg} = await fetchData(`/stores/${localStorage.getItem('storeid')}/${localStorage.getItem('userid')}`);
        console.log(data.branches)
        setBranches(data.branches)
        }

  const sendDataa = async () => {
    setLoader(true)
    let body={}
    if(selectedAdminRole=='1'){
body=JSON.stringify({storeid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),email:email,admin:1,})
    }else{
        body=JSON.stringify({storeid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),email:email,admin:2,branches:branchess})
    }
    console.log(body)
    const {status, data, msg} = await sendData('/users/add', body, navigate);
    if(status){
      setLoader(false)
        navigate('/users', {replace: true});
    }else{
      setLoader(false)
  setShowalert(true)

    }
  }
        useEffect(()=>{
            fetchBranch()
          },[])
          const onSelect=(selectedList, selectedItem) =>{
branchess.push(selectedItem.id)
          }
          const onRemove=(selectedList, selectedItem) =>{
            console.log(selectedItem.id)
            let indexToRemove = branchess.indexOf(selectedItem.id);

if (indexToRemove !== -1) {
    branchess.splice(indexToRemove, 1);
}

            console.log({branchess})
                      }
  return (
    <>
      {showAlert&&(  <Alert onClose={() => setShowalert(false)} dismissible key={'danger'} variant={'danger'}>
        User already added
        </Alert>)}
        <Toast onClose={toggleShowB} show={showB} animation={false}>
        <Toast.Header>
        
            <strong className="me-auto">Error</strong>
       
          </Toast.Header>
          <Toast.Body>Please Select Branches!</Toast.Body>
        </Toast>
        <Toast show={showA} onClose={toggleShowA}>
        <Toast.Header>
        
        <strong className="me-auto">Error</strong>
   
      </Toast.Header>
          <Toast.Body>Please Add Email Address!</Toast.Body>
        </Toast>
        {error && (
        <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
            <div className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
            </svg>
            <strong className="ms-1">{errors[0]}</strong>
            </div>
            <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
            </button>
        </div>
        )}
        {loader?
       <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

       <div className="spinner-border" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
     </div>
     :
 <>
        <div className="container" style={{ marginTop: '5%' }}>
      <div className="mb-5">
          <label className="form-label fs-5">Email</label>
          <input
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            className="form-control w-50"
            type="email"
            name="email"
            required
          />
          <span className="text-body-secondary w-50 d-block" style={{ fontSize: '14px' }}>
            We will send an invitation. Once the user accepts the invitation, they will be able to access to the store
            information.
          </span>
        </div>

        <input type="hidden" name="storeid"  />
        <input type="hidden" name="type"  />
        {/* {type === 'edit' && <input type="hidden" name="idx" value={idx} />} */}

        <label className="form-label fs-5">User role</label>
        <div className="bg-white p-3 rounded border" style={{ width: 'fit-content' }}>
          <div className="d-flex align-items-center">
            <input
             checked={selectedAdminRole === '1'}
             onChange={() => setSelectedAdminRole('1')}
              className="form-check-input"
              required
              type="radio"
              name="admin"
              value="1"
            />
            <div className="ms-3 border-bottom pb-1 w-100">
              <p style={{ fontFamily: 'Poppins-SemiBold' }}>Store Admin</p>
              <p style={{ fontFamily: 'Poppins-Light', fontSize: '14px' }}>Can access to all store information</p>
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <input
               checked={selectedAdminRole === '2'}
               onChange={() => setSelectedAdminRole('2')}
              className="form-check-input"
              required
              type="radio"
              name="admin"
              value="2"
            />
            <div className="ms-3 pb-1">
              <p style={{ fontFamily: 'Poppins-SemiBold' }}>Branch Manager</p>
              <p style={{ fontFamily: 'Poppins-Light', fontSize: '14px' }}>
                Can only access information regarding their branches
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 branches-div" 
      //  style={{ display: type === 'edit' && data.admin === 0 ? 'block' : 'none' }}
        >
         
      {selectedAdminRole=='1'?null:
      <>
    
     <label className="form-label fs-5">Branches</label>
     <br />
          <Multiselect
options={branches} // Options to display in the dropdown
//selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
onSelect={onSelect}
 onRemove={onRemove}
displayValue="name" // Property name to display in the dropdown options
/>
</>
  }
        </div>

        <input disabled={loader?true:false} onClick={()=>{
          setShowalert(false)
            if(selectedAdminRole=='2'){
        
                if(email!=''){
                    if(branchess.length>0){
                      setError(false)
                        sendDataa() 
                    }else{
                      setErrors(['Please Select a Branch'])
                      setError(true)
                    }
                }else{
                  setErrors(['Email Address is required'])
                  errors.push('')
                  setError(true)
                    // toggleShowA()
                }
            }else {
                if(email!=''){
                  setError(false)
                    sendDataa()
                }else{
                  setErrors(['Email Address is required'])
                  setError(true)
                }
          
            }
         }} type="submit" className="btn buttonx text-white my-5" 
        value={'Send invitation'}
         />
    </div>
  </>}
    </>
    
  )
}
