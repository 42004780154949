import React, {useEffect, useState} from 'react'
import Tippy from '@tippyjs/react/headless';
import {fetchData} from "../utils"


import Select, { components, createFilter } from "react-select"
// import Select, { components, GroupHeadingProps,StylesConfig } from "react-select"
import makeAnimated from "react-select/animated";
import { borderBottom, textAlign, width } from '@mui/system';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

const animatedComponents = makeAnimated();
const generateUUID = () => Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
export default function AddProductVariant() {
    const navigate = useNavigate()
    const [loadermodal, setLoadermodal] = useState(false);
    const handleClose4 = () => setLoadermodal(false);

  const [showalert, setShowalert] = useState(false);
  const [msg, setMsg] = useState('');
  const [variants, setVariants] = useState([{id: generateUUID(), name: '', images: [{url: '', id: generateUUID(), name: '',size:''}], 
  sizes: [{id: generateUUID(), name: '', size: '', sku: '', price: '', weight: '', description: ''}]
  }]);
  const [images, setImages] = useState([]);
  const [ImageSize, setImageSize] = useState('');
  const [ImageName, setImageName] = useState('');
  const [ImagesName, setImagesName] = useState([]);
  const [ImagesSize, setImagesSize] = useState([]);
  const [cats, setCats] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({value: null, label: 'Select Brand'});
  const [newBrand, setNewBrand] = useState(false);
  const [newBrandName, setNewBrandName] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [format, setFormat] = useState('');
  const [selectedCat, setSelectedCat] = useState({value: null, label: 'Category (required)'});
  const [sku, setSku] = useState('');
  const [featured, setFeatured] = useState('0');
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [unitmeasure, setUnitMeasure] = useState('unit');
  const [unitmeasure2, setUnitMeasure2] = useState(null);
  const [prixunit1, setPrixunit1] = useState('');
  const [prixbulk1, setPrixbulk1] = useState('');
  const [prixpack1, setPrixpack1] = useState('');
  const [unit, setUnit] = useState('');
  const [weightpack1, setWeightpack1] = useState('');
  const [variation, setVariation] = useState('');
  const [size, setSize] = useState('');


  const [ErrorImg, setErrorImg] = useState('');
  const [Errors, setErrors] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
 

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeMB = 1; // Maximum file size allowed in MB

    // Check if a file is selected
    if (file) {
        // Convert file size to MB
        const fileSizeMB = file.size / (1024 * 1024);

        // Check if the file size is less than 1 MB
        if (fileSizeMB < maxSizeMB) {
            // File size is within the limit, you can proceed
            if (ImageName==file.name) {
              // If the image name exists, set image to null
             //setImage(null);
             // setErrorImg('Image with this name already exists.');
              return;
          }
            setImageName(file.name)
            setImageSize(fileSizeMB)
            setImage(file);
            setErrorImg('')
        } else {
            // File size exceeds the limit, show an error message or handle accordingly
            setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')
            setImage(null);

        }
    }
};
const handleOtherImageChange = (e, otherimg) => {
  const file = e.target.files[0];
  const maxSizeMB = 1; // Maximum file size allowed in MB

  // Check if a file is selected
  if (file) {
      // Convert file size to MB
      const fileSizeMB = file.size / (1024 * 1024);

      // Check if the file size is less than 1 MB
      if (fileSizeMB < maxSizeMB) {
        if (ImagesName.includes(file.name)) {
          // If the image name exists, set image to null
         //setImage(null);
          setErrorImg('Image with this name already exists.');
          return;
      }
       let b =[...ImagesName]
       b.push(file.name)
       setImagesName(b)
       let c =[...ImagesSize]
       c.push(fileSizeMB)
       setImagesSize(c)

        setErrorImg('')
          // File size is within the limit, update otherImages state
          setOtherImages(otherImages.map((oi) => {
              if (oi.key === otherimg.key) {
                  return { ...oi, value: file };
              } else {
                  return oi;
              }
          
          }));
      } else {
        setErrorImg('Photo is larger than 1 MB. Please compress the image or upload a different file.')

          // File size exceeds the limit, show an error message or handle accordingly
      }
  }
};
  const addTag = (checked, type) => {
      if(checked && !tags.includes(type)){
        setTags([...tags, type]);
      } else if(!checked) {
        setTags(tags.filter(i => i !== type));
      }
}
const getDropDowns = async () => {
  const {status, data, msg} = await fetchData(`/products/createdropdown`);
  if(status){ 
    if(data.brands.length > 0){
    //   setSelectedBrand({label: data.brands[0].name, value: data.brands[0].id})
      setBrands(data.brands.map(i => ({label: i.name, value: i.id})));
    }
    if(data.categories.length > 0){
     // setSelectedCat({label: data.categories[0].subsubs[0].name, value: data.categories[0].subsubs[0].value})
      setCats(data.categories.map(cat => {
        const mainlabel = cat['prodcat']['name'] + ' > ' + cat['name'];
        return {
          label: mainlabel,
          options: cat.subsubs.map(subsub => ({label: subsub.name, value: subsub.id, mainlabel }))
        }
      }));

    }
    
  }
}
function containsOnlyNumbers(str) {
  // Regular expression to match only digits
  const regex = /^[0-9]+$/;
  
  // Test the string against the regular expression
  return regex.test(str);
}
const isFormValid = () => {
  let x=[]
        // Validate string fields
        if (!selectedBrand.value) x.push('Brand is required');
        if (!selectedCat.value) x.push('Category is required');


  
    
        // if(unitmeasure=='unit'&&unitmeasure2==null){
        //   if (!prixunit1) x.push('Price is required'); 
        //   if (!unit) x.push('Weight is required'); 
        //   if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        // }
        // if(unitmeasure=='weight'&&unitmeasure2=='bulk'){
        //   if (!prixbulk1) x.push('Price is required'); 
        //   if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
        // }
        // if(unitmeasure=='weight'&&unitmeasure2=='package'){
        //   if (!prixpack1) x.push('Price is required'); 
        //   if (!weightpack1) x.push('Weight per package is required');
        //   if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
        //   if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 

        // }
        // if(unitmeasure=='weight'&&unitmeasure2=='both'){
        //   if (!prixbulk1) x.push('Price is required'); 
        //   if (!prixpack1) x.push('Price is required'); 
        //   if (!weightpack1) x.push('Weight per package is required');
        //   if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');
        //   if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers'); 
 
        // }
        // if(unitmeasure=='both'&&unitmeasure2=='weight'){
        //   if (!prixbulk1) x.push('Price is required'); 
        //   if (!prixunit1) x.push('Price is required'); 
        //   if (!unit) x.push('Weight per unit is required');
        //   if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers');

        // }
        // if(unitmeasure=='both'&&unitmeasure2=='package'){
        //   if (!prixunit1) x.push('Price is required'); 
        //   if (!prixpack1) x.push('Price is required'); 
        //   if (!weightpack1) x.push('Weight per package is required');
        //   if (!unit) x.push('Weight per unit is required');
        //   if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
        //   if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        // }
        // if(unitmeasure=='both'&&unitmeasure2=='both'){
        //   if (!prixunit1) x.push('Price is required'); 
        //   if (!prixbulk1) x.push('Price is required'); 
        //   if (!prixpack1) x.push('Price is required'); 
        //   if (!unit) x.push('Weight per unit is required');
        //   if (!weightpack1) x.push('Weight per package is required');
        //   if(!containsOnlyNumbers(unit)) x.push('Weight per unit Should be only numbers'); 
        //   if(!containsOnlyNumbers(weightpack1)) x.push('Weight per package Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixunit1)) x.push('Price Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixbulk1)) x.push('Price Should be only numbers'); 
        //   if(!containsOnlyNumbers(prixpack1)) x.push('Price Should be only numbers');
        // }
        // if(unitmeasure=='both'&&!unitmeasure2){
        //   x.push('Please choose a unit measure 2');
        // }
        // if(unitmeasure=='weight'&&!unitmeasure2){
        //   x.push('Please choose a unit measure 2');
        // }
        // if(containsOnlyNumbers(format)) x.push(`Format can't be only numbers`); 
      

      



        // Validate images
        //  if (!image) x.push('Image is required');
        //  for (let i = 0; i < otherImages.length; i++) {
        //   if (!otherImages[i].value) x.push(`Image ${i+2} is required`);
          
        //  }
  
        if (x.length > 0) {
          console.log('Validation Errors:', x);
          setErrors(x)
          return false;
        }
      if(x.length==0){
        setErrors([])
        return true;
      }
        // If all validations pass, return true
  
   
      };
      const handleSubmit = async() => {
        setErrors([])
        for (let i = 0; i < variants.length; i++) {
          for (let k = 0; k < variants[i].images.length; k++) {
            console.log(variants[i].images[k])
            
          }
        
       }
  
        if (isFormValid()) {
          setLoadermodal(true)
          setError(false)

          try {
 
          
             let userid =localStorage.getItem('userid')
             let storeid =localStorage.getItem('storeid')
            const formData = new FormData();
             formData.append('userid', userid);
             formData.append('storeid', storeid);
             formData.append('brandid', selectedBrand.value);
             formData.append('newbrand', !newBrand?0:1);
             formData.append('brandx', newBrandName);
             formData.append('categoryid', selectedCat.value);
             formData.append('variants', JSON.stringify(variants));
             formData.append('tags', JSON.stringify(tags));
             for (let i = 0; i < images.length; i++) {
              formData.append(`${images[i].key}`, images[i].file);
              //formData.append(`variant${i}${k}`, variants[i].images[k])
                // for (let k = 0; k < variants[i].images.length; k++) {
                //   formData.append(`variant${i}${k}`, variants[i].images[k]);
                // }
             }
            
          const req = await fetch("https://api.trakitrak.com/merchant/products/create/variant", {
            method:'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              //'Content-Type': 'multipart/form-data'
       
            },
            body: formData,
    })
    if(req.status==200){
        const json = await req.json()
        console.log(json)
        setLoadermodal(false)
        navigate('/products')
       


    }else{
        const json = await req.json()
        console.log(json) 
        setShowalert(true)
        setMsg(json.message)
        setLoadermodal(false)
    }
          } catch (error) {
            setLoadermodal(false)
          }
       
        } else {
          setLoader(false)
            setError(true)
            window.scrollTo({
              top: 0,
              behavior: "smooth" // Use smooth scrolling animation
            });

        }
      };   
const filterConfig = {
        ignoreCase: true,
        ignoreAccents: false,
        trim:true,
        matchFrom: 'any',
        stringify: (option) =>{
          return `${option.label} ${option.data.mainlabel}`;
        }
      };   
const groupStyles = {
    // border: `2px dotted blue`,
    color: '#8b8b93',
    backgroundColor: 'white',
    padding: '5px 0px 0px 8px',
    display: 'flex',
  };

  const GroupHeading = (props) => (
    <div style={groupStyles}>
      <p style={{fontSize:17}}>{props.data.label} </p>
      {/* <components.GroupHeading {...props} /> */}
    </div>
  );



  useEffect(() => {
    getDropDowns();
  },[])
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  return (
    <div className="container" style={{marginTop:'5%'}}>
        {showAlerts()}
            {error && (
     <div className="alert alert-danger alert-dismissible fade show w-100 mt-3 d-flex justify-content-between align-items-center" style={{backgroundColor: 'red', color: 'white', padding: '10px'}} role="alert">
         <div className="d-flex align-items-center">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
         </svg>
         <strong className="ms-1">{Errors[0]}</strong>
         </div>
         <button onClick={()=>{setError(false)}} type="button" data-bs-dismiss="alert" style={{border: 'none', backgroundColor: 'transparent'}} aria-label="Close">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
         </button>
     </div>
     )}
     {loader?
     <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10%'}}>

     <div className="spinner-border" role="status">
     <span className="visually-hidden">Loading...</span>
   </div>
   </div>
     :
       <div className="d-flex flex-column">
           <h3  className="text-center">Add Product</h3>
            <div className="d-flex my-4 justify-content-center">
                <div className="preview-container my-shadowx rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                    </svg>
                    <div className="preview-text">
                    <p>Brand · Women's jeans</p>
                    <p style={{ fontSize: '14px' }}>$36</p>
                    </div>
                    <div style={{width:'100%',marginLeft:20}}>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Variation</p>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                        <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',width:'25%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>Black</p>
                                        </div>
                                        <div style={{padding:5,display:'flex',marginLeft:5,alignItems:'center',justifyContent:'center',width:'25%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>blue</p>
                                        </div>
                                        </div>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Size</p>
                                       <div style={{display:'flex',alignItems:'center'}}>
                                       <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>S</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>M</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>L</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>XL</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>XXL</p>
                                        </div>
                                       </div>
                                    
                                    
                                    </div>
                </div>

                <div className="preview-container my-shadowx rounded mx-5" style={{ transform: 'scale(1.1)' }}>
                  {image?
                  <div style={{ width: '100px', height: '50%',borderRadius:10,justifyContent:'center',alignItems:'center',display:'flex'  }}>

                 <img id="img-preview" alt='product preview' src={URL.createObjectURL(image)} style={{ width: '70px', height: '70px',borderRadius:10  }} />
</div>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" id="no-prod" className="bi bi-card-image" viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                </svg>
                }
                 
                    <div className="preview-text">
                    <p id="name" style={{color: (newBrand && newBrandName) || (selectedBrand.value && !newBrand) || name ? 'black' : '#b7b7c2'}}><span id="brandtext"></span><span id="nametext">{newBrand && newBrandName ? `${newBrandName} · ` : selectedBrand.value && !newBrand ? `${selectedBrand.label} · ` : ''}{variants[0].sizes[0].name ? variants[0].sizes[0].name : 'Name'}</span></p>
                    <p id="pricetext" style={{ fontSize: '14px', color: prixunit1 || prixbulk1 || prixpack1 ? 'black' : '#b7b7c2' }}>{variants.length>0 ? '$'+variants[0].sizes[0].price : 'Price'}</p>
                    </div>
                    <div style={{width:'100%',marginLeft:20}}>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Variation</p>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                        <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',width:'25%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>{variants[0].name ? String(variants[0].name).substring(0,5) +`${variants[0].name.length>5?'...':''}` : 'Black'}</p>
                                        </div>
                                       
                                        </div>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Size</p>
                                       <div style={{display:'flex',alignItems:'center'}}>
                                       <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',minWidth:'12%',maxWidth:'24%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>{variants[0].sizes[0].size ?String(variants[0].sizes[0].size).substring(0,4)+`${variants[0].sizes[0].size.length>4?'...':''}`  : 'S'}</p>
                                        
                                        </div>
                                       
                                       </div>
                                    
                                    
                                    </div>
                </div>

                                <div className="preview-container my-shadowx rounded">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50%" fill="#dcdcdf" className="bi bi-card-image" viewBox="0 0 16 16">
                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                    </svg>
                                    <div className="preview-text">
                                    <p>Brand · Women's Dress</p>
                                    <p style={{ fontSize: '14px' }}>$56</p>
                                    </div>
                                    <div style={{width:'100%',marginLeft:20}}>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Variation</p>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                        <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',width:'25%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>Black</p>
                                        </div>
                                        <div style={{padding:5,display:'flex',marginLeft:5,alignItems:'center',justifyContent:'center',width:'25%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>white</p>
                                        </div>
                                        </div>
                                        <p style={{ fontSize: '12px',color:'#b7b7c2',fontFamily:'Poppins-SemiBold' }}>Size</p>
                                       <div style={{display:'flex',alignItems:'center'}}>
                                       <div style={{padding:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>S</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>M</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'black'}}>
                                        <p style={{ fontSize: '10px',color:'white' }}>L</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>XL</p>
                                        </div>
                                        <div style={{padding:5,marginLeft:5,display:'flex',alignItems:'center',justifyContent:'center',width:'12%',borderRadius:30,backgroundColor:'#d1d1d1'}}>
                                        <p style={{ fontSize: '10px',color:'black' }}>XXL</p>
                                        </div>
                                       </div>
                                    
                                    
                                    </div>
                                 
                                </div>
                </div>
          
            <div  style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                            <label className="form-label">Brand</label>
                            <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{background: '#f1f1f1', color: 'black', maxWidth: 300}}>
                                <strong className="text-black" style={{fontFamily: 'Poppins-SemiBold', fontSize: '12px'}}>IMPORTANT:</strong>
                                <p style={{fontSize: '13px'}}>This is the manufacturer's brand. If the product is produced by your store, you do not need to enter a brand.</p>
                                <p style={{fontSize: '13px', marginTop: '5px'}}>If the brand you are looking for does not exist, you can create one. Remember to take into consideration the following tips</p>
                                <ul className="myul">
                                  <li>Use capital letters only for brand names or at the beginning of the name.</li>
                                </ul>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                            </div>
                        
                            <div className="w-75">
                    <div className="rounded" style={{ backgroundColor: 'white', padding: '10px 0' }}>

                    <Select
                    isDisabled={newBrand}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti={false}
                    options={brands}
                    onChange={(e) => setSelectedBrand(e)}
                    value={selectedBrand}
                    blurInputOnSelect={true}
                    styles={{
                      container: (styles) => ({...styles, border: 0}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none'}),
                    }}
                    />

                    </div>
                    </div>

                    <div className="w-75 form-check mt-2">
                        <input checked={newBrand} onChange={(e) => setNewBrand(e.target.checked)} className="form-check-input brand-check" type="checkbox" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        Add new brand instead
                        </label>
                    </div>

                    {newBrand &&
                    <div className="mt-2 w-75 new-brand" >
                        <input value={newBrandName} onChange={(e) => setNewBrandName(e.target.value) } type="text" name="brandx" placeholder="Enter new brand name" className="form-control" />
                    </div>
                    }

                   
                <div className="d-flex w-75 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Category <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <p>The customer will be able to find your product in this category. It is very important that you classify your product in the corresponding related aisle.</p>
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                        </Tippy>
                </div>
                <div className="w-75">
                <div className="rounded" style={{ backgroundColor: 'white', padding: '10px 0' }}>
                    <Select
                    closeMenuOnSelect={false}
                    components={{GroupHeading}}
                    filterOption={createFilter(filterConfig)}
                    isMulti={false}
                    options={cats}
                    onChange={(e) => setSelectedCat(e)}
                    value={selectedCat}
                    blurInputOnSelect={true}
                    //styles={colourStyles}
                    styles={{
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        backgroundColor: isSelected ? 'white' : isFocused ? '#f1f1f1' : 'white',
                        color: isSelected ? 'black' : 'black',
                        fontFamily: isSelected ? 'Poppins-SemiBold' : '500',
                        height: 55,
                        margin:0,
                       
                        // margin: '0 15px 15px 15px',
                        display: 'flex',
                        alignItems: 'center', // Center vertically
                        borderBottom:'1px solid #f3f3f3',
                      
                        // justifyContent: 'center', // Center horizontally
                      }),
                      container: (styles) => ({...styles, border: 0,color:'black'}),
                      control: (styles) => ({...styles, border: 0, boxShadow:'none',}),
                      groupHeading: (base) => ({
                        ...base,
                        flex: '1 1',
                        color: 'white',
                        fontSize: 15,
                        margin: 100,

                      })
                    }}
                    />
                </div>
                 </div>
               
                  
                    
            </div>
            <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-1 w-75">
           <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Product Variant <span style={{ color: 'gray', fontSize: '13px' }}>(required)</span></label>
                </div>
                <p style={{color:'#8b8b93',fontSize:14}}>Select one or more stores to create your variants</p>
                <p style={{color:'#8b8b93',fontSize:14}}>Below, you can create one or more variants depending on the products you want to add.</p>

                </div>
            <div className="w-75 justify-content-between align-items-center m-auto">
            {ErrorImg?
                                <Alert dismissible key={'danger'} variant={'danger'}>
                                 <div style={{display:'flex',alignItems:'center'}}>
                                 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#ff0000" stroke="none">
<path d="M1555 5115 c-5 -2 -23 -6 -40 -10 -23 -5 -210 -185 -753 -728 -398
-397 -731 -735 -740 -752 -16 -28 -17 -113 -17 -1055 0 -564 4 -1038 8 -1055
6 -21 219 -241 730 -753 397 -398 736 -731 752 -740 27 -16 116 -17 1045 -20
558 -2 1031 0 1050 3 30 5 139 110 769 738 425 424 739 746 747 764 12 27 14
201 14 1052 0 685 -3 1029 -10 1048 -14 38 -1455 1481 -1497 1499 -27 12 -197
14 -1040 13 -554 0 -1012 -2 -1018 -4z m1276 -1078 c19 -12 43 -40 54 -62 l19
-40 -66 -883 c-73 -969 -66 -922 -138 -969 -29 -20 -46 -23 -140 -23 -94 0
-111 3 -140 23 -72 48 -66 4 -136 930 -35 463 -64 865 -64 892 0 59 25 106 72
135 30 18 51 20 269 20 229 0 237 -1 270 -23z m-165 -2394 c67 -23 154 -110
177 -177 40 -117 14 -231 -72 -317 -72 -71 -132 -94 -235 -87 -82 6 -133 28
-189 85 -67 66 -82 105 -82 213 0 78 4 102 22 135 38 71 112 134 183 156 45
13 145 9 196 -8z"/>
</g>
</svg>
                       <p style={{marginLeft:10}}>{ErrorImg}</p>
                                 </div>
                              </Alert>:null}
                {variants.map((variant, i) => {
                return (
                    <Accordion defaultActiveKey="0" key={variant.id} style={{marginTop: i == 0 ? 0 : 15}}>
                    <Accordion.Item eventKey={`${i}`}>
                      <Accordion.Header>
                       {i==0?null:
                        <span className="me-2" onClick={(e)=> {
                          setVariants(variants.filter(varr => varr.id != variant.id))
                        }}>
                          <svg fill="#e05b0e" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                          </svg>
                      </span>}
                        Product Variant #{i+1}
                        </Accordion.Header>
                      <Accordion.Body>
                      <label className="form-label mt-1">Variation <span style={{color:'gray', fontSize:'13px'}}>(required) </span>                   
                            <Tippy
                            placement='right'
                            theme='tomato'
                            render={attrs => (
                                <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black' }}>
                                <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                <ul className="myul">
                                  <li>HTML tags are not permitted.</li>
                                </ul>
                                <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>EXAMPLES:</strong>
                                  
                                <div className="d-flex align-items-center p-1">
                                  <div style={{ width: '10%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </svg>
                                  </div>
                                  <p className="ms-2" style={{ width: '90%' }}>Yellow or 14 Neutral or Dark</p>
                                </div>
                              
                                <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: '#ffdbdb' }}>
                                  <div style={{ width: '10%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                    </svg>
                                  </div>
                                  <p className="ms-2" style={{ width: '90%' }}>Color: Yellow</p>
                                </div>
                              </div>
                              

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                            </Tippy>
                      </label>
                      <input value={variant.name} 
                        onChange={(e)=>{
                            setVariants(variants.map(varr => {
                                if(varr.id == variant.id) return {...varr, name: e.target.value}
                                else return varr;
                            }))
                        }} 
                        placeholder="Example: blue" 
                        type="text" 
                        className="form-control form-placeholder variation_name" 
                    />

                            <label className="form-label mt-4">Image <span style={{color:'gray', fontSize: '13px'}}>(required)</span></label>
                            <div id="main-img-div" className="rounded border p-2">

                            {variant.images.map((_img, ik)=>{
                                return (
                                <div  key={`ik${ik}`} className={`${ik != 4 ? 'border-bottom':''} pb-2`}>
                                    <label className="w-100 label-input">
                                    <input name={`variant${i}${ik}`} 
                                    onChange={(e) => {
                                        if (e.target.files[0]) {
                                            const file = e.target.files[0];
                                            // Check if the file size is less than or equal to 1 MB (1048576 bytes)
                                            if (file.size <= 1048576) {
                                                setErrorImg("");

                                                let b = [...images,{file, key: `variant${i}${ik}`}]
            
                                                
                                                setImages(b)


                                                setImagesName()
                                                setVariants(variants.map(varr => {
                                                    if (varr.id === variant.id) {
                                                        return {
                                                            ...varr, 
                                                            images: varr.images.map((img, x) => {
                                                                if (img.id === _img.id) {
                                                                    return {
                                                                        ...img, 
                                                                        url: URL.createObjectURL(file), 
                                                                        //name: `variant${i}${x}`
                                                                         name:file.name,
                                                                         size:file.size
                                                                    };
                                                                } else return img;
                                                            })
                                                        };
                                                    } else return varr;
                                                }));
                                            } else {
                                                // Inform the user that the image size exceeds the limit
                                                setErrorImg("Image size should be less than or equal to 1 MB.");
                                                // Optionally, you can clear the input field
                                                e.target.value = null;
                                            }
                                        }
                                    }} 
                                    // onChange={(e)=>{
                                    //     if(e.target.files[0]){
                                    //     setVariants(variants.map(varr => {
                                    //         if(varr.id == variant.id){
                                    //             return {...varr, images: varr.images.map((img,x) => {
                                    //                 if(img.id == _img.id){
                                    //                     return {...img, url: URL.createObjectURL(e.target.files[0]), name: `variant${i}${x}`}
                                    //                 } else return img;
                                    //             })}
                                    //         } else return varr;
                                    //     }))
                                    //   }
                                    // }} 
                                    accept="image/*" style={{display:'none'}} type="file"  />
                                    <div className={`d-flex align-items-center justify-content-between ${ik != 0 ? 'mt-2' : ''}`}>

                                        <div className="d-flex align-items-center">
                                            <div>
                                                <div className="d-flex p-2 rounded align-items-center justify-content-center" style={{border: '0.125rem dashed black', height:'55px', width:'55px'}}>
                                                    {_img.url ? 
                                                    <img src={_img.url} className="img-uploaded" style={{width:'100%', height:'100%', objectFit: 'contain'}} />
                                                    :                            
                                                    <svg className="img-preview" style={{fill: 'black'}} xmlns="http://www.w3.org/2000/svg" height="1.8em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>}
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column justify-content-center ms-2">
                                                <p>{_img.name?_img.name:`Image ${ik+1}`}</p>
                                                <span style={{color:'gray', fontSize:'13px'}}>{_img.size?Number(_img.size/ (1024 * 1024)*1000).toFixed(0)+"KB":`Choose...`}</span>               
                                            </div>
                                        </div>

                                        {ik != 0 ?
                                        <div className="close-img me-3" onClick={(e)=>{
                                            e.preventDefault()
                                            setVariants(variants.map(v =>{
                                            if(v.id == variant.id){
                                                return {...v, images: v.images.filter(im => im.id != _img.id)}
                                            } else return v;
                                        }))}} >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                                        </div> : 
                                        null 
                                        }




                                    </div>
                                    </label>
                                </div>
                                )
                            })}

                            {variant.images.length < 5 ?
                            <div onClick={() => {
                                    if(variant.images.length <5){
                                    setVariants(variants.map(varr => {
                                    if(varr.id == variant.id){
                                        return {...varr, images: [...varr.images, {url: '', id: generateUUID(), name: ''}]}
                                    } else return varr;
                                }))
                                }

                                }} id="add-img" className="p-2 mt-2 justify-content-between align-items-center" style={{display:'flex', cursor:'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <title>plus-circle</title>
                                            <desc>Created with Sketch Beta.</desc>
                                            <defs></defs>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                                            <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                                                <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </svg>
                                        <p className="ms-2">Add another image</p>
                                    </div>
                                    <svg height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                        <path d="M10 7L15 12L10 17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </g>
                                    </svg>
                                </div> : null}
                            </div>
                            <div className="mt-1 w-100">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>Learn <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8479767-what-to-consider-when-creating-a-non-variant-products' style={{fontSize:13,color:'#007aff'}}>Best practices for uploading images.</a> You can arrange them any way you want them to show in the app.</p>
                            </div>
                          <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between',}}>
                          <label className="form-label mt-2">Sizes <span style={{color:'gray', fontSize:'13px'}}>(required) 
                   
                                </span>
                       
                            </label>
                            <span>
                            <button             
              onClick={()=>{
                setVariants(variants.map(varr => {
                    if(varr.id == variant.id){
                        return {...varr, sizes: [...varr.sizes, {id: generateUUID(), name: '', size: '', sku: '', price: '', weight: '', description: ''} ]}
                    } else return varr;
                }))
            }} 
            type="button" id="add_btn" className="mt-3 btn my-shadow d-flex align-items-center" style={{backgroundColor: 'rgb(242, 242, 242)',marginBottom:10}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
                    <p className="ms-1">Add Other Sizes</p>
                </button>
                            </span>
                          </div>
                         
                


                            <div className="sizes_div">
                       
                    

                            {variant.sizes.map((size, ix) => (
                                <div key={`size${ix}`} data-size={size.id} className="w-100 mb-2 border rounded pb-3">
                                <div className="p-2 rounded text-white d-flex justify-content-between align-items-center" style={{backgroundColor: '#dfdfdf'}}>
                                    <p style={{color:'black'}}>{size.name ? size.name : 'Size #' + (ix+1)}</p>
                                    <div className="size-trash" 
                                    onClick={()=>{
                                      // variant condition
                                      if(variant.sizes.length==1){
                                 console.log('')
                                      }else{
                                        setVariants(variants.map(varr => {if(varr.id == variant.id){ return {...varr, sizes: variant.sizes.filter(s => s.id != size.id)}}   else {return varr} }))

                                      }
                                    }} 
                                           // variant condition
                                           >
                                    <svg fill="black" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                    </svg>
                                    </div>
                                </div>
                                <div className="w-100 row ms-0 me-0 mt-1">
                                    <div className="col-md-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className="form-label mt-1 m-0">Name <span style={{color:'gray', fontSize:'13px'}}>(required)
                                </span></label>
                                                
                                                <Tippy
                                                placement="left"
                                                theme='tomato'
                                                render={attrs => (
                                                    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', width: 300 }}>
                                                    <p style={{fontSize: 14}}>This is the name of the product that will appear to the customer when this size of this variant is selected.</p>
                                                  </div>
                                                  
                    
                                                )}
                                                >
                                                  <i id="brand-tip">
                                                    <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                                                  </i>
                                                </Tippy>
                                          

                                    </div>
                                    <textarea value={size.name} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, name: e.target.value}
                                                    } return s;
                                                })}
                                            } else return varr;
                                        })
                                        setVariants(newVars);
                                    }} rows="1" type="text" className="form-control size_name"></textarea>
                                    </div >


                                    <div className="col-md-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <label className="form-label mt-1 m-0">Size <span style={{color:'gray', fontSize:'13px'}}>(required)
                                </span></label>
                                                
                                                <Tippy
                                                placement="left"
                                                theme='tomato'
                                                render={attrs => (
                                                    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', fontSize: 14, width: 300 }}>
                                                        <strong className="text-black" style={{ fontFamily: "Poppins-SemiBold", fontSize: "12px" }}>IMPORTANT:</strong>
                                                            <ul className="myul">
                                                                <li>Be brief and simple.</li>
                                                                <li style={{ marginTop: "5px" }}>HTML tags are not permitted.</li>
                                                            </ul>
                                                            <strong className="text-black" style={{ fontFamily: "Poppins-SemiBold", fontSize: "12px" }}>EXAMPLES:</strong>

                                                            <div className="d-flex align-items-center p-1">
                                                                <div style={{ width: "10%" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                                </svg>
                                                                </div>
                                                                <p className="ms-2" style={{ width: "90%" }}>5XL or 12 ct or 100 ml</p>
                                                            </div>

                                                            <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: "#ffdbdb" }}>
                                                                <div style={{ width: "10%" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                                </svg>
                                                                </div>

                                                                <p className="ms-2" style={{ width: "90%" }}>Size 5XL or capacity 5ml</p>
                                                            </div>
                                                  </div>        
                                                )}
                                                >
                                                  <i id="brand-tip">
                                                    <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                                                  </i>
                                                </Tippy>

                                    </div>
                                    <input value={size.size} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, size: e.target.value}
                                                    } return s;
                                                })}
                                            }else return varr;
                                        })
                                        setVariants(newVars);
                                    }} type="text" className="form-control" />
                                    </div>
                                    
                                    <div className="col-md-4">
                                    <label className='form-label mt-1 m-0'>SKU <span style={{color:'gray', fontSize:'13px'}}>(required)
                                </span></label>
                                    <input value={size.sku} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, sku: e.target.value}
                                                    } return s;
                                                })}
                                            }else return varr;
                                        })
                                        setVariants(newVars);
                                    }} type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                    <label className='form-label mt-1 m-0'>Price <span style={{color:'gray', fontSize:'13px'}}>(required)
                                </span></label>
                                    <input value={size.price} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, price: e.target.value}
                                                    } return s;
                                                })}
                                            } else return varr;
                                        })
                                        setVariants(newVars);
                                    }} type="number" className="form-control" />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                    <div className="tippy-parent d-flex justify-content-between align-items-center">
                                    <label className="form-label mt-1 m-0">Weight <span style={{color:'gray', fontSize:'13px'}}>(required)
                                </span></label>
                                                
                                                <Tippy
                                                placement="left"
                                                theme='tomato'
                                                render={attrs => (
                                                    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', fontSize: 14, width: 300 }}>
                                                    <p>This is the weight of this specific size, the weight doesn't appear in the app but is required for our internal system.</p>
                                                  </div>        
                                                )}
                                                >
                                                  <i id="brand-tip">
                                                    <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                                                  </i>
                                                </Tippy>
                                         
                                    </div>
                                    <input value={size.weight} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, weight: e.target.value}
                                                    } return s;
                                                })}
                                            }else return varr;
                                        })
                                        setVariants(newVars);
                                    }} type="number" className="form-control" />

                                    </div>
                                    <div className="col-md-4 mt-2">
                                    <div className="tippy-parent d-flex justify-content-between align-items-center">
                                        <label className="form-label mt-1 m-0">Description <span style={{color:'gray', fontSize:'13px'}}>(Optional)
                                </span></label>     
                                                <Tippy
                                                placement="left"
                                                theme='tomato'
                                                render={attrs => (
                                                    <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', fontSize: 14, width: 300 }}>
<div>
                                                    <strong className="text-black" style={{ fontFamily: "Poppins-SemiBold", fontSize: "12px" }}>IMPORTANT:</strong>
                                                    <ul className="myul">
                                                        <li>Avoid using subjective adjectives about the product's characteristics.</li>
                                                        <li style={{ marginTop: "5px" }}>Be brief and objective.</li>
                                                        <li style={{ marginTop: "5px" }}>Use simple and formal language.</li>
                                                        <li style={{ marginTop: "5px" }}>HTML tags are not permitted.</li>
                                                    </ul>
                                                    <strong className="text-black" style={{ fontFamily: "Poppins-SemiBold", fontSize: "12px" }}>EXAMPLES:</strong>

                                                    <div className="d-flex align-items-center p-1">
                                                        <div style={{ width: "10%" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="green" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                        </svg>
                                                        </div>
                                                        <p className="ms-2" style={{ width: "90%" }}>Sugar-free soda, lime flavor.</p>
                                                    </div>

                                                    <div className="d-flex align-items-center p-1 mt-1 rounded" style={{ backgroundColor: "#ffdbdb" }}>
                                                        <div style={{ width: "10%" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="20" fill="red" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                        </svg>
                                                        </div>

                                                        <p className="ms-2" style={{ width: "90%" }}>The best sugar-free soda. It will quench your thirst with its refreshing natural lime flavor.</p>
                                                    </div>
                                                    </div>

                                                     </div>        
                                                )}
                                                >
                                                  <i id="brand-tip">
                                                    <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                                                  </i>
                                                </Tippy>
                                         
                                    </div>
                                    <textarea value={size.description} onChange={(e)=>{
                                        const newVars = variants.map(varr => {
                                            if(varr.id == variant.id){
                                                return {...varr, sizes: variant.sizes.map((s)=>{
                                                    if(s.id == size.id){
                                                        return {...s, description: e.target.value}
                                                    } return s;
                                                })}
                                            }else return varr;
                                        })
                                        setVariants(newVars);
                                    }} rows="1" type="text" className="form-control"></textarea>
                                    </div>
                                </div>
                                </div>    
                            )
                            )}

                            </div>



                      </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                )
                })}
            </div>
      
           
            <div 
            className="w-75 justify-content-between align-items-center m-auto">
                <button             
                onClick={() => {
                    setVariants([...variants, {
                        id: generateUUID(), name: '', images: [{ url: '', id: generateUUID(), name: '',size:'' }],
                        sizes: [{ name: '', size: '', sku: '', price: '', weight: '', description: '' }]
                    }]);
                }}  
            type="button" id="add_btn" className="mt-3 btn my-shadow d-flex align-items-center" style={{backgroundColor: 'rgb(242, 242, 242)'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg>
                    <p className="ms-1">Add product variation</p>
                </button>
            </div>
            <div className="w-75 mt-2 justify-content-between align-items-center m-auto">
                            <p style={{textAlign:'left',color:'#8e8e93',fontSize:13}}>What to Consider When Creating a Variant Product.<a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8483941-what-to-consider-when-creating-a-variant-product' style={{fontSize:13,color:'#007aff'}}> Learn more.</a></p>
                            </div>
            


           {/* product tags */}
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-5 w-75">
           <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">   Product tags <span style={{ color: 'gray', fontSize: '13px' }}>(optional)</span></label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                  <p>This information is optional, but it can be important for the Shopper to know if there’s any product that needs to be treated with special care.
</p>
                              
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </div>
                </div>
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" w-75">
         
      <div className="row ms-0 me-0" >
        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Frozen"), 'Frozen')} className="tag-container rounded mt-4 bg-primary d-flex align-items-center px-3" style={{ height: '100px' }}>
            <input checked={tags.includes("Frozen")} onChange={(e) => addTag(e.target.checked, 'Frozen')} 
            value="Frozen" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Frozen</h6>
              <p>Example: Ice</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Alcohol"), 'Alcohol')} className="bg-danger mt-4 d-flex align-items-center px-3 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(121 55 89 / 80%) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Alcohol")} onChange={(e) => addTag(e.target.checked, 'Alcohol')}  value="Alcohol" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Alcohol</h6>
              <p>Example: Beer</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Fragile"), 'Fragile')} className="d-flex align-items-center px-3 mt-4 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(48 206 62) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Fragile")} onChange={(e) => addTag(e.target.checked, 'Fragile')}  value="Fragile" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Fragile</h6>
              <p>Example: Cake</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div onClick={() => addTag(!tags.includes("Tobacco"), 'Tobacco')} className="tag-container rounded d-flex align-items-center px-3 mt-4" style={{ height: '100px', background: 'linear-gradient(to right, rgb(206 48 160) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input checked={tags.includes("Tobacco")} onChange={(e) => addTag(e.target.checked, 'Tobacco')}  value="Tobacco" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Tobacco</h6>
              <p>Example: Cigarette</p>
            </div>
          </div>
        </div>
      </div>
    </div>

           {/* product tags */}
           <div style={{display:'flex',flexDirection:'column',alignSelf:'center'}}  className=" mt-5 w-75">
           <div className="d-flex w-100 mt-4 justify-content-between align-items-center">
                    <label className="form-label">Featured</label>
                    <Tippy
                            placement='left'
                            theme='tomato'
                            render={attrs => (
                              <div className='p-2 rounded my-shadow' style={{ background: '#f1f1f1', color: 'black', maxWidth: 350 }}>
                                  <strong className="text-black" style={{ fontFamily: 'Poppins-SemiBold', fontSize: '12px' }}>IMPORTANT:</strong>
                                  <p>It corresponds to the most commercial, eye-catching, and attractive products. They will also be used as a reference within Trakitrak to differentiate the location of the products in the aisles.</p>
                              
                              </div>

                            )}
                            >
                              <i id="brand-tip">
                                <svg stroke="red"  width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="2.5"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" strokeWidth="2.5"></circle> <path d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13" stroke="#ff0000" strokeWidth="2.5" strokeLinecap="round"></path> <circle cx="12" cy="16" r="1" fill="#ff0000"></circle> </g></svg>
                              </i>
                    </Tippy>
                </div>
                </div>
         {/* <label style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}} className="form-label mt-4 w-75">featured</label> */}
            <select value={featured} onChange={(e) => setFeatured(e.target.value)} style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}} className="form-select w-75" name="highlight" id="status">
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>

            <div className="my-5 w-75" style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'80%'}}>
                <input onClick={()=>{handleSubmit()}} type="submit" value="ADD PRODUCT" className="btn btn-primary buttonx w-100" />
            </div>
         {/* </div> */}
       
       </div>
         }
         <Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal2"// Custom class for centering horizontally
     show={loadermodal} onHide={handleClose4}>
    
                    <div className="modal-content" style={{height:173,backgroundColor:'#fafbff'}}>
                  <div style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <Spinner animation="border" style={{marginBottom:20}} role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p style={{fontSize:16,color:'#4a4a4a',width:132,height:65,fontWeight:'900',textAlign:'center'}}>This Process may take a few minutes</p>
                  </div>
                    </div>
        
            
       

</Modal>
    </div>
  )
}
