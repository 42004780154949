import React, { useState,useEffect } from 'react'
import { fetchData, sendData } from '../utils';
import Modal from 'react-bootstrap/Modal';

export default function PaymentsSettings() {
  const [billid, setBillid] = useState(null);
  const [billinfoid, setBbillinfoid] = useState(null);
  const [bankaccountid, setBankaccountid] = useState(null);
  const [branchess, setBranchess] = useState([]);



 
  const [bankinfoo, setbankinfoo] = useState({});
  const [billinfoo, setBillInfoo] = useState({});
  const [billInfo, setBillInfo] = useState([]);
  const [bankaccounts, setBanks] = useState([]);
    const [paymentsettings, setPaymentseetings] = useState([]);
    const [nobranches, setNobranches] = useState([]);
    const [branches, setBranches] = useState([]);
    const [branchesss, setBranchesss] = useState([]);

    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(true);
    const [showModal, setshowModal] = useState(false);
    const [BankAccount, setBankAccount] = useState(false);
    const [bill, setBill] = useState(false);
    const [paymentdetails, setPaymnentDetails] = useState(false);
    const [bankss, setBankss] = useState(false);
    const [branchmodal, setBranchModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [modalData, setModalData] = useState({branches: [], billinfo: null, bankaccount: null});
    const [changed, setChanged] = useState(false);

    const handleClose = () => setshowModal(false);
    const handleClose1 = () => setBankAccount(false);
    const handleClose2 = () => setBill(false);
    const handleClose3 = () => setPaymnentDetails(false);
    const handleClose5 = () => setBankss(false);
    const handleClose6 = () => setBranchModal(false);
    const fetchpaymentSettigs= async (signal)=>{
        const {status, data, msg} = await fetchData(`/paymentsettings/${localStorage.getItem('userid')}/${localStorage.getItem('storeid')}`);
        if(status){
         console.log({data})
         setBillInfo(data.billinfos)
         setBanks(data.bankaccounts)
         setBranches(data.nobranches)
        setPaymentseetings(data.billsettings)
        setNobranches(data.nobranches)
          setLoading(false)
    
        }else{
          setShowalert(true)
          setMsg(msg)
          setPaymentseetings([])
          setLoading(false)
        }
    
        }
        
        const sendDataa = async () => {
      
   
        let body={}

        body=JSON.stringify({billinfoid:billinfoid,bankaccountid:bankaccountid,branches:branchess.map(i=>i.id),storid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),})
            
            console.log(body)
            const {status, data, msg} = await sendData('/paymentsettings/create', body,);
            if(status){
              setBranchess([])
              setBbillinfoid(null)
              setBankaccountid(null)
              fetchpaymentSettigs()
              setshowModal(false)
            }else{
              setBranchess([])
              setBbillinfoid(null)
              setBankaccountid(null)
              setshowModal(false)
              setShowalert(true)
              setMsg(msg)
    
            }
     
      
      
        }
        const EditPayment = async (x) => {
      
   
          let body={}
  
          body=JSON.stringify({id:x,billinfoid:billinfoid,bankaccountid:bankaccountid,branches:branchess.map(i=>i.id),storid:localStorage.getItem('storeid'), userid:localStorage.getItem('userid'),})
              
              console.log(body)
              const {status, data, msg} = await sendData('/paymentsettings/edit', body,);
              if(status){
                setBranchess([])
                setBbillinfoid(null)
                setBankaccountid(null)
                fetchpaymentSettigs()
                setshowModal(false)
                setChanged(false)
                setshowModal(false)
                setPaymnentDetails(false)
              }else{
                setChanged(false)
                setBranchess([])
                setBbillinfoid(null)
                setBankaccountid(null)
                setshowModal(false)
                setshowModal(false)
                setPaymnentDetails(false)

                setShowalert(true)
                setMsg(msg)
      
              }
       
        
        
          }
    useEffect(()=>{
     
        fetchpaymentSettigs()
      },[])
     
  return (
 <div className="container" style={{ marginTop: '3%' }}>
    <div className="d-flex justify-content-between align-items-center">
      <div style={{display:'flex',flexDirection:'column'}}>
      <h3> Payment settings</h3>
      Set up how you want us to bill and pay your sales
      </div>
      <a onClick={()=>{setshowModal(true)}} className="btn buttonx text-white" >+ Set up branches</a>
    </div>
   {loading?
   null
   :
   <>
   {nobranches.length > 0 ? (
  <div className="alert alert-danger alert-dismissible fade show w-100 mt-3" style={{ backgroundColor: 'gray',border:'none', color: 'white', padding: '10px' }} role="alert">
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <svg width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
        <strong className="ms-1" style={{ fontSize: '14px' }}>There are {nobranches.length} branches where payment information has not been configured</strong>
      </div>
      <button onClick={() => setShowAlert(!showAlert)} className="text-white" type="button" style={{ border: 'none', backgroundColor: 'transparent' }}>
        <div className="d-flex align-items-center rounded p-1 px-2 alert-btn"><span style={{ fontSize: '14px' }}>Show detail</span>
          <svg style={{ transition: 'all 0.3s', transform: !showAlert ? 'rotate(0deg)' : 'rotate(180deg)' }} height="1.5em" viewBox="0 0 24 24" fill="none">
            <path d="M7 10L12 15L17 10" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </div>
      </button>
    </div>

    {showAlert ? (
      <ul>
        {nobranches.map((b, index) => (
          <li key={index}>{b.name}</li>
        ))}
      </ul>
    ) : null}
  </div>
) : null}
{paymentsettings.length==0?
 <div style={{width:'100%',height:'50vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
 <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000"
preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M832 4940 c-237 -62 -422 -259 -462 -490 -7 -46 -10 -646 -8 -2075
l3 -2010 21 -40 c46 -88 156 -137 251 -112 29 8 172 88 360 200 171 103 316
187 323 187 6 0 173 -97 372 -215 360 -215 361 -215 420 -215 32 0 74 7 94 15
19 8 185 105 370 216 222 133 342 199 354 196 11 -3 159 -90 331 -193 296
-179 314 -188 370 -192 80 -6 137 17 188 74 22 26 43 63 50 89 8 31 11 434 11
1399 l0 1355 298 3 297 3 57 27 c80 37 152 107 191 187 l32 66 0 520 0 520
-24 74 c-66 201 -236 357 -447 411 -75 20 -117 20 -1728 19 -1592 0 -1654 -1
-1724 -19z m2783 -317 c-5 -10 -18 -43 -29 -73 -21 -55 -21 -55 -26 -2001 l-5
-1947 -239 143 c-132 79 -260 149 -285 157 -57 16 -162 16 -217 0 -23 -7 -189
-100 -368 -207 l-325 -195 -293 176 c-161 97 -306 184 -323 194 -43 26 -131
50 -181 50 -97 0 -139 -18 -392 -170 l-247 -149 -3 1891 c-1 1209 1 1904 8
1927 24 91 100 170 193 202 49 17 132 18 1396 19 1275 0 1343 -1 1336 -17z
m659 -17 c60 -29 103 -73 138 -139 23 -42 23 -46 26 -511 3 -429 1 -470 -14
-487 -16 -18 -36 -19 -281 -19 l-263 0 0 493 c0 473 1 494 21 537 33 73 93
126 169 151 46 15 147 3 204 -25z"/>
<path d="M1295 3736 c-120 -53 -120 -228 -1 -286 43 -20 50 -21 724 -18 673 3
681 3 709 24 15 11 38 36 51 54 46 68 18 176 -58 219 -37 21 -43 21 -716 21
-541 -1 -685 -3 -709 -14z"/>
<path d="M1318 2888 c-50 -13 -104 -74 -113 -127 -9 -53 25 -126 74 -158 l34
-23 691 0 691 0 36 24 c97 66 91 210 -11 270 -34 21 -49 21 -700 23 -418 1
-679 -3 -702 -9z"/>
<path d="M1292 2003 c-127 -62 -110 -254 26 -293 25 -8 251 -10 709 -8 663 3
672 3 700 24 100 75 98 201 -6 271 l-34 23 -681 0 c-608 0 -685 -2 -714 -17z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No payments results found</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again</p>
</div>
:

<>
<div className="mt-3">
   {paymentsettings.map((bill, i) => (
     <div
       key={i}
       onClick={() => {
        setBillid(bill.id)
        //console.log(bill.branches)
        //setBranchess(bill.branches.map(i=>i.branch))
         setBranchesss(bill.branches.map(i=>i.branch))
         //console.log([...branches, ...bill.branches.map(i=>i.branch)])
         setBillInfoo(bill.billinfo)
         setBbillinfoid(bill.billinfoid)
         setbankinfoo(bill.bankaccount)
         setBankaccountid(bill.bankaccountid)


          setPaymnentDetails(true)
       }}
       className="account-container mb-3 rounded showmodal"
       data-show-modal="myModal"
     >
       <div className="account-content">
         <div className="billing-title">
           <strong>Branches</strong>
           <ul className="billing-title-list">
             {bill.branches.map((branch, index) => (
               <li key={index} className="rounded" style={{ marginLeft: index === 0 ? '0px' : '4px' }}>
                 {branch.branch.name}
               </li>
             ))}
           </ul>
         </div>
 
         <div className="billing-fields">
           <div className="bill-field billing-field">
             <b className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>
               Billing information:
             </b>
             <span className="bill-field-value">
               {bill.billinfo.busname} - {bill.billinfo.street}, {bill.billinfo.unit}
               {bill.billinfo.numsuite ? ', ' + bill.billinfo.numsuite : ''}, {bill.billinfo.city}
             </span>
           </div>
           <div className="bill-field billing-field">
             <b className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>
               Bank account
             </b>
             <span className="bill-field-value">
               {bill.bankaccount.acctyp} - {bill.bankaccount.accnum}
             </span>
           </div>
         </div>
       </div>
     </div>
   ))}
 </div>
</>
}
  

 </>}
     
    <Modal   scrollable dialogClassName="custom-modal" centered show={showModal} onHide={handleClose}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                         <button  onClick={()=>{
                          setBbillinfoid(null)
                          setBankaccountid(null)
                          setBranchess([])
                          setshowModal(false)
                          }} type="submit" className="btn buttonx-outline">Close</button>
<p style={{marginLeft:10}}>Payment settings</p>
                         </div>
                         <button disabled={branchess.length!=0&&billinfoid!=null&&bankaccountid!=null?false:true}  onClick={()=>sendDataa()} type="submit" className="btn buttonx-outline">Save</button>

        </div>
       </Modal.Header>
       <Modal.Body>
       <p style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BRANCHES</p>
       <p style={{ fontSize: '14px' }}>Orders placed in these branches will be billed and paid using the following information</p>
      {branchess.length==0?
     
       <div onClick={()=>setBranchModal(true)} style={{width:'100%',marginTop:'1%',height:154,backgroundColor:'#f2f2f2',display:'flex',flexDirection:'column',borderRadius:10,justifyContent:'center',alignItems:'center'}}>
       No branches selected
       <div   style={{ backgroundColor: "#888b90",justifyContent:'center',alignItems:'center',display:'flex',borderRadius:5,minWidth:120,padding:5,height:40, cursor: "pointer",marginTop:10  }}>
       <p  style={{ fontSize: "14px",color:'white',fontWeight:'bolder', }}>Select branches</p>
        </div>
       </div>
        :
     
      <div className="border rounded mt-3">
  {branchess.map((branch, i) => (
    <div key={i} className="branches-modal-item">
      <h2 className="m-0" style={{ fontSize: '20px' }}>
        <span className="branch-title-span" style={{ fontFamily: 'Poppins-Regular' }}>
          {/* {branch.name} */}
        </span>
      </h2>
      <div className="bill-field">
        <strong className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>
          Address:
        </strong>
        <span className="bill-field-value">{branch.address}</span>
      </div>
    </div>
  ))}
  <div className="branches-modal-item d-flex justify-content-end">
    <button onClick={()=>setBranchModal(true)} type="button" className="btn buttonx text-white btn-sm">
      Select branches
    </button>
  </div>
</div> 
}

  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BILLING INFORMATION</p>
  {billinfoid!=null?
 <div className="border rounded mt-3">
 <div className="branches-modal-item">
   <div className="d-flex justify-content-between align-items-center">
     <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Business/Company name</p>
     <p>{billinfoo ? billinfoo.busname : ''}</p>
   </div>
   <div className="mt-3 d-flex justify-content-between align-items-center">
     <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Activity</p>
     <p>{billinfoo ? billinfoo.activit : ''}</p>
   </div>
   <div className="mt-3 d-flex justify-content-between align-items-center">
     <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Address</p>
     <p>{billinfoo ? `${billinfoo.street}, ${billinfoo.unit}${billinfoo.numsuite ? ', ' + billinfoo.numsuite : ''}, ${billinfoo.city}` : ''}</p>
   </div>
   <div className="mt-3 d-flex justify-content-between align-items-center">
     <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>RUT</p>
     <p>{billinfoo ? billinfoo.comprat : ''}</p>
   </div>
 </div>
 <div className="branches-modal-item d-flex justify-content-end">
   <button onClick={()=>setBill(true)} type="button" className="btn buttonx text-white btn-sm">Select billing information</button>
 </div>
</div> 
:
<div onClick={()=>setBill(true)} style={{width:'100%',marginTop:'1%',height:154,backgroundColor:'#f2f2f2',display:'flex',flexDirection:'column',borderRadius:10,justifyContent:'center',alignItems:'center'}}>
You have not selected your billing information
<div  style={{ backgroundColor: "#888b90",justifyContent:'center',alignItems:'center',display:'flex',borderRadius:5,minWidth:120,padding:5,height:40, cursor: "pointer" ,marginTop:10 }}>
<p  style={{ fontSize: "14px",color:'white',fontWeight:'bolder', }}>Select billing informations</p>
 </div>

       </div>
}
 


  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BANK ACCOUNT</p>
  {bankaccountid!=null?
  <div className="border rounded mt-3">
  <div className="branches-modal-item">
    <div className="d-flex justify-content-between align-items-center">
      <p style={{ fontFamily: 'Poppins-Regular' }}>{bankinfoo ? bankinfoo.acctyp : 'Money Transfer'}</p>
      <p>{bankinfoo ? 'Nº ' + bankinfoo.accnum : ''}</p>
    </div>
    <div className="mt-3 d-flex justify-content-between align-items-center">
      <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Bank</p>
      <p>{bankinfoo ? bankinfoo.bank.name : ''}</p>
    </div>
    <div className="mt-3 d-flex justify-content-between align-items-center">
      <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Account holder</p>
      <p>{bankinfoo ? bankinfoo.name : ''}</p>
    </div>
    <div className="mt-3 d-flex justify-content-between align-items-center">
      <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>RUT</p>
      <p>{bankinfoo ? bankinfoo.idnum : ''}</p>
    </div>
    <div className="mt-3 d-flex justify-content-between align-items-center">
      <p style={{ fontFamily: 'Poppins-Regular',marginLeft:10 }}>Email</p>
      <p>{bankinfoo ? bankinfoo.email : ''}</p>
    </div>
  </div>
  <div className="branches-modal-item d-flex justify-content-end">
    <button onClick={()=>{setBankss(true)}} type="button" className="btn buttonx text-white btn-sm">Select bank account</button>
  </div>
</div>:
<div onClick={()=>{setBankss(true)}} style={{width:'100%',marginTop:'1%',height:154,backgroundColor:'#f2f2f2',display:'flex',flexDirection:'column',borderRadius:10,justifyContent:'center',alignItems:'center'}}>
  You have not selected a bank account
       <div  style={{ backgroundColor: "#888b90",justifyContent:'center',alignItems:'center',display:'flex',borderRadius:5,minWidth:120,padding:5,height:40, cursor: "pointer",marginTop:10 }}>
       <p   style={{ fontSize: "14px",color:'white',fontWeight:'bolder', }}>Add a bank account</p>
        </div>
       </div>
}
  

       </Modal.Body>
     
     </Modal>


     <Modal scrollable dialogClassName="custom-modal" centered show={BankAccount} onHide={handleClose1}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                         <button  onClick={()=>setBankAccount(false)} type="submit" className="btn buttonx-outline">Close</button>
                          <p style={{marginLeft:10}}>Select a bank account</p>
                         </div>
                         <button disabled={true}  onClick={()=>setshowModal(false)} type="submit" className="btn buttonx-outline">Save</button>

        </div>
       </Modal.Header>
       <Modal.Body>

       </Modal.Body>
       </Modal>


       <Modal style={{minHeight:'100vhm'}} backdrop="static" scrollable dialogClassName="custom-modal" centered show={bill} onHide={handleClose2}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                         <button  onClick={()=>setBill(false)} type="submit" className="btn buttonx-outline">Close</button>
                          <p style={{marginLeft:10}}>Select billing information</p>
                         </div>
                         <button disabled={billinfoid!=null?false:true}  onClick={()=>setBill(false)} type="submit" className="btn buttonx-outline">Save</button>

        </div>
       </Modal.Header>
       <Modal.Body>
{billInfo.length>0?
<>
{billInfo.map((item,i)=>(
<div onClick={()=>{
  setBbillinfoid(item.id)
setBillInfoo(item)}}  style={{backgroundColor:'#f2f2f2',marginBottom:10,borderRadius:10,padding:10,cursor:'pointer'}}>
<h3 style={{fontSize:18}}>{item.busname}</h3>
<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
<p>Activity: {item.activit}</p>
<p>Address: {item.city}</p>
<p>Company RUT: {item.comprat}</p>
{  billinfoid==item.id  && (
        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
</div>
</div>
))}
</>
:
<p>Please Add Billing informartion</p>}

       </Modal.Body>
       </Modal>


       <Modal style={{minHeight:'100vhm'}} scrollable dialogClassName="custom-modal" centered show={bankss} onHide={handleClose5}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                         <button  onClick={()=>setBankss(false)} type="submit" className="btn buttonx-outline">Close</button>
                          <p style={{marginLeft:10}}>Select billing information</p>
                         </div>
                         <button disabled={bankaccountid!=null?false:true}  onClick={()=>setBankss(false)} type="submit" className="btn buttonx-outline">Save</button>

        </div>
       </Modal.Header>
       <Modal.Body>
{bankaccounts.length>0?
<>
{bankaccounts.map((item,i)=>(
<div onClick={()=>{
  setBankaccountid(item.id)
  setbankinfoo(item)}}  style={{backgroundColor:'#f2f2f2',marginBottom:10,borderRadius:10,padding:10,cursor:'pointer'}}>
<h3 style={{fontSize:18}}>Checking account Nº {item.accnum}</h3>
<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
<p>Account holder: {item.name}</p>
<p>Bank:: {item.bank.name}</p>
<p>Company ID Number: {item.idnum}</p>
{  bankaccountid==item.id  && (
        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
</div>
</div>
))}
</>
:
<p>Please Add Billing informartion</p>}

       </Modal.Body>
       </Modal>


       <Modal style={{minHeight:'100vhm'}} scrollable dialogClassName="custom-modal" centered show={branchmodal} onHide={handleClose6}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                         <button  onClick={()=>setBranchModal(false)} type="submit" className="btn buttonx-outline">Close</button>
                          <p style={{marginLeft:10}}>Select branches</p>
                         </div>
                         <button disabled={branchess.length==0?true:false}  onClick={()=>{setChanged(true)
                          setBranchModal(false)}} type="submit" className="btn buttonx-outline">Save</button>

        </div>
       </Modal.Header>
       <Modal.Body>
{branches.length>0?
<>
{branches?.map((item,i)=>{
      const existingIndex = branchess.findIndex(branch => branch.id === item.id);

  return(
<div 
  onClick={() => {
    
    const existingIndex = branchess.findIndex(branch => branch.id === item.id);
    if (existingIndex !== -1) {
      const updatedBranchess = [...branchess.slice(0, existingIndex), ...branchess.slice(existingIndex + 1)];
      setBranchess(updatedBranchess);
    } else {
     
      const updatedBranchess = [...branchess, item];
      setBranchess(updatedBranchess);
    }
    
    // Additional logic if needed
  }}  style={{backgroundColor:'#f2f2f2',marginBottom:10,borderRadius:10,padding:10,cursor:'pointer'}}>
<h3 style={{fontSize:18}}>{item.name}</h3>
<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
<p>Address: {item.address}</p>
{  (existingIndex !== -1)  && (
        <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
</div>
</div>
)
    }
)}
</>
:
<p>Please Add Billing informartion</p>}

       </Modal.Body>
       </Modal>




       <Modal backdrop="static" scrollable dialogClassName="custom-modal" centered show={paymentdetails} onHide={handleClose3}>
       <Modal.Header>
        <div style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                         <div style={{display:'flex',alignItems:'center'}}>
                          <p style={{marginLeft:10}}>Payment settings</p>
                         </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                        <button  onClick={()=>EditPayment(billid)} type="submit" className="btn buttonx-outline">Save</button>

<button  style={{marginLeft:10}} onClick={()=>{setPaymnentDetails(false)
setChanged(false)}} type="submit" className="btn buttonx-outline">Close</button>

                        </div>
        </div>
       </Modal.Header>
       <Modal.Body>
        
       <div className="modal-body">


  <p style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BRANCHES</p>
  <p style={{ fontSize: '14px' }}>Orders placed in these branches will be billed and paid using the following information</p>

  <div className="border rounded mt-3">
  <>
  {
changed?
<>

{branchess?.map((branch, i) => {
         

  return(
<div   key={i} className="branches-modal-item">
<h2 className="m-0" style={{ fontSize: '20px' }}><span className="branch-title-span" style={{ fontFamily: 'Poppins-Regular' }}>{branch.name}</span></h2>
<div className="bill-field">
  <strong className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>Address:</strong>
  <span className="bill-field-value">{branch.address}</span>
</div>
</div>
)})}
</>:
<>

{branchesss?.map((branch, i) => {
         

  return(
<div   key={i} className="branches-modal-item">
<h2 className="m-0" style={{ fontSize: '20px' }}><span className="branch-title-span" style={{ fontFamily: 'Poppins-Regular' }}>{branch.name}</span></h2>
<div className="bill-field">
  <strong className="d-flex" style={{ fontFamily: 'Poppins-Regular' }}>Address:</strong>
  <span className="bill-field-value">{branch.address}</span>
</div>
</div>
)})}
</>
  }
  </>
    <div className="branches-modal-item d-flex justify-content-end">
      <button onClick={()=>{
        setBranchess(branchesss)
        setBranches([...branches,...branchesss])
        setBranchModal(true)}} type="button" className="btn buttonx text-white btn-sm">Select branches</button>
    </div>
  </div>

  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BILLING INFORMATION</p>
  <div className="border rounded mt-3">
    <div className="branches-modal-item">
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Business/Company name</p>
        <p>{billinfoo ? billinfoo.busname : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Activity</p>
        <p>{billinfoo ? billinfoo.activit : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Address</p>
        <p>{billinfoo ? `${billinfoo.street}, ${billinfoo.unit}${billinfoo.numsuite ? ', ' + billinfoo.numsuite : ''}, ${billinfoo.city}` : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>RUT</p>
        <p>{billinfoo ? billinfoo.comprat : ''}</p>
      </div>
    </div>
    <div className="branches-modal-item d-flex justify-content-end">
      <button onClick={()=>setBill(true)} type="button" className="btn buttonx text-white btn-sm">Select billing information</button>
    </div>
  </div>

  <p className="mt-4" style={{ color: 'gray', fontFamily: 'Poppins-Regular' }}>BANK ACCOUNT</p>

    <div className="border rounded mt-3">
    <div className="branches-modal-item">
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>{bankinfoo ? bankinfoo?.acctyp : ''}</p>
        <p>{bankinfoo ? 'Nº ' + bankinfoo?.accnum : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Bank</p>
        <p>{bankinfoo ? bankinfoo.bank?.name : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Account holder</p>
        <p>{bankinfoo ? bankinfoo?.name : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>RUT</p>
        <p>{bankinfoo ? bankinfoo?.idnum : ''}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <p style={{ fontFamily: 'Poppins-Regular' }}>Email</p>
        <p>{bankinfoo ? bankinfoo?.email : ''}</p>
      </div>
    </div>
    <div className="branches-modal-item d-flex justify-content-end">
      <button onClick={()=>setBankss(true)} type="button" className="btn buttonx text-white btn-sm">Select bank account</button>
    </div>
  </div>

</div>
       </Modal.Body>
       </Modal>
    </div>
  )
}
