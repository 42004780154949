import React, {useState, useEffect} from 'react'
import { fetchData, sendData } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS,  Tooltip, Legend, BarElement, BarController } from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(Tooltip, Legend, BarElement, BarController);

const FoundRate = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoveredOption, setHoveredOption] = useState(null);
    const [loading, setLoading]= useState(true);
    const [branches, setBranches] = useState([]);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const [branchid, setBranchid] = useState('0');
    const [period, setPeriod] = useState('week');
    const navigate = useNavigate();

    const getData = async () => {
        if(!loading) setLoading(true)
        const {status, data, msg} = await sendData(`/getreportsdata`, JSON.stringify(
            {
            type: 'found-rate', 
            storeid: localStorage.getItem("storeid"),
            userid: localStorage.getItem("userid"),
            branchid,
            period,
            }
        ), navigate);
        if(status){
            // let newData = [...data];
            // newData.forEach((product) => {
            //     product.perc = parseFloat(((product.cart5s.filter(x=>x.CartProdStatus == 'rejected' && x.rejectReason == 'OUT_OF_STOCK').length / product.cart5s.length) * 100).toFixed(2));
            // })
            setData(data.data);
            setLabels(data.labels)
            setLoading(false);
        }
    }

    const getBranches = async () => {
        const {status, data, msg} = await fetchData(`/branches/dropdown/${localStorage.getItem('storeid')}/${localStorage.getItem("userid")}`, navigate);
        if(status){
            setBranches(data);
        }
    }

    useEffect(() => {
        getData();
    }, [branchid, period])

    useEffect(() => {
        getBranches();
    }, [])
    const dropdownOptions = [
        {
          label: 'Export',
          subOptions: [
            {
              label: 'XLSX',
              subOptions: [
                { label: 'Normal' },
                { label: 'Per branch' },
              ],
            },
            {
              label: 'CSV',
              subOptions: [
                { label: 'Normal' },
                { label: 'Per branch' },
              ],
            },
          ],
        },
      ];
    const buttonStyle = {
        backgroundColor: 'white',
        cursor: 'pointer',
        boxShadow: '1px 1px 3px 0 rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '8px 8px 0 10px',
        width: '36px',
        height: '36px',
        borderRadius: '20px',
        position: 'absolute',
        right: '6%',
        top: '8%',
        zIndex: 1000, // Ensure button is above the dropdown
      };
    const dropdownStyle = {
        display: showDropdown ? 'block' : 'none',
        position: 'absolute',
        backgroundColor: 'white',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '12px 16px',
        marginTop: '40px', // Adjust based on the button size and desired dropdown position
        right: '100%',
        zIndex: 999, // Ensure dropdown is directly below the button
      };
    const subDropdownStyle = {
        display: 'none',
        position: 'absolute',
        left: '100%',
        top: 0,
        backgroundColor: 'white',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '12px 16px',
      };

  return (
    <div style={{flex: 1, overflow: 'hidden'}}>
    <div className='d-flex h-100'>
        <aside className="sidebar-containerx border-end">
        <div className="py-4">
        <div className="px-3">

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Branch</p>
                    <select onChange={e => setBranchid(e.target.value)} value={branchid} name="branchid" id="branchid" className="form-select mt-2">
                    <option value="0">All branches</option>
                        {branches.map(b => <option key={b.id} value={b.id}>{b.name}</option>)}
                    </select>

                    <p className="mt-4" style={{fontFamily: 'Poppins-Regular'}}>Period</p>
                    <select className='form-select' value={period} onChange={e => setPeriod(e.target.value)}>
                        <option value="day">By day</option>
                        <option value="week">Per week</option>
                        <option value="month">Per month</option>
                    </select>

        </div>

        </div>
    </aside>

    <main className="main-containerx" style={{width: '80%', position:'relative' }}>

        {loading ?
        <div className="py-3 px-4 d-flex justify-content-center align-items-center" style={{marginTop:'10%'}}>

            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
        : 
        <div className='py-3 px-4' style={{ position:'absolute',left:0, top: 0,width: '100%'}}>
            {data.length > 0 && (
                <>
                    <div class="p-2 bg-white table-container">
                        <Chart
                        height={'100%'}
                        data={{
                            datasets: [{
                                type: 'bar',
                                label: 'Number of products',
                                data: data.map(p=>p.prods),
                                yAxisID: 'y1',
                                backgroundColor: '#a0d0f6'
                            }, {
                                type: 'line',
                                label: 'Fulfillment rate',
                                data: data.map(p=>p.prods > 0 ? parseFloat((p.fullrate/p.prods).toFixed(2))*100:0),
                                yAxisID: 'y',
                                backgroundColor: '#fe6484',
                                borderColor: '#fe6484'
                            }],
                            labels: labels
                        }}
                        options={{  
                            scales:{
                                x:{ 
                                    grid: {
                              drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                                },
                                y:{
                                    grid: {
                              drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                                },
                                y1:{
                                    type:'linear',
                                    display:true,
                                    position:'right',
                                    grid: {
                              drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                                }
                            }}}
                        />
                    </div>
                    <div style={{paddingBottom: '20px'}}>

                    </div>
                </>
            )}
            <div className="export-button-container">
      <div className="export-button" style={{alignItems:'center',justifyContent:'center',display:'flex'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11px" height="14px">
                <path d="M3,0 L8,0 L8,5 L11,5 L5.5,10 L0,5 L3,5 L03,0" fill="#888"/>
                <rect x="0" y="12" fill="#888" width="11" height="2"/>
                </svg>
      </div>
      <ul className="dropdownn">
        <li className="dropdown-item">
          <a href="#">CSV</a>
          <ul className="nested-dropdown">
            <li><a href="#">Normal</a></li>
            <li><a href="#">Per branch</a></li>
          </ul>
        </li>
        <li className="dropdown-item">
          <a href="#">XLSX</a>
          <ul className="nested-dropdown">
            <li><a href="#">Normal</a></li>
            <li><a href="#">Per branch</a></li>
          </ul>        </li>
        {/* Add more items as needed */}
      </ul>
    </div>
           
        </div>
        }

    </main>

</div>
</div>
  )
}

export default FoundRate