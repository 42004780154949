import React from 'react'

const MapBranchIcon = (props) => {
  return (
    <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.9158 7.07196H15C12.3918 7.07196 10.2524 9.2804 10.2524 11.9727V31.5509C10.2524 34.2432 12.3918 36.4516 15 36.4516H19.4952L21.3822 38.6601L24.9519 42.8164L28.5216 38.6601L30.4086 36.4516H34.9038C37.512 36.4516 39.6514 34.2432 39.6514 31.5509V11.9727C39.6634 9.2804 37.524 7.07196 34.9158 7.07196Z"
      fill="#333335"
    />
    <path
      d="M13.1964 19.8015C14.819 19.8015 16.1411 18.4739 16.1411 16.861V6.94789H15.1555C12.2589 6.94789 10.2637 9.2804 10.2637 11.9727V16.8734C10.2637 18.4739 11.5858 19.8015 13.1964 19.8015Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M36.7188 19.8015C35.0962 19.8015 33.774 18.4739 33.774 16.861V6.94789H34.7596C37.8606 6.94789 39.6635 9.2804 39.6635 11.9727V16.8734C39.6635 18.4739 38.3414 19.8015 36.7188 19.8015Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M22.0192 16.861V7.07196H16.1418V16.861C16.1418 18.4739 17.4639 19.8015 19.0865 19.8015C20.6971 19.8015 22.0192 18.4739 22.0192 16.861Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M27.8966 16.861V7.07196H22.0192V16.861C22.0192 18.4739 23.3413 19.8015 24.9639 19.8015C26.5745 19.8015 27.8966 18.4739 27.8966 16.861Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M33.786 16.861V7.07196H27.9086V16.861C27.9086 18.4739 29.2307 19.8015 30.8533 19.8015C32.4639 19.8015 33.786 18.4739 33.786 16.861Z"
      fill="url(#paint4_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={13.2006}
        y1={6.94789}
        x2={13.2006}
        y2={19.7984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e05b0e" />
        <stop offset={0.2551} stopColor="#e05b0e" />
        <stop offset={0.6155} stopColor="#e05b0e" />
        <stop offset={1} stopColor="#e05b0e" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={36.7209}
        y1={6.94789}
        x2={36.7209}
        y2={19.7984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e05b0e" />
        <stop offset={0.2551} stopColor="#e05b0e" />
        <stop offset={0.6155} stopColor="#e05b0e" />
        <stop offset={1} stopColor="#e05b0e" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={16.1412}
        y1={13.4352}
        x2={22.0211}
        y2={13.4352}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.2585} stopColor="#FDFCFC" />
        <stop offset={1} stopColor="#FBF8F9" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={24.9611}
        y1={7.07196}
        x2={24.9611}
        y2={19.7984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#e05b0e" />
        <stop offset={0.2551} stopColor="#e05b0e" />
        <stop offset={0.6155} stopColor="#e05b0e" />
        <stop offset={1} stopColor="#e05b0e" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={30.841}
        y1={7.07196}
        x2={30.841}
        y2={19.7984}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={0.2585} stopColor="#FDFCFC" />
        <stop offset={1} stopColor="#FBF8F9" />
      </linearGradient>
    </defs>
  </svg>
  )
}

export default MapBranchIcon