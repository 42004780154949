import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const ReportsHeader = () => {
    const location = useLocation();
    const pathname = location.pathname;
  return (
    <div className="report-nav-container border-bottom d-flex justify-content-center align-items-center">
    
    <Link to="/reports/products-not-found" className={pathname == '/reports/products-not-found' ? 'active' : "inactive"}>
        Products not found
    </Link>
   
    <Link to="/reports/found-rate" className={`${pathname == '/reports/found-rate' ? 'active' : 'inactive'} mx-2`}>
        Found rate
    </Link>

    <Link to="/reports/popular-products" className={pathname == '/reports/popular-products' ? 'active' : "inactive"}>
        Popular products
    </Link>

    <Link to="/reports/orders-report" className={`${pathname == '/reports/orders-report' ? 'active' : 'inactive'} mx-2`}>
        Orders
    </Link>

    <Link to="/reports/orders-heatmap" className={pathname == '/reports/orders-heatmap' ? 'active' : "inactive"}>
        Order heatmap
    </Link>

    <Link to="/reports/sales-by-category" className={`${pathname == '/reports/sales-by-category' ? 'active' : 'inactive'} mx-2`}>
        Sales by category
    </Link>

    <Link to="/reports/product-sales" className={pathname == '/reports/product-sales' ? 'active' : "inactive"}>
        Product sales
    </Link>

    <Link to="/reports/products-searched" className={`${pathname == '/reports/products-searched' ? 'active' : 'inactive'} ms-2`}>
        Products searched
    </Link>

</div>
  )
}

export default ReportsHeader