import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Welcome1() {
  const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  return (
    <div className="container w-75" style={{marginTop:'12%'}}>
    <div className="container mainhomecontainer">
        <div className="card mb-5">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <a href="/stores/add"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></a>
                    <p className="ms-2" style={{margin:0}}>Self-evaluation</p>
                </div>
                <button
                onClick={()=>navigate('/stores/deal',{replace:true})} 
        id="continue"
        type="button"
        style={{ border: 'none', pointerEvents: isChecked ? 'auto' : 'none' }}
        disabled={!isChecked}
      >
        <a className="btn btn-outline-primary buttonx-outline" >
          Continue
        </a>
      </button>
                  </div>

            <div className="card-body pb-4">
            <div className="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" style={{fill:'#e05b0e'}} height="3em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
            </div>
            <h5 className="text-center mt-3" style={{ fontFamily: 'Poppins-SemiBold' }}>These products cannot be sold through the app</h5>

                <div className="d-flex flex-column align-items-center justify-content-center mt-5" >

                    <div className="d-flex align-items-center w-75">
                    <svg style={{width:'10%',fill:'#e05b0e'}} xmlns="http://www.w3.org/2000/svg" height="2.5em" viewBox="0 0 512 512"><path d="M488.6 23.4c31.2 31.2 31.2 81.9 0 113.1l-352 352c-31.2 31.2-81.9 31.2-113.1 0s-31.2-81.9 0-113.1l352-352c31.2-31.2 81.9-31.2 113.1 0zM443.3 92.7c-6.2-6.2-16.4-6.2-22.6 0c-12.5 12.5-23.8 15.1-37.5 17.6l-2.5 .4c-13.8 2.5-31.6 5.6-48 22c-16.7 16.7-20.9 36-24.1 50.9l0 0v0l-.2 1c-3.4 15.6-6 26.4-15.7 36.1s-20.5 12.3-36.1 15.7l-1 .2c-14.9 3.2-34.2 7.4-50.9 24.1s-20.9 36-24.1 50.9l-.2 1c-3.4 15.6-6 26.4-15.7 36.1c-9.2 9.2-18 10.8-32.7 13.4l0 0-.9 .2c-15.6 2.8-34.9 6.9-54.4 26.4c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0c12.5-12.5 23.8-15.1 37.5-17.6l2.5-.4c13.8-2.5 31.6-5.6 48-22c16.7-16.7 20.9-36 24.1-50.9l.2-1c3.4-15.6 6-26.4 15.7-36.1s20.5-12.3 36.1-15.7l1-.2c14.9-3.2 34.2-7.4 50.9-24.1s20.9-36 24.1-50.9l.2-1c3.4-15.6 6-26.4 15.7-36.1c9.2-9.2 18-10.8 32.7-13.4l.9-.2c15.6-2.8 34.9-6.9 54.4-26.4c6.2-6.2 6.2-16.4 0-22.6zM191.2 479.2l288-288L495 207c10.9 10.9 17 25.6 17 41s-6.1 30.1-17 41L289 495c-10.9 10.9-25.6 17-41 17s-30.1-6.1-41-17l-15.8-15.8zM17 305C6.1 294.1 0 279.4 0 264s6.1-30.1 17-41L223 17C233.9 6.1 248.6 0 264 0s30.1 6.1 41 17l15.8 15.8-288 288L17 305z"/></svg>
                    <div className="ms-2" style={{width:'90%'}}>
                            <a style={{ fontFamily: 'Poppins-SemiBold' }}>Hot food</a>
                            <p style={{ fontSize: '15px' }}>Anything that needs be stored in a specific way for it to stay hot</p>
                        </div>
                    </div>

                    <div className="d-flex align-items-center w-75 my-4">
                    <svg style={{width:'10%',fill:'#e05b0e'}} fill="#e05b0e" height="2.5em" viewBox="0 0 50 50" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" overflow="inherit" stroke="#e05b0e"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M6 11v33.74c0 1.47 1.237 3.26 2.76 3.26h33.218c1.519 0 3.022-1.79 3.022-3.26v-33.74h-39zm19.46 26.776c-5.86 0-10.611-4.594-10.611-10.263s4.751-10.263 10.611-10.263 10.611 4.594 10.611 10.263c0 5.67-4.751 10.263-10.611 10.263zm16.518-36.776h-33.218c-1.523 0-2.76 1.033-2.76 2.505v5.495h39v-5.495c0-1.472-1.503-2.505-3.022-2.505zm-22.978 6h-11v-4h11v4zm19.146-.28c-1.249 0-2.258-.979-2.258-2.188 0-1.207 1.009-2.186 2.258-2.186s2.261.979 2.261 2.186c-.001 1.208-1.012 2.188-2.261 2.188z"></path></g></svg>
                    <div className="ms-2" style={{width:'90%'}}>
                             <a style={{ fontFamily: 'Poppins-SemiBold' }}>Large objects</a>
                            <p style={{ fontSize: '15px' }}>Anything that won't fit in the trunk of a car</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center w-75">
                    <svg style={{width:'10%',fill:'#e05b0e'}} xmlns="http://www.w3.org/2000/svg" height="2.5em" viewBox="0 0 640 512"><path d="M96 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32V224v64V448c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384H64c-17.7 0-32-14.3-32-32V288c-17.7 0-32-14.3-32-32s14.3-32 32-32V160c0-17.7 14.3-32 32-32H96V64zm448 0v64h32c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32v64c0 17.7-14.3 32-32 32H544v64c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32V288 224 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32zM416 224v64H224V224H416z"/></svg>

                    <div className="ms-2" style={{width:'90%'}}>
                             <a style={{ fontFamily: 'Poppins-SemiBold' }}>Heavy objects</a>
                            <p style={{ fontSize: '15px' }}>Over 20lbs</p>
                        </div>
                    </div>

                    <div className="d-flex align-items-center w-75 my-4">
                    <svg style={{width:'10%',fill:'#e05b0e'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="2.5em" stroke="#e05b0e"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M1 20.24C1 19.8203 1.3436 19.48 1.76744 19.48H22.2326C22.6564 19.48 23 19.8203 23 20.24C23 20.6597 22.6564 21 22.2326 21H1.76744C1.3436 21 1 20.6597 1 20.24Z" fill="#e05b0e"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.68981 3.8904C2.7907 4.78079 2.7907 6.21386 2.7907 9.08V14.1467C2.7907 16.0574 2.7907 17.0128 3.39011 17.6064C3.98952 18.2 4.95425 18.2 6.88372 18.2H17.1163C19.0457 18.2 20.0105 18.2 20.6099 17.6064C21.2093 17.0128 21.2093 16.0574 21.2093 14.1467V9.08C21.2093 6.21386 21.2093 4.78079 20.3102 3.8904C19.4111 3 17.964 3 15.0698 3H8.93023C6.03603 3 4.58893 3 3.68981 3.8904ZM8.16279 15.16C8.16279 14.7403 8.50639 14.4 8.93023 14.4H15.0698C15.4936 14.4 15.8372 14.7403 15.8372 15.16C15.8372 15.5797 15.4936 15.92 15.0698 15.92H8.93023C8.50639 15.92 8.16279 15.5797 8.16279 15.16Z" fill="#e05b0e"></path> </g></svg>
                    <div className="ms-2" style={{width:'90%'}}>
                             <a style={{ fontFamily: 'Poppins-SemiBold' }}>Valuable objects</a>
                            <p style={{ fontSize: '15px' }}>Greater in value than $500</p>
                        </div>
                    </div>

                    <div className="d-flex align-items-center w-75">
                    <svg style={{width:'10%',fill:'#e05b0e'}} xmlns="http://www.w3.org/2000/svg" height="2.5em" viewBox="0 0 512 512"><path d="M61.1 224C45 224 32 211 32 194.9c0-1.9 .2-3.7 .6-5.6C37.9 168.3 78.8 32 256 32s218.1 136.3 223.4 157.3c.5 1.9 .6 3.7 .6 5.6c0 16.1-13 29.1-29.1 29.1H61.1zM144 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm240 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32zM272 96a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM16 304c0-26.5 21.5-48 48-48H448c26.5 0 48 21.5 48 48s-21.5 48-48 48H64c-26.5 0-48-21.5-48-48zm16 96c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16v16c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V400z"/></svg>
                    <div className="ms-2" style={{width:'90%'}}>
                             <a style={{ fontFamily: 'Poppins-SemiBold' }}>Prepared on demand</a>
                            <p style={{ fontSize: '15px' }}>This creates longer waiting times for your customers</p>
                        </div>
                    </div>

                    <div className="d-flex p-3 border rounded mt-4 align-items-center">
  <input type="checkbox" checked={isChecked}
        onChange={handleCheckboxChange} className="form-check-input m-0" style={{ transform: 'scale(1.3)',backgroundColor:isChecked?"#e05b0e":"white",borderColor:isChecked?"#e05b0e":"gray" }} />
  <p className="ms-2" style={{ fontFamily: 'Poppins-Regular',margin:0 }}>
    Understood. I will not sell these types of products.
  </p>
</div>

                </div>


            </div>

        </div>
    </div>
</div>
  )
}
