import React,{useState,useEffect, useRef} from 'react'
import { fetchData, sendData } from '../utils';
import ProdItem from './ProdItem';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Multiselect from 'multiselect-react-dropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Spinner from 'react-bootstrap/Spinner';
function useOutsideAlerter(ref,setIsExpanded) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        setIsExpanded(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default function Products() {
  const {id}= useParams()
  const fileInputRef = useRef(null)
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef ,setIsExpanded);


  const [totalentries, setTotalentries] = useState(0);
  const [validentries, setValidentries] = useState(0);
  const [invalidentries, setInvalidentries] = useState(0);
  const [preimporterror, setPreImportError] = useState([]);
  const [emptyexcel, setEmptyexcel] = useState(false);


  const [sameav, setSameav] = useState(false);
  const [prodinfo, setProdInfo] = useState();
  const [branchesav, setBranchesav] = useState([]);
  const [excel, setexcel] = useState(null);
  const [excel1, setexcel1] = useState(null);
  const [excel2, setexcel2] = useState(null);
  const [branchname, setBranchName] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [modal, setClose] = useState(false);
  const [modal1, setClose1] = useState(false);
  const [loadermodal, setLoadermodal] = useState(false);
  const [modal2, setClose2] = useState(false);
  const [exportmdodal, setExportmdodal] = useState(false);
  const [processmodal, setProcessmodal] = useState(false);
  const [processloader, setProcessloader] = useState(false);
  const [processloader1, setProcessloader1] = useState(false);
  const [processapproved, setprocessapproved] = useState(false);
  const [bulkupdate, setBulkUpdate] = useState(false);
  

  const handleClosemodal = () => setClose(false);
  const handleClosemodal1 = () => setClose1(false);
  const handleClosemodal2 = () => setClose2(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleClose4 = () => setLoadermodal(false);
  const handleProcess = () => setProcessmodal(false);
  const handleProcessapproved = () => setBulkUpdate(false);
  const handlebulkupdate = () => setprocessapproved(false);


    const [branchess, setBranchess] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showalert, setShowalert] = useState(false);
    const [msg, setMsg] = useState('');
    const [branches, setBranches] = useState([]);
    const [modalLoading, setModalLoading] = useState(true);
    const [modalLoading2, setModalLoading2] = useState(false);
    const [modalInput, setModalInput] = useState(null);
    const [modalInput1, setModalInput1] = useState(null);
    const [modalInput2, setModalInput2] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalImgPrev, setModalImgPrev] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(0);
    const [search, setSearch] = useState('');
    const [branchid, setBranchid] = useState('0');
    const [statuss, setStatus] = useState('ALL');
    const [products, setProducts] = useState([]);
    const [firstFetch, setFirstFetch] = useState(false);

//edit product states
const [imgs, setimgs] = useState([]);
const [avArray, setAvArray] = useState([]);
const [extraimg, setExtraimg] = useState([]);
const [name, setName] = useState('');
const [format, setFormat] = useState('');
const [Brand, setBrand] = useState('');
const [sku, setSku] = useState('');
const [des, setDes] = useState('');
const [cat, setCat] = useState('');
const [price, setPrice] = useState('');
const [img, setImg] = useState('');
const [img2, setImg2] = useState('');
const [img3, setImg3] = useState('');
const [img4, setImg4] = useState('');
const [img5, setImg5] = useState('');
//end edit product states
const [variants, setVariants] = useState([]);
const [product, setProduct] = useState();

    const fetchBranch= async (signal)=>{
      if(localStorage.getItem('storeid')){
        const {status, data, msg} = await fetchData(`/stores/${localStorage.getItem('storeid')}/${localStorage.getItem('userid')}`);
        console.log({data})
        setBranches(data.branches)
      }
     
      }
      const handleChildClick = () => {
        console.log("Function called from child component");
      };
  const fetchprods = async () => {
    setLoading(true)
    console.log(JSON.stringify({statuss, branchid, storeid: localStorage.getItem('storeid'),search,page}))
    const {status, data, msg} = await sendData('/products', JSON.stringify({status:statuss, branchid, storeid: localStorage.getItem('storeid'),search,page}));
    if(status){
      if(data.products.length>0){

     
      console.log(data)
      setProducts(data.products);
      let totalPagesx = Math.ceil(data.count / data.limit);
      if(totalPagesx == 0) totalPagesx = 1;
      setTotalPages(totalPagesx);
      setLimit(data.limit);
      setCount(data.count);
      setLoading(false)
      setFirstFetch(true)
    }else{
      setProducts([]);
      setLoading(false);
      setFirstFetch(true)
    }
    }else{
      setShowalert(true)
      setMsg(msg)

      setProducts([]);
      setLoading(false);
      setFirstFetch(true)
    }
  }
     
      const fetchProducts = async (withAbort) => {
        if(!loading) setLoading(true);
        let controller = null;
        if(withAbort) controller = new AbortController();
        const req = await fetch('/appmerchant/getproducts', {
        method: 'POST',
        signal: withAbort ? controller.signal: null,
        headers: {
        'X-CSRF-TOKEN': "{{csrf_token()}}",
        'Content-Type': "application/json"
        },
        body: JSON.stringify({
        statuss,
        branchid,
        storeid: "{{$storeid}}",
        search,
        page
        })
        })
        if(req.status == 200){
        const res = await req.json();
        if(controller){
        controller.abort()
        controller = null;
        }
        setProducts(res.products);
        let totalPagesx = Math.ceil(res.count / res.limit);
        if(totalPagesx == 0) totalPagesx = 1;
        setTotalPages(totalPagesx);
        setLimit(res.limit);
        setCount(res.count);
        setLoading(false)
        setFirstFetch(true)
        }else{
            if(controller){
            controller.abort()
            controller = null;
            }
            setProducts([]);
            setLoading(false);
            setFirstFetch(true)
        }
}
    useEffect(()=>{
      fetchBranch()
    },[])
    useEffect(()=>{
      fetchprods();
  },[branchid, statuss, page]);

 
  useEffect(()=>{
      let typingTimer = null;

      if(firstFetch){
          setLoading(true);
          typingTimer = setTimeout(()=>{
            fetchprods(true);
          }, 850)
      }

      return () =>{
          if(typingTimer) clearTimeout(typingTimer);
      }
  },[search]);

  const setModalState = (state) => {
      setModalLoading(state);
  }
  const setModalInfo = (state) => {
      setModalData(state);
  }
  const showAlerts = () =>{
    return (
      <>
  {showalert&&(
<Alert key={'danger'} variant={'danger'} onClose={() => setShowalert(false)} dismissible>
    {msg}
</Alert>
  )}
     
    </>
    )
  }
  const onSelect=(selectedList, selectedItem) =>{
    let b = [...branchess];
    b.push(selectedItem.id)
    setBranchess(b)
              }
  const onRemove=(selectedList, selectedItem) =>{
    let b = [...branchess];
                let indexToRemove = b.indexOf(selectedItem.id);
    
    if (indexToRemove !== -1) {
      b.splice(indexToRemove, 1);
    }
    setBranchess(b)
    
                          }
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 768){
        setSidebarOpen(false)
      }
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const downloadTemplate = () => {
    // Function to download the template
};
const fetchAvailability= async (signal)=>{
  const {status, data, msg} = await fetchData(`/productbranches/${id}`);
if(status){
  console.log(data)
  setBranchesav(data)
}else{
  setShowalert(true)
  setMsg(msg)

}
  }
const fetchProduct = async (productid) => {
    setModalLoading(true);
    const {status, data, msg} = await sendData('/getproduct', JSON.stringify({  productid: id}));
    if(status){
  console.log({"savio":data})
  setProduct(data)
  setProdInfo(data)
   setModalData(data);
   setModalImgPrev(data.img)
   setModalLoading(false);

   setFormat(data.formatt)
   setName(data.name)
   setBrand(data.brand!=null?data.brand.name:'')
   setSku(data.sku)
   setDes(data.dess)
   setCat(data.catid)
   setPrice(data.prixbulk1)
   setImg(data.img)
   if(data.img2!=null){
    extraimg.push(data.img2)
    imgs.push(data.img2)
   }
   if(data.img3!=null){
    extraimg.push(data.img3)
    imgs.push(data.img3)
   }
   if(data.img4!=null){
    extraimg.push(data.img4)
    imgs.push(data.img4)
   }
   if(data.img5!=null){
    extraimg.push(data.img5)
    imgs.push(data.img5)
   }
   setImg2(data.img2!=null?data.img2:'')
   setImg3(data.img3!=null?data.img3:'')
   setImg4(data.img4!=null?data.img4:'')
   setImg5(data.img5!=null?data.img5:'')
   setClose(true)

    }else{

      setShowalert(true)
      setMsg(msg)
    }
  }
const fetchVariants = async (productid) => {

  const {status, data, msg} = await sendData('/getproductvariants', JSON.stringify({  productid: id}));
  if(status){
console.log({data})
 setVariants(data);
 setLoading(false);
  }else{

    setShowalert(true)
    setMsg(msg)
  }
}
useEffect(()=>{

  if(id){
   
    fetchProduct()
  }
},[])
useEffect(()=>{
},[branchess])
const handleImages = (e) => {
  const selectedImage = e.target.files[0];

  if (selectedImage && extraimg.length < 5) {
      setimgs((prevImages) => [...prevImages, selectedImage]);
  }
};
const UpdateAvailibility = async () => {
  console.log(JSON.stringify({prodid: product.id,branches:branchesav}))
  const {status, data, msg} = await sendData('/productbranches/update', JSON.stringify({prodid: product.id,branches:branchesav}));
  if(status){
console.log({data})
setClose(false)
setClose1(false)
setSameav(false)
//  setVariants(data);
//  setLoading(false);
  }else{

    setShowalert(true)
    setMsg(msg)
  }
}
const handleSelectAll = (selectedStatus) => {
  // Update the status for all branches
  const updatedBranches = branchesav.map((item) => ({ ...item, status: selectedStatus }));
  setBranchesav(updatedBranches);
};
const handleSubmit = async() => {
  setLoadermodal(true)
//setProcessloader1(true)


    try {

       let userid =localStorage.getItem('userid')
       let storid =localStorage.getItem('storeid')
      const formData = new FormData();
      formData.append('storid', storid);
      formData.append('userid', userid);
      formData.append('file', excel);
 console.log({formData})
    const req = await fetch("https://api.trakitrak.com/merchant/excel/importproducts", {
      method:'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //'Content-Type': 'multipart/form-data'
 
      },
      body: formData,
})
if(req.status==200){
  const json = await req.json()
  console.log(json)
setShow(false)
setProcessmodal(false)
setprocessapproved(true)
setexcel(null)
setModalInput(null)
setLoadermodal(false)

  // navigate('/stores/added', {replace: true});

}else{
  const json = await req.json()
  console.log(json) 
  setShow(false)
  setexcel(null)
  setProcessmodal(false)

  setModalInput(null)
  setLoadermodal(false)
  // setShowalert(true)
  // setMsg(json.message)
  // setLoader(false)
}
    } catch (error) {
      setexcel(null)
      setModalInput(null)
      setShow(false)
      setLoadermodal(false)
      setProcessmodal(false)
    }
 
  
};
const HandlePreImport = async() => {
  // setProcessloader(true)
  setLoadermodal(true)
    try {
       let userid =localStorage.getItem('userid')
       let storid =localStorage.getItem('storeid')
      const formData = new FormData();
      formData.append('storid', storid);
      formData.append('userid', userid);
      formData.append('file', excel);
      console.log({formData})
    const req = await fetch("https://api.trakitrak.com/merchant/excel/preimportproducts", {
      method:'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //'Content-Type': 'multipart/form-data'
 
      },
      body: formData,
})
if(req.status==200){
  const json = await req.json()
    console.log(json)


    setEmptyexcel(json.isEmpty)
    setPreImportError(json.errors)
    setTotalentries(Number(json.valid)+Number(json.invalid))
    setValidentries(json.valid)
    setInvalidentries(json.invalid)
    setLoadermodal(false)
    setLoadermodal(false)
    setProcessmodal(true)
    setProcessloader(false)

}else{
  const json = await req.json()
  console.log(json) 

  setLoadermodal(false)
  setexcel(null)
  setModalInput(null)
  setProcessloader(false)
  setShowalert(true)
  setMsg('Invalid Excel')
  setShow(false)
  // setLoader(false)
}
    } catch (error) {
      setShow(false)
      setLoadermodal(false)
      setexcel(null)
      setModalInput(null)
      setProcessloader(false)
    }
 
  
};
const HandlePreBulk= async() => {
  // setProcessloader(true)
  setLoadermodal(true)
    try {
       let userid =localStorage.getItem('userid')
       let storid =localStorage.getItem('storeid')
      const formData = new FormData();
      formData.append('storid', storid);
      formData.append('userid', userid);
      formData.append('file', excel2);
      console.log({formData})
    const req = await fetch("https://api.trakitrak.com/merchant/excel/prebulkupdate", {
      method:'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //'Content-Type': 'multipart/form-data'
 
      },
      body: formData,
})
if(req.status==200){
  const json = await req.json()
    console.log(json)


    setEmptyexcel(json.isEmpty)
    //setPreImportError(json.errors)
    setTotalentries(Number(json.valid)+Number(json.invalid))
    setValidentries(json.valid)
    setInvalidentries(json.invalid)
    setLoadermodal(false)
    setLoadermodal(false)
    setBulkUpdate(true)
    setProcessloader(false)

}else{
  const json = await req.json()
  console.log(json) 

  setLoadermodal(false)
  setexcel(null)
  setModalInput(null)
  setProcessloader(false)
  setShowalert(true)
  setMsg('Invalid Excel')
  setShow(false)
  // setLoader(false)
}
    } catch (error) {
      setShow(false)
      setLoadermodal(false)
      setexcel(null)
      setModalInput(null)
      setProcessloader(false)
    }
 
  
};
const handleUpdate = async() => {
  setLoadermodal(true)
//setProcessloader1(true)


    try {

       let userid =localStorage.getItem('userid')
       let storid =localStorage.getItem('storeid')
      const formData = new FormData();
      formData.append('storid', storid);
      formData.append('userid', userid);
      formData.append('file', excel2);
 console.log({formData})
    const req = await fetch("https://api.trakitrak.com/merchant/excel/bulkupdate", {
      method:'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //'Content-Type': 'multipart/form-data'
 
      },
      body: formData,
})
if(req.status==200){
  const json = await req.json()
  console.log(json)
setShow(false)
setBulkUpdate(false)
setProcessmodal(false)
setprocessapproved(true)
setexcel(null)
setModalInput(null)
setLoadermodal(false)

  // navigate('/stores/added', {replace: true});

}else{
  const json = await req.json()
  console.log(json) 
  setShow(false)
  setexcel2(null)
  setProcessmodal(false)
  setBulkUpdate(false)

  setModalInput2(null)
  setLoadermodal(false)
  // setShowalert(true)
  // setMsg(json.message)
  // setLoader(false)
}
    } catch (error) {
      setexcel2(null)
      setModalInput2(null)
      setBulkUpdate(false)
      setShow(false)
      setLoadermodal(false)
      setProcessmodal(false)
    }
 
  
};
const exportProd = async() => {
 setExportmdodal(true)
  
      try {
  
         let userid =localStorage.getItem('userid')
         let storid =localStorage.getItem('storeid')
    console.log(JSON.stringify({
      storid:storid,
      userid:userid,
      branchids:branchess
  }))
  
      const req = await fetch("https://api.trakitrak.com/merchant/excel/export", {
        method:'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
        body: JSON.stringify({
            storid:storid,
            userid:userid,
            branchids:branchess
        }),
  })
  console.log(req.status)
  if(req.status==200){
    const blob = await req.blob()
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = `TrakiTrak - ${localStorage.getItem('storename')}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setShow2(false)
    setExportmdodal(false)
  

  
    // navigate('/stores/added', {replace: true});
  
  }else{
    const json = await req.json()
    console.log(json) 
    setExportmdodal(false)
    setShow2(false)
    // setShowalert(true)
    // setMsg(json.message)
    // setLoader(false)
  }
      } catch (error) {
        setShow2(false)

        setExportmdodal(false)
  }
 
 
  
};
const handleStatusChange = (newStatus) => {
  setStatus(newStatus);
  setOpen(false)
};
const handleMouseOver = (e) => {
  e.preventDefault();
};
const [open, setOpen] = useState(false);
const [open1, setOpen1] = useState(false);
const handleClosedrop1 = () => {
  setOpen1(false);
};
const handleClosedrop = () => {
  setOpen(false);
};
const selectAllBranches = () => {
  setBranchess(branches);
};
  return (
    <div>
{/* Show products modals */}
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={modal} onHide={handleClosemodal}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          
            <h5 style={{margin:0,marginLeft:10}}>{product?.brand ? product?.brand.name + '・' : ''}{product?.name}</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button onClick={()=>{
                                      setClose(false)
                                      setExtraimg([])
                                    }} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn">Close</button>
                                      </div>
            </div>
         <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginBottom:'1%',marginTop:'1%'}}/>
            <a onClick={()=>{
              fetchAvailability()
              setClose1(true)
                      setExtraimg([])
            setClose(false)}}  className="modal-button ms-2">Availability</a>
                     
            <a  onClick={()=>{setClose2(true)}} className="modal-button ms-2">Edit Product</a>
            <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:'1%'}}/>

        </div>

      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'90%',marginBottom:'5%'}}>
        {/* first div */}
        <div style={{width:'40%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Product</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <img src={prodinfo?prodinfo.img:''} className='modal2' />
                <button className="mt-3 modal-button modal-buttonx" style={{ width: '95%',marginBottom:10 }}>
                  Delete product {modalData && modalData.prodid ? 'variant' : ''}
                </button>
             
                
              
            </div>
        </div>
        {/* second div */}
        <div style={{width:'60%',height:'80vh', overflowY: 'auto',paddingBottom:100}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>GENERAL INFORMATION</p>
        <div style={{width:'98%',paddingBottom:15,marginLeft:'1%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.name}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
                Name
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.brand ? modalData.brand.name : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Brand
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.barcode ? modalData.barcode : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Barcode
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            {modalData?.is_variant==0 && !modalData.prodid ? (
              <>
         
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.sku ? modalData.sku : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              SKU
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.format ? modalData.format : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Format
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.dess ? modalData.dess : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Description
              </p>
            </div>
            </>
            ):null}
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.prodcat ? modalData.prodcat.name : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Category
              </p>
            </div>
            {modalData?.is_variant && modalData.prodid ? (
          <>
            <p  style={{ color: 'gray',marginLeft:5, fontSize: '15px' }}>
              VARIANT INFORMATION
            </p>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.variant ? modalData.variant : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Variant
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.size ? modalData.size : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Size
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.sku ? modalData.sku : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              SKU
              </p>
            </div>
            <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
            <div style={{height:50,width:'95%',marginLeft:'2%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
             <p style={{margin:0,color: 'black', fontSize: '16px'}}>{modalData && modalData.dess ? modalData.dess : '---'}</p>
              <p  style={{ color: 'gray', fontSize: '13px' }}>
              Description
              </p>
            </div>
          </>
        ) : null}
     



          

            
          
        </div>
      
          
        </div>
    </div>


</div>


</Modal>
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={modal1} onHide={handleClosemodal1}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>Availability</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button onClick={()=>{setClose(false)
                                      setClose1(false)}} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn">Close</button>
                                    <button onClick={()=>{
                                      UpdateAvailibility()
                                      }} data-bs-dismiss="modal" style={{marginLeft:5}} className="btn btn-outline-primary custom-btn">Save</button>
                                      </div>
            </div>
         <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginBottom:'1%',marginTop:'1%'}}/>
            <a onClick={()=>{setClose1(false)
            setClose(true)}}  className="modal-button ms-2">Edit general information</a>
            <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1',marginTop:'1%'}}/>

        </div>

      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'90%',marginBottom:'5%'}}>
        {/* first div */}
        <div style={{width:'40%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Product</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={prodinfo?.img} className='modal2' />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:40,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.name}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>Name</p>
                </div>
                {prodinfo?.brand?.name?
                <>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:40,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.brand?.name}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>Brand</p>
                </div>
                </>:
                null}
                
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',height:40,justifyContent:'center',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>{prodinfo?.sku}</p>
                 <p style={{marginLeft:'2%',color:'gray'}}>SKU</p>
                </div>
            </div>
        </div>
        {/* second div */}
        <div style={{width:'60%',height:'80vh', overflowY: 'auto',paddingBottom:branchesav.length*30}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>All Branches</p>
            <div style={{width:'98%',marginLeft:'1%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
           
            <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,alignItems:'center',justifyContent:'space-between'}}>
              <p style={{marginLeft:'5%'}}>Same availability</p>
                    <Form>
                <Form.Check 
                value={sameav}
                onChange={(e)=>setSameav(e.target.checked)}
                  type="switch"
                  id="custom-switch"
          
                />
             
              </Form>
            </div>
            {sameav&&(  
              <select  value={branchesav.length > 0 ? branchesav[0].status : ''}  onChange={(e) => handleSelectAll(e.target.value)} style={{border:'none',marginBottom:'1.3%',width:'93%'}} >
                <option value="AVAILABLE">Available</option>
                <option value="UNAVAILABLE">Unavailable</option>
                <option value="OUT_OF_STOCK">Out of stock</option>
                <option value="OUT_OF_SEASON">Out of season</option>
              </select>)}
            </div>
          <div style={{paddingBottom:branchesav.length*10}}>
          {branchesav.map((item,i)=>(
              <div style={{marginTop:'5%'}}>
          <div style={{display:'flex',marginLeft:'1%',flexDirection:'row',width:'100%',alignItems:'center'}}>
            <p style={{fontWeight:'bold',color:'black'}}>{item.branch.name}</p>
            <p style={{fontWeight:'bold',color:'gray',marginLeft:'2%',fontSize:12}}>{item.branch.conf2==1?'Active':'Inactive'}</p>
            </div>      
        <div style={{width:'98%',marginLeft:'1%',height:80, boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <div style={{marginLeft:'2%',height:40,display:'flex',alignItems:'center'}}>
            <p >Availability</p>
              </div> 
              <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>  
              <div style={{marginLeft:'2%',height:40,width:'100%',display:'flex',alignItems:'center'}}>
  
           <select value={item.status}
           onChange={(e) => {
            const selectedIndex = i;
            const selectedStatus = e.target.value;
            // Update the item.status for the selected index
            const updatedBranches = [...branchesav];
            updatedBranches[selectedIndex] = { ...updatedBranches[selectedIndex], status: selectedStatus };
            setBranchesav(updatedBranches)
            // Call a function to handle the state update (e.g., setBranches(updatedBranches))
            // setBranches(updatedBranches);
          }}
           style={{border:'none',width:'96%'}} >
                <option value="AVAILABLE">Available</option>
                <option value="UNAVAILABLE">Unavailable</option>
                <option value="OUT_OF_STOCK">Out of stock</option>
                <option value="OUT_OF_SEASON">Out of season</option>
              </select>
              </div>
              </div>
              
              </div>
            ))}
          </div>
        </div>
    </div>


</div>


</Modal>
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={modal2} onHide={handleClosemodal2}>
      <div className="modal-content" style={{ minHeight: '83vh',position:'relative',maxHeight:'80vh', overflowY: 'clip',backgroundColor:'#fafbff',alignItems:'center' }}>
   
        <div style={{width:'100%',height:'auto',backgroundColor:'white'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <h5 style={{margin:0,marginLeft:10}}>Edit Product</h5>
            <div style={{marginRight:25,marginTop:5}} >
                                    <button onClick={()=>{setClose2(false)}} data-bs-dismiss="modal" className="btn btn-outline-primary custom-btn"
>Close</button>
                                    <button onClick={()=>{setClose2(false)}} data-bs-dismiss="modal" style={{marginLeft:5}} className="btn btn-outline-primary custom-btn"
>Save</button>
                                      </div>
            </div>
       

        </div>

      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'95%',marginBottom:'5%'}}>
       
        {/* second div */}
        <div style={{width:'70%',height:'80vh', overflowY: 'auto',paddingBottom:100}}>
        <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Information</p>
          
        <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',}}>
          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Name</p>
            <input value={name} onChange={(e)=>setName(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
           <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Brand</p>
           <select  style={{border:'none',width:'97%'}}>
            <option value="Waiting">Scheduled</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Progress">In Progress</option>
          </select>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>category (required)</p>
            <select  style={{border:'none',width:'97%'}}>
            <option value="Waiting">Scheduled</option>
            <option value="Delivered">Delivered</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Progress">In Progress</option>
          </select>
          </div>
          
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>SKU (required)</p>
            <input value={sku} onChange={(e)=>setSku(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Format (required)</p>
            <input value={format} onChange={(e)=>setFormat(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>
          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>

          <div style={{height:55,justifyContent:'center',borderRadius:10,marginLeft:'2%',marginTop:'1%',display:'flex',flexDirection:'column'}}>
            <p style={{fontWeight:'900',fontSize:'18px',color:'gray'}}>Description</p>
            <input value={des} onChange={(e)=>setDes(e.target.value)} style={{border:'none',width:'95%',}}/>
          </div>

          <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
          <div id="main-img-div" className="rounded border p-2">
            <div className="border-bottom pb-2">
                <label htmlFor="exterior" className="w-100 label-input">
                    <input required accept="image/*" style={{ display: 'none' }} type="file" name="exterior" id="exterior"  />
                    <div className="d-flex align-items-center">
                        <div>
                            <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                                <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                    <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                                {img!=null?<img className="img-uploaded" src={img}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null}

                            </div>
                        </div>

                        <div className="d-flex flex-column justify-content-center ms-2">
                            <p>Image1</p>
                            <span style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
                        </div>
                    </div>
                </label>
            </div>

            {extraimg.map((item,i)=>(
                            <div 
                   
                            className="border-bottom pb-2">
                <label className="w-100 label-input"  style={{marginTop:'1%'}}>
            <input accept="image/*" style={{ display: 'none' }} type="file" onChange={handleImages}   />
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div>
                        <div className="d-flex p-2 rounded" style={{ border: '0.125rem dashed black', height: '37px', width: '37px' }}>
                            <svg className="img-preview" style={{ fill: 'black' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                            </svg>
                            <img className="img-uploaded" src={i==0?img2:i==1?img3:i==2?img4:i==3?img5:''}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 

                            {/* {imgs[i]?<img className="img-uploaded" src={URL.createObjectURL(imgs[i])}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null} */}

                            {/* {extraimg[i]?<img className="img-uploaded" src={extraimg[i]}  style={{  width: '100%', height: '100%', objectFit: 'contain' }} /> 
:null} */}
                        </div>
                    </div>

                    <div className="d-flex flex-column justify-content-center ms-2">
                    <p>Image{i+2}</p>
                        <span style={{ color: 'gray', fontSize: '13px' }}>Choose...</span>
                    </div>
                </div>

                <div  onClick={(e)=>{
                    if (extraimg.length > 0) {
                        e.preventDefault()
                       
                        // let images = imgs.splice(i, 1);
                        // console.log({images})
                        // console.log({imgs})
                        // setimgs((prevImages) => prevImages.filter((_, i) => i !== i));
                        setExtraimg(prevImages => prevImages.filter((_, index) => index !== i));
                    }
                }}  className='close-img'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 384 512">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg>
                </div>
            </div>
        </label>
        </div>
            ))}


            <div onClick={()=>{
           if (extraimg.length < 4) {
            setExtraimg(prevImages => [...prevImages, null]);
        }
                }} id="add-img" className="p-2 mt-2 justify-content-between align-items-center" style={{ display: 'flex',cursor:'pointer' }}>
                <div className="d-flex align-items-center">
                    <svg height="1.3em" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnssketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <title>plus-circle</title>
                            <desc>Created with Sketch Beta.</desc>
                            <defs></defs>
                            <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd" sketchtype="MSPage">
                                <g id="Icon-Set-Filled" sketchtype="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#19cc1c">
                                    <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketchtype="MSShapeGroup">
                                    </path>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <p className="ms-2">Add another image</p>
                </div>
                <svg height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path d="M10 7L15 12L10 17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                </svg>
            </div>
        </div>
        {/*  product unit measure */}
        <div style={{display:'flex',alignSelf:'center'}} className="unitmeasuresmaindiv1 mt-4" id="unitmeasuremaindiv">
                <div className="measurediv1 unitdiv">
                    <div className="measure-first">
                    <input
                        id="unit-check"
                        style={{ marginLeft: '10px' }}
                        value="unit"
                        type="radio"
                        name="unitmeasure"
                    />
                    <div className="measure-text">
                        <p>By unit</p>
                        <p>Example: soda can</p>
                    </div>
                    </div>
                    <div className="measure-second">
                    <img src={'	https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/pepsi.png'} alt="soda-can" style={{ width: '100%', top: '10px' }} />
                    </div>
                </div>

                <div style={{ flex: '0.05' }}></div>

                <div className="measurediv1 bulkdiv">
                    <div className="measure-first">
                    <input
                        id="weight-check"
                        type="radio"
                        name="unitmeasure"
                        value="weight"
                        style={{ marginLeft: '10px' }}
                    />
                    <div className="measure-text" style={{ color: 'black' }}>
                        <p>By Weight</p>
                        <p>Example: Lemon</p>
                    </div>
                    </div>
                    <div className="measure-second">
                    <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/lemon.png'} alt="lemon" style={{ width: '100%', top: '25px' }} />
                    </div>
                </div>
                </div>
                {/* end product unit measure */}
                <div style={{display:'flex',alignSelf:'center',justifyContent:'center',alignItems:'center',width:'100%'}}  id="unitmeasuremaindiv">
          <div class="weightunitdiv1" id="weightunitdiv">
                    <div class="measure-first">
                        <input id="weightunit-check" style={{marginLeft:10}} type="radio" name="unitmeasure"
                            value="both"/>
                        <div class="measure-text">
                            <p>By unit and weight</p>
                            <p>Example: pieces of meat</p>
                        </div>
                    </div>
                    <div class="measure-second">
                        <img src={'https://trakitrak-prod.s3.eu-west-1.amazonaws.com/quotes/meat.png'} alt="meat" style={{top:-5,height:'100%'}}/>
                    </div>
                </div>
          </div>
        {/* product tags */}
        <div className="row ms-0 me-0" >
        <div className="col-sm-6">
          <div className="tag-container rounded mt-4 bg-primary d-flex align-items-center px-3" style={{ height: '100px' }}>
            <input value="Frozen" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Frozen</h6>
              <p>Example: Ice</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="bg-danger mt-4 d-flex align-items-center px-3 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(121 55 89 / 80%) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input value="Alcohol" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Alcohol</h6>
              <p>Example: Beer</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="d-flex align-items-center px-3 mt-4 tag-container rounded" style={{ height: '100px', background: 'linear-gradient(to right, rgb(48 206 62) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input value="Fragile" name="tags[]" type="checkbox" />
            <div className="ms-3">
              <h6 className="m-0">Fragile</h6>
              <p>Example: Cake</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="tag-container rounded d-flex align-items-center px-3 mt-4" style={{ height: '100px', background: 'linear-gradient(to right, rgb(206 48 160) 0%, rgba(172, 227, 255, 0.8) 100%)' }}>
            <input value="Tobacco" name="tags[]" type="checkbox" />
            <div className="ms-3 text-white">
              <h6 className="m-0">Tobacco</h6>
              <p>Example: Cigarette</p>
            </div>
          </div>
        </div>
      </div>
      {/* end product tags */}
      <label style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%'}} className="form-label mt-4 w-98">Featured</label>
            <select style={{display:'flex',flexDirection:'column',alignSelf:'center',width:'95%',marginBottom:'5%'}} className="form-select" name="highlight" id="status">
                <option value="1" selected>Yes</option>
                <option value="0">No</option>
            </select>
          </div>
        </div>
         {/* first div */}
         <div style={{width:'45%',maxHeight:'auto',display:'flex',flexDirection:'column',}}>
            <p style={{marginTop:'3%',fontSize:16,color:'black',fontWeight:'700'}}>Preview</p>
            <div style={{width:'95%', boxShadow: `0 1px 5px 2px rgba(0,0,0,0.13)`,backgroundColor:'white',marginTop:'2%',borderRadius:10,borderWidth:1,borderColor:'gray',display:'flex',flexDirection:'column',alignItems:'center'}}>
                <img src={prodinfo?.img} className='modal2' />
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Name</p>
                <p style={{marginLeft:'2%',color:'gray',overflow:'hidden'}}>{name?name:'Product name'}</p>
                </div>
                <div style={{width:'100%',height:0.8,backgroundColor:'#d1d1d1'}}/>
                <div style={{width:'95%',display:'flex',flexDirection:'row',alignItems:'flex-start',height:35,justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                <p style={{marginLeft:'2%',color:'black'}}>Formart</p>
                <p style={{marginLeft:'2%',color:'gray'}}>{format?format:'Product format'}</p>
                </div>
            </div>
        </div>
    </div>


</div>


</Modal>
{/* Show products modals */}




 <Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal1"  // Custom class for centering horizontally
     show={show} onHide={handleClose}>
    
    <div className="modal-content" style={{minHeight:'75vh',backgroundColor:'#fafbff'}}>
                        <div className="modal-header" style={{ display: "block" }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Upload spreadsheet</h5>
                                <div className="d-flex align-items-center">
                                    <div>
                                      <button onClick={()=>{setShow(false)
                                      setexcel(null)
                                      setModalInput(null)
                                      if (fileInputRef.current) {
                                        fileInputRef.current.value = null;
                                    }
                                      }} type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button disabled={processloader&&excel?true:excel&&!processloader?false:true} onClick={HandlePreImport} type="submit" className="btn buttonx-outline">Upload</button></div>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-body" + (modalLoading2 ? ' d-flex justify-content-center align-items-center' : '')} style={{ backgroundColor: "#fafbff",width:'90%',marginLeft:'5%' }}>
                        {processloader?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'28%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div> : (
                                <div>
                                    <input type="hidden" name="_token" value="{{csrf_token()}}" />
                                    <input type="hidden" name="storeid" value="{{$storeid}}" />
                                    <div className="d-flex">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">1</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Download the spreadsheet template and fill in the required information.</p>
                                    </div>

                                    <div className="d-flex justify-content-center mt-1"  >
                                        <a href={`https://api.trakitrak.com/merchant/excel/download/${localStorage.getItem('storename')}?a=123456789`} download className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "red", cursor: "pointer",marginTop:50 }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            <p className="text-white ms-1" style={{ fontSize: "14px" }}>Download template</p>
                                        </a>
                                    </div>

                                    <div className="d-flex mt-4">
                                        <div className="d-flex justify-content-center" >
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">2</p>
                                            </div>
                                        </div>
                                        <p style={{ margin:0,marginLeft:10,marginTop:3 }}>Upload your spreadsheet here</p>
                                    </div>

                                    <div className="d-flex flex-column justify-content-center mt-2 py-4" style={{ backgroundColor: "white",height:'30vh' ,borderRadius:10,marginBottom:10 }}>

                                        {modalInput && (
                                          <div style={{display:'flex',justifyContent:'space-between',height:70,alignItems:'center',borderBottom:'1px solid red'}}>
                                       
                                            <div style={{marginLeft:15,}} className="d-flex  align-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                                </svg>
                                                <p style={{fontSize:17,fontWeight:'bold'}}>{modalInput}</p>
                                            </div>

                                            <div onClick={()=>{
                                              setModalInput(null)
                                              setexcel(null)
                                              if (fileInputRef.current) {
                                                fileInputRef.current.value = null;
                                            }
                                            }} style={{marginRight:34,cursor:'pointer'}}>
<svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <line x1="18" y1="6" x2="6" y2="18" />
  <line x1="6" y1="6" x2="18" y2="18" />
</svg>
                                            </div>
                                    
                                            </div>
                                        )}

                                        <label htmlFor="file" style={{display:'flex',justifyContent:modalInput?'flex-end':'center',width:'100%',height:50,alignItems:'center' }}>
                                            
                                            <input
                                            ref={fileInputRef}
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                   console.log({excel})
                                                   setexcel(e.target.files[0])
                                                   console.log({excel})
                                                if (file) {
                                                  console.log({file})
                                             
                                                    setModalInput(file.name)
                                                } else setModalInput(null);
                                            }} id="file" name="file" type="file" accept=".xlsx" style={{ display: "none" }} />
                                         
                                            <div className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "#f1f1f1",width:120,height:40, cursor: "pointer" }}>
                                                <p  style={{ fontSize: "14px",color:'black',fontWeight:'bolder', }}>{excel?'Replace file':'Select a file'}</p>
                                            </div>
                                        </label>

                                    </div>
                                    <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8568477-adding-single-products-in-bulk' style={{textAlign:'start',fontSize:13.7,marginTop:10,color:'#007aff'}}>Need help importing products?</a>

                                </div>
                            )}
                        </div>
                    </div>
        
            
       

</Modal>

<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal" // Custom class for centering horizontally
     show={show1} onHide={handleClose1}>
    
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: "block" }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Inventory</h5>
                                <div className="d-flex align-items-center">
                                    <div><button onClick={()=>setShow1(false)} type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                </div>
                            </div>
                        </div>
                        <div className={"modal-body" + (modalLoading2 ? ' d-flex justify-content-center align-items-center' : '')} style={{ backgroundColor: "white" }}>
                            {modalLoading2 ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <div>
                                    <input type="hidden" name="_token" value="{{csrf_token()}}" />
                                    <input type="hidden" name="storeid" value="{{$storeid}}" />
                                    <div className="d-flex">
                                        <div className="d-flex justify-content-center" style={{ flex: "0.1" }}>
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">1</p>
                                            </div>
                                        </div>
                                        <a onClick={()=>
                                        {setShow2(true)
                                        setShow1(false)
                                        }
                                        }  data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-primary" style={{ fontSize: '13px' }}>
                                            Export
                                          </a>
                                    </div>

                                    

                                    <div className="d-flex mt-4">
                                        <div className="d-flex justify-content-center" style={{ flex: "0.1" }}>
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "25px", width: "25px", borderRadius: "50%", backgroundColor: "#007aff", position: "relative", top: "3px" }}>
                                                <p className="text-white p-0 m-0">2</p>
                                            </div>
                                        </div>
                                        <a   onClick={()=>
                                        {setShow3(true)
                                        setShow1(false)
                                        }
                                        }  data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-primary" style={{ fontSize: '13px' }}>
                                            Bulk Update
                                          </a>
                                    </div>

                                   
                                </div>
                            )}
                        </div>
                    </div>
        
            
       

</Modal>






{/* export modal */}
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal1"  // Custom class for centering horizontally
     show={show2} onHide={handleClose2}>
    
                    <div className="modal-content" style={{height:'80vh',backgroundColor:'#fafbff'}}>
                        <div className="modal-header" style={{ display: "block",backgroundColor:'white' }}>
                            <div  className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Export inventory</h5>
                                <div className="d-flex align-items-center">
                                    <div>
                                      <button onClick={()=>setShow2(false)} type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button disabled={branchess.length==0?true:false} onClick={exportProd} type="submit" className="btn buttonx-outline">Export</button></div>
                                </div>
                            </div>
                        </div>
                        {exportmdodal?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div>
                        :
                        <div className={"modal-body" + (modalLoading2 ? ' d-flex justify-content-center align-items-center' : '')} style={{ backgroundColor: "#fafbff" }}>
                        <div style={{marginTop:'1%',width:'90%',marginLeft:'5%'}}>
 <p style={{fontWeight:'bolder',color:'black',fontSize:18}}> Choose the template's format</p>
                            <p style={{color:'gray',fontSize:16}}>You can edit this file and then modify the price and availability of your selected branches' inventory via bulk update.
</p>
 </div>

       <div style={{marginTop:'3%',width:'90%',marginLeft:'5%',backgroundColor:'white',padding:'10px 10px 250px 10px',border:'1px solid #d1d1d1',borderRadius:5}}>
        {/* <div style={{display:'flex',alignItems:'center'}}>
        <input
          type="checkbox"
          // checked={isChecked}
           onChange={()=>{
            if(branchess){
              setBranchess([])
            }else{
              setBranchess(branches)
            }
        
          }}
        />
<p style={{marginLeft:10}}>Select all branches</p>   
     </div> */}
       <Multiselect
       style={{chips:{background:'gray'}}}
     showArrow={true}
      //  showCheckbox={true}
      hidePlaceholder={true}
     placeholder={'Select Branches'}
     options={branches} // Options to display in the dropdown
     //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
     onSelect={onSelect}
     onRemove={onRemove}
     displayValue="name" // Property name to display in the dropdown options
     />

       </div>
                      
                        </div>
                        }
                       
                    </div>
        
            
       

</Modal>
{/* export modal */}
{/* bulk update */}
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal1" // Custom class for centering horizontally
     show={show3} onHide={handleClose3}>
    
                    <div className="modal-content" style={{height:'80vh',backgroundColor:'#fafbff'}}>
                    <div className="modal-header" style={{ display: "block",backgroundColor:'white' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Bulk update</h5>
                                <div className="d-flex align-items-center">
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setShow3(false)
                                        setModalInput2(null)
                                        setexcel2(null)
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button disabled={excel2?false:true} onClick={HandlePreBulk} type="submit" className="btn buttonx-outline">Upload</button></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column' }}>
                            {modalLoading2 ? (
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <div >
                                  
                                    

                                    <div className="d-flex mt-4">
                                        
                                        <p >Upload your updated inventory</p>
                                    </div>



                                  <div className="d-flex flex-column justify-content-center mt-2 py-4" style={{ backgroundColor:modalInput1?'white': "#dcdcdf",border:modalInput1?'1px solid #d1d1d1':'0px solid #d1d1d1',height:99 ,borderRadius:10,marginBottom:13 }}>

                                  {modalInput2 && (
                                    <div style={{display:'flex',justifyContent:'space-between',height:70,alignItems:'center',borderBottom:'1px solid red',marginBottom:10}}>

                                      <div style={{marginLeft:15,}} className="d-flex  align-items-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                              <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                          </svg>
                                          <p style={{fontSize:17,fontWeight:'bold'}}>{modalInput2}</p>
                                      </div>

                                      <div onClick={()=>{
                                        setModalInput2(null)
                                        setexcel2(null)
                                      }} style={{marginRight:34,cursor:'pointer'}}>
                                  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                  <line x1="18" y1="6" x2="6" y2="18" />
                                  <line x1="6" y1="6" x2="18" y2="18" />
                                  </svg>
                                      </div>

                                      </div>
                                  )}

                                  <label htmlFor="file" style={{display:'flex',justifyContent:modalInput2?'flex-end':'center',width:'100%',height:50,alignItems:'center' }}>
                                      <input onChange={(e) => {
                                          const file = e.target.files[0];
                                            console.log({excel2})
                                            setexcel2(e.target.files[0])
                                            console.log({excel2})
                                          if (file) {
                                            console.log({file})
                                      
                                              setModalInput2(file.name)
                                          } else setModalInput2(null);
                                      }} id="file" name="file" type="file" accept=".xlsx" style={{ display: "none" }} />
                                  
                                      <div className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "#888b90",padding:'7px 16px 7px 16px', cursor: "pointer" }}>
                                          <p  style={{ fontSize: "14px",color:'white',fontWeight:'bolder', }}>{excel1?'Replace file':'Select a file'}</p>
                                      </div>
                                  </label>

                                  </div>
                                  <p>Upload file with product price and availability updates. <a target="_blank" href='https://help-stores.trakitrak.com/en/articles/8568477-adding-single-products-in-bulk' style={{textAlign:'start',fontSize:13.7,marginTop:10,color:'#007aff'}}>Learn more.</a></p>
                                   


                                    {/* <div className="d-flex flex-column justify-content-center mt-2 py-4" style={{ backgroundColor: "#f5f5f5",height:'35vh' }}>

                                        {modalInput1 && (
                                            <div className="d-flex justify-content-center align-items-center mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                                </svg>
                                                <p>{modalInput1}</p>
                                            </div>
                                        )}

                                        <label htmlFor="file" style={{ alignSelf: "center", width: "30%", }}>
                                            <input onChange={(e) => {
                                                const file = e.target.files[0];
                                                   console.log({excel})
                                                   setexcel1(e.target.files[0])
                                                   console.log({excel})
                                                if (file) {
                                                  console.log({file})
                                             
                                                    setModalInput1(file.name)
                                                } else setModalInput1(null);
                                            }} id="file" name="file" type="file" accept=".xlsx" style={{ display: "none" }} />
                                            <div className="d-flex justify-content-center align-items-center px-2 py-1 rounded" style={{ backgroundColor: "#888b90", cursor: "pointer" }}>
                                                <p className="text-white ms-1" style={{ fontSize: "14px" }}> {excel1?'Replace file':'Select a file'}</p>
                                            
                                            </div>
                                        </label>

                                    </div> */}

                                </div>
                            )}
                        </div>
                    </div>
        
            
       

</Modal>
{/* bulk update */}
{/* bulk Process */}
<Modal   
centered 
scrollable={true}  // This centers the modal vertically
 dialogClassName="custom-modal1" 
 // Custom class for centering horizontally
     show={processmodal}
     onHide={handleProcess}>
    
                    {/* <div className="modal-content" style={{backgroundColor:'#fafbff'}}>
                    <div className="modal-header" style={{ display: "block",backgroundColor:'white' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>{emptyexcel||preimporterror.length>0?'Bulk upload process failed':'Bulk upload process completed'}</h5>
                                <div className="d-flex align-items-center">
                                {emptyexcel||preimporterror.length>0?
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setProcessmodal(false)
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>:null}
                                    <div className="ms-2">
                                      <button  type="submit" className="btn buttonx-outline">
                                      {emptyexcel||preimporterror.length>0?'Upload another template':'Ready'}</button></div>
                                </div>
                            </div>
                        </div>
                        <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                                <div >
                                    <div className="d-flex mt-4">
                                        <p>Bulk upload process summary</p>
                                    </div>
                                  <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Total entries</p>
                                        <p>{totalentries}</p>
                                      </div>
                                      <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Valid entries</p>
                                        <p>{validentries}</p>
                                      </div>
                                      <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                      <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                        <p>Invalid entries</p>
                                        <p>{invalidentries}</p>
                                      </div>
                                  </div> 
                               

                                  {emptyexcel||preimporterror.length>0?<p style={{marginTop:10}}>Erros:</p>:null}
                                  {emptyexcel?
                                  <ul>
                                    <li>You have uploaded an empty excel</li>
                                  </ul>
                                  :
                                  preimporterror.length>0?
                                  <table className="table" >
                                  <thead>
                                    <tr style={{height:64}}>
                                      <th scope="col">Row</th>
                                      <th scope="col">Column</th>
                                      <th scope="col">Error Type</th>
                                    </tr>
                                  </thead>
                                  <tbody >
                                 {preimporterror.map((err,i) => {
        
                                               return(
                                                <tr key={i}    >
                                                 <td>{err.row}</td>
                                                 <td>{err.column}</td>
                                                 <td>{err.reason}</td>
                                              </tr>
                                               )
                                     
                                })}
                                  </tbody>
                                </table>
                                :null
                                  }

                                </div>
                           
                        </div>
                    </div>    */}
        <Modal.Header>
        <div className="modal-header" style={{ display: "block",backgroundColor:'white', width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>{emptyexcel||preimporterror.length>0?'Bulk upload process failed':'Confirm bulk process'}</h5>
                                <div className="d-flex align-items-center">
                              
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setShow(false)
                                        setexcel(null)
                                        setModalInput(null)
                                        setProcessmodal(false)
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = null;
                                      }
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button onClick={()=>{
                                        if(emptyexcel||preimporterror.length>0){
                                              setProcessmodal(false)
                                         
                                              setexcel(null)
                                              setModalInput(null)
                                              if (fileInputRef.current) {
                                                fileInputRef.current.value = null;
                                            }

                                        }else{
                                          handleSubmit()
                                        }
                                      }}  type="submit" className="btn buttonx-outline">
                                      {emptyexcel||preimporterror.length>0?'Upload another template':'Confirm'}</button></div>
                                </div>
                            </div>
                        </div>
      </Modal.Header>
 
      <Modal.Body style={{backgroundColor: "#fafbff",height:'70vh'}}>
      {processloader1?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'10%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div>:
      <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                          <div >
                              <div className="d-flex mt-4">
                                  <p>Bulk upload process summary</p>
                              </div>
                            <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Total entries</p>
                                  <p>{totalentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Valid entries</p>
                                  <p>{validentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Invalid entries</p>
                                  <p>{invalidentries}</p>
                                </div>
                            </div> 
                         

                            {emptyexcel||preimporterror.length>0?<p style={{marginTop:10}}>Erros:</p>:null}
                            {emptyexcel?
                            <ul>
                              <li>You have uploaded an empty excel</li>
                            </ul>
                            :
                            preimporterror.length>0?
                            <table className="table" >
                            <thead>
                              <tr style={{height:64}}>
                                <th scope="col">Row</th>
                                <th scope="col">Column</th>
                                <th scope="col">Error Type</th>
                              </tr>
                            </thead>
                            <tbody >
                           {preimporterror.map((err,i) => {
  
                                         return(
                                          <tr key={i}    >
                                           <td>{err.row}</td>
                                           <td>{err.column}</td>
                                           <td>{err.reason}</td>
                                        </tr>
                                         )
                               
                          })}
                            </tbody>
                          </table>
                          :null
                            }

                          </div>
                     
                  </div>
}
      </Modal.Body>

</Modal>
{/* bulk Process */}

{/* bulk Process  approved*/}
<Modal   
centered 
scrollable={true}  // This centers the modal vertically
 dialogClassName="custom-modal1" 
 // Custom class for centering horizontally
     show={processapproved}
     onHide={handleProcessapproved}>
    
                  
        <Modal.Header>
        <div className="modal-header" style={{ display: "block",backgroundColor:'white', width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>Bulk upload process completed</h5>
                                <div className="d-flex align-items-center">
                              
                            
                                    <div className="ms-2">
                                      <button onClick={()=>{
                                        fetchprods()
                                        setprocessapproved(false)
                                      setShow3(false)

                                      }}  type="submit" className="btn buttonx-outline">
                                     Ready</button></div>
                                </div>
                            </div>
                        </div>
      </Modal.Header>
 
      <Modal.Body style={{backgroundColor: "#fafbff",height:'70vh'}}>
    
      <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                          <div >
                              <div className="d-flex mt-4">
                                  <p>Bulk upload process summary</p>
                              </div>
                            <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Total entries</p>
                                  <p>{totalentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Valid entries</p>
                                  <p>{validentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Invalid entries</p>
                                  <p>{invalidentries}</p>
                                </div>
                            </div> 
                         

                           

                          </div>
                     
                  </div>

      </Modal.Body>

</Modal>

{/* bulk Process approved */}


{/* bulk update Process */}
<Modal   
centered 
scrollable={true}  // This centers the modal vertically
 dialogClassName="custom-modal1" 
 // Custom class for centering horizontally
     show={bulkupdate}
     onHide={handlebulkupdate}>
    
               
        <Modal.Header>
        <div className="modal-header" style={{ display: "block",backgroundColor:'white', width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 style={{ fontFamily: "Poppins-SemiBold",fontSize:18 }}>{emptyexcel?'Bulk upload process failed':'Confirm bulk process'}</h5>
                                <div className="d-flex align-items-center">
                              
                                    <div>
                                      <button onClick={()=>
                                      {
                                        setShow(false)
                                        setexcel2(null)
                                        setModalInput2(null)
                                        setBulkUpdate(false)
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = null;
                                      }
                                      }
                                     
                                      } type="button" data-bs-dismiss="modal" className="btn btn-primary">Close</button></div>
                                    <div className="ms-2">
                                      <button onClick={()=>{
                                        if(emptyexcel){
                                              setBulkUpdate(false)
                                         
                                              setexcel2(null)
                                              setModalInput2(null)
                                              if (fileInputRef.current) {
                                                fileInputRef.current.value = null;
                                            }

                                        }else{
                                          console.log('pres')
                                          handleUpdate()
                                          // handleSubmit2()
                                        }
                                      }}  type="submit" className="btn buttonx-outline">
                                      {emptyexcel?'Upload another template':'Confirm'}</button></div>
                                </div>
                            </div>
                        </div>
      </Modal.Header>
 
      <Modal.Body style={{backgroundColor: "#fafbff",height:'70vh'}}>
      {processloader1?
                        <div style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center',display:'flex',marginTop:'10%'}}>
                        <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      </div>:
      <div  style={{backgroundColor: "#fafbff",width:'85%',marginLeft:'7.5%',display:'flex',justifyContent:'center',flexDirection:'column',overflowY:'auto' }}>
                          
                          <div >
                              <div className="d-flex mt-4">
                                  <p>Bulk upload process summary</p>
                              </div>
                            <div style={{backgroundColor:'white',width:'100%',marginTop:10,border:'1px solid #d1d1d1',borderRadius:10}}>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Total entries</p>
                                  <p>{totalentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Valid entries</p>
                                  <p>{validentries}</p>
                                </div>
                                <div style={{width:'80%',marginLeft:'20%',height:1,backgroundColor:'#d1d1d1'}}/>
                                <div style={{display:'flex',justifyContent:'space-between',padding:20}}>
                                  <p>Invalid entries</p>
                                  <p>{invalidentries}</p>
                                </div>
                            </div> 
                         

                            {emptyexcel?<p style={{marginTop:10}}>Erros:</p>:null}
                            {emptyexcel?
                            <ul>
                              <li>You have uploaded an empty excel</li>
                            </ul>
                            :
                          
                          null
                            }

                          </div>
                     
                  </div>
}
      </Modal.Body>

</Modal>
{/* bulk update Process */}

{/* modal loader */}
<Modal      centered  // This centers the modal vertically
 dialogClassName="custom-modal2"// Custom class for centering horizontally
     show={loadermodal} onHide={handleClose4}>
    
                    <div className="modal-content" style={{height:173,backgroundColor:'#fafbff'}}>
                  <div style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <Spinner animation="border" style={{marginBottom:20}} role="status">
                      <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <p style={{fontSize:16,color:'#4a4a4a',width:132,height:65,fontWeight:'900',textAlign:'center'}}>This Process may take a moment</p>
                  </div>
                    </div>
        
            
       

</Modal>
{/* modal loader */}


      {/* <aside  className="sidebar-container border-end"   >
    <div className="py-4">
        <p className="px-3 pb-3 border-bottom" style={{ fontFamily: 'Poppins-Regular' }}>Products</p>

        <div className="px-3">
            <p className="mt-3" style={{ fontFamily: 'Poppins-Regular' }}>Filter</p>

            <p className="mt-3" style={{ fontFamily: 'Poppins-Regular' }}>Availability</p>
            <select value={statuss} onChange={(e) => setStatus(e.target.value)} name="status" id="status" className="form-select mt-2">
                <option value="ALL">All statuses</option>
                <option value="AVAILABLE">Available</option>
                <option value="UNAVAILABLE">Unavailable</option>
                <option value="OUT_OF_STOCK">Out of stock</option>
                <option value="OUT_OF_SEASON">Out of season</option>
            </select>

            <p className="mt-4" style={{ fontFamily: 'Poppins-Regular' }}>Branches</p>
            <select value={branchid} onChange={(e) => setBranchid(e.target.value)} name="branchid" id="branchid" className="form-select mt-2">
                <option value="0">All branches</option>
                {branches.map((branch) => (
                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                ))}
            </select>
        </div>
    </div>
    </aside> */}
     
   
    <main className="products-container">
      <div className="prodfilter" style={{  marginTop:'1%',width:'96%',marginLeft:'2%'}}>
      <div className="d-flex align-items-center" style={{width:'70%'}}>
   <div style={{cursor:'pointer',marginLeft:'0%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">
   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12.000000pt" height="12.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>

        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search-product"
          placeholder="Search Product"
          style={{width:'90%',border:'none',marginLeft:10,outline: 'none'}}
          // className="form-control"
        />
     {search!=''?   <svg onClick={()=>setSearch('')} style={{marginLeft:5}} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2321 5110 c-313 -31 -610 -116 -900 -261 -261 -129 -470 -281 -678
-491 -209 -211 -346 -404 -478 -673 -126 -255 -197 -484 -242 -775 -24 -161
-24 -539 0 -700 45 -291 116 -520 241 -775 134 -272 283 -480 498 -692 211
-209 404 -346 673 -478 255 -126 484 -197 775 -242 161 -24 539 -24 700 0 281
44 515 115 756 231 269 130 461 264 673 471 231 226 378 427 517 710 125 255
196 484 241 775 24 161 24 539 0 700 -45 291 -116 520 -241 775 -134 272 -283
480 -498 692 -211 209 -404 346 -673 478 -252 124 -486 197 -765 240 -126 19
-468 27 -599 15z m-391 -1585 c19 -9 171 -153 337 -318 l303 -302 287 286
c159 158 304 295 323 305 47 25 191 27 235 2 115 -63 169 -198 130 -319 -16
-50 -41 -78 -306 -344 -159 -160 -289 -295 -289 -300 0 -6 135 -145 301 -310
330 -329 349 -353 349 -455 0 -186 -199 -315 -365 -236 -38 18 -121 95 -350
325 -165 166 -304 301 -310 301 -5 0 -156 -146 -335 -324 -308 -307 -328 -325
-379 -340 -68 -20 -94 -20 -160 0 -102 30 -181 135 -181 240 0 115 3 119 359
477 l325 327 -301 303 c-165 166 -309 317 -318 336 -64 127 4 296 141 351 59
24 151 22 204 -5z"/>
</g>
</svg>:null}
         </div>
{/* <div  style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M1283 4612 c-700 -262 -1273 -479 -1273 -482 0 -3 574 -220 1275
-482 l1275 -478 477 179 478 179 -55 25 c-30 13 -245 102 -478 198 -398 163
-422 175 -395 186 15 6 160 67 321 136 l293 125 478 -214 479 -215 476 178
c262 98 476 180 476 183 0 4 -2503 944 -2545 956 -5 2 -583 -212 -1282 -474z"/>
<path d="M2 2369 l3 -1441 1195 -448 1195 -448 3 1436 c1 790 -1 1439 -5 1442
-5 4 -516 197 -1138 430 -621 233 -1158 434 -1193 446 l-62 24 2 -1441z m958
-988 l315 -118 3 -171 c1 -95 1 -172 0 -172 -2 0 -145 53 -318 119 l-315 118
-3 171 c-1 95 -1 172 0 172 2 0 145 -53 318 -119z"/>
<path d="M4640 3630 l-475 -178 -5 -403 -5 -402 -315 -118 c-173 -66 -316
-119 -317 -119 -2 0 -4 180 -5 399 l-3 399 -395 -148 -395 -148 -3 -1441 -2
-1441 62 24 c35 12 572 213 1193 446 622 233 1133 426 1138 430 4 3 6 652 5
1442 l-3 1436 -475 -178z"/>
</g>
</svg>

<DropdownButton className='custom-dropdown' id="dropdown-basic-button" title="Dropdown button">
      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    </DropdownButton>
 <NavDropdown 
 className="custom-dropdown"
style={{marginLeft:'5%'}}
              id="nav-dropdown-dark-example"
              title={statuss === 'ALL' ? 'Availability' : statuss}
              onClick={()=>setOpen(!open)}
              show={open} // Control the dropdown visibility using state
              onSelect={() => handleClosedrop()} // Close dropdown when an item is selected
            >
              <NavDropdown.Item onClick={() => handleStatusChange('AVAILABLE')}>Available</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleStatusChange('UNAVAILABLE')}>Unavailable</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleStatusChange('OUT_OF_STOCK')}>Out of stock</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleStatusChange('OUT_OF_SEASON')}>Out of season</NavDropdown.Item>
              <NavDropdown.Item onClick={() => setStatus('ALL')} disabled={statuss=='ALL'?true:false} style={{color:statuss=='ALL'?'gray':'red'}} >Clear selection</NavDropdown.Item>
     
             
            </NavDropdown> 
  
</div> */}
<div >
  <div onClick={() => setIsExpanded(!isExpanded)} className="form-control" style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 5px 0 5px',border:statuss=='ALL'?null:'2px solid #307afe',display:'flex',alignItems:'center',height:40}}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill={statuss=='ALL'?"#616161":'#307afe'}  stroke="none">
    <path d="M1283 4612 c-700 -262 -1273 -479 -1273 -482 0 -3 574 -220 1275
    -482 l1275 -478 477 179 478 179 -55 25 c-30 13 -245 102 -478 198 -398 163
    -422 175 -395 186 15 6 160 67 321 136 l293 125 478 -214 479 -215 476 178
    c262 98 476 180 476 183 0 4 -2503 944 -2545 956 -5 2 -583 -212 -1282 -474z"/>
    <path d="M2 2369 l3 -1441 1195 -448 1195 -448 3 1436 c1 790 -1 1439 -5 1442
    -5 4 -516 197 -1138 430 -621 233 -1158 434 -1193 446 l-62 24 2 -1441z m958
    -988 l315 -118 3 -171 c1 -95 1 -172 0 -172 -2 0 -145 53 -318 119 l-315 118
    -3 171 c-1 95 -1 172 0 172 2 0 145 -53 318 -119z"/>
    <path d="M4640 3630 l-475 -178 -5 -403 -5 -402 -315 -118 c-173 -66 -316
    -119 -317 -119 -2 0 -4 180 -5 399 l-3 399 -395 -148 -395 -148 -3 -1441 -2
    -1441 62 24 c35 12 572 213 1193 446 622 233 1133 426 1138 430 4 3 6 652 5
    1442 l-3 1436 -475 -178z"/>
    </g>
    </svg>
    <div style={{marginLeft:5,width:'100%',color:statuss=='ALL'?'black':'#307afe',padding:'0 4px 0 0'}}>{statuss=='ALL'?'Availability':statuss=='AVAILABLE'?'Available':
    statuss=='OUT_OF_SEASON'?'Out of season':statuss=='OUT_OF_STOCK'?'Out of stock':
    statuss=='UNAVAILABLE'?'Not available':null }</div>
  </div>
  {isExpanded? 


  <div ref={wrapperRef}  className='AvailabilityFilterList_containerList__be85l' >
  <div style={{display:'flex',flexDirection:'column',marginLeft:10}}>
        <div onClick={()=>{
          setStatus('AVAILABLE')
      setIsExpanded(!isExpanded)
      }} style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
        Available
        </div>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
             <div onClick={()=>{      setIsExpanded(!isExpanded)
             setStatus('OUT_OF_SEASON')
             }} style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
        Out of season
        </div>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
             <div onClick={()=>{
                    setIsExpanded(!isExpanded)
              setStatus('OUT_OF_STOCK')}
              } style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
        Out of stock
        </div>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
             <div onClick={()=>{
                    setIsExpanded(!isExpanded)
              setStatus('UNAVAILABLE')}
              } style={{padding:'10px 0 5px 0',cursor:'pointer'}}>
        Not available
        </div>
        <div style={{width:'100%',height:1,backgroundColor:'#d1d1d1'}}/> 
        <div  onClick={() => {
          if(statuss=='ALL'){
            console.log('.')
          }else{
            setStatus('ALL')
            setIsExpanded(!isExpanded)
          }
  
            }} style={{padding:'10px 0 5px 0',color:statuss=='ALL'?'gray':'red',cursor:'pointer'}}>
        Clear selection
        </div>
      
   

    
  </div>

  </div>
  :null}
 
</div>

<div  style={{cursor:'pointer',marginLeft:'2%',width:'auto',padding:'0 15px 0 15px',display:'flex',alignItems:'center',height:40}} className="form-control">

<svg xmlns="http://www.w3.org/2000/svg" fill='#e05b0e'  width="18.000000pt" height="18.000000pt" viewBox="0 0 512 512">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#616161" stroke="none">
<path d="M515 4786 c-84 -26 -153 -84 -191 -157 -13 -28 -87 -300 -175 -651
-146 -584 -151 -605 -145 -680 28 -391 332 -708 703 -734 218 -16 427 66 586
229 l67 70 68 -69 c301 -310 763 -310 1065 0 l67 69 68 -69 c301 -310 763
-310 1065 0 l67 69 68 -70 c158 -163 367 -245 585 -229 371 26 675 343 703
734 6 75 1 96 -145 680 -88 351 -162 623 -175 651 -29 57 -92 116 -155 145
l-46 21 -2020 2 c-1604 1 -2028 -1 -2060 -11z"/>
<path d="M1295 2388 c-65 -41 -194 -93 -290 -118 -198 -51 -458 -29 -648 54
l-37 16 2 -887 3 -888 26 -56 c33 -71 87 -125 158 -158 l56 -26 678 -3 677 -3
0 641 0 640 640 0 640 0 0 -640 0 -641 678 3 677 3 56 26 c71 33 125 87 158
158 l26 56 3 888 2 887 -37 -16 c-132 -58 -329 -91 -478 -80 -153 10 -332 66
-456 142 -31 18 -62 34 -69 34 -6 0 -37 -15 -68 -33 -313 -183 -751 -183
-1064 0 -31 18 -62 33 -68 33 -6 0 -37 -15 -68 -33 -313 -183 -751 -183 -1064
0 -31 18 -62 33 -70 33 -7 0 -35 -15 -63 -32z"/>
</g>
</svg>

<select value={branchid === '0' ? 'All Branches' : branchname}
  onChange={(e) => {
    if(e.target.value==0){
      setBranchid(0);
      setBranchName('All Branches');
    }else{
      const selectedBranchName = e.target.value;
      const selectedBranch = branches.find(branch => branch.name === selectedBranchName);
      setBranchid(selectedBranch.id);
      setBranchName(selectedBranch.name);
    }
  
  }} style={{marginLeft: '5%',width:branchid==0?120:150 ,border:'none',outline:'none'}}>
  <option value="0">All Branches</option>
  {branches.map((branch) => (
      <option key={branch.id}>{branch.name}</option>
  ))}
</select>
{/* <Dropdown 
 className="custom-dropdown"
style={{marginLeft:'5%'}}
              id="nav-dropdown-dark-example"
              title={branchid === '0' ? 'Branches' : branchname}
              onClick={()=>setOpen1(!open1)}
              show={open1} // Control the dropdown visibility using state
              onSelect={() => handleClosedrop1()} // Close dropdown when an item is selected
            >
              {branches.map((branch) => (
                                  <Dropdown.Item onClick={() =>{setBranchid(branch.id)
                                  setBranchName(branch.name)}}>{branch.name}</Dropdown.Item>

                ))}
             
             
            </Dropdown> */}
  
</div>
           
            </div>
        <div className="d-flex align-items-center">
        
            <Dropdown >
      <Dropdown.Toggle style={{backgroundColor:'#e05b0e',border:'none',height:33,marginRight:8}} variant="success" id="dropdown-basic">
      Inventory
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>{
           setShow2(true)
           setShow1(false)
        }}>
<div style={{display:'flex',alignItems:'center'}}>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="red" stroke="none">
<path d="M2485 5101 c-27 -13 -197 -177 -522 -502 -426 -428 -482 -488 -488
-522 -23 -122 38 -214 153 -233 80 -13 110 10 445 344 l307 307 0 -1540 0
-1540 23 -40 c71 -125 243 -125 314 0 l23 40 0 1540 0 1540 308 -307 c334
-334 364 -357 444 -344 115 19 176 111 153 233 -6 34 -62 94 -488 522 -325
325 -495 489 -522 502 -22 10 -56 19 -75 19 -19 0 -53 -9 -75 -19z"/>
<path d="M563 3446 c-168 -55 -303 -191 -356 -361 l-22 -70 0 -1275 c0 -1401
-4 -1310 63 -1445 41 -82 131 -176 213 -223 32 -18 92 -41 135 -52 76 -20 115
-20 1964 -20 1849 0 1888 0 1964 20 192 49 341 198 397 395 12 42 14 258 14
1325 l0 1275 -22 70 c-38 119 -124 232 -234 306 -24 16 -80 41 -124 55 l-80
25 -536 -3 -536 -3 -37 -29 c-102 -77 -95 -231 12 -298 l37 -23 516 -5 515 -5
41 -27 c24 -16 49 -44 62 -70 l21 -44 0 -1227 c0 -1020 -2 -1233 -14 -1260
-16 -39 -61 -82 -99 -96 -40 -15 -3754 -15 -3794 0 -38 14 -83 57 -99 96 -12
27 -14 240 -14 1260 l0 1227 21 44 c13 26 38 54 62 70 l41 27 515 5 516 5 37
23 c107 67 114 221 12 298 l-37 29 -541 2 -541 2 -72 -23z"/>
</g>
</svg>
<p style={{marginLeft:10,marginTop:5}}>Export</p>
</div>
          </Dropdown.Item>
        <Dropdown.Item 
        onClick={()=>{
          setShow3(true)
          setShow1(false)
       }}>
       <div style={{display:'flex',alignItems:'center'}}>
       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2495 4786 c-37 -17 -70 -52 -84 -89 -8 -20 -11 -467 -11 -1450 l0
-1422 -442 442 c-480 478 -464 465 -555 448 -49 -9 -109 -69 -118 -118 -18
-93 -44 -61 603 -709 648 -648 616 -620 709 -603 34 5 107 76 635 603 647 648
621 616 603 709 -9 49 -69 109 -118 118 -91 17 -75 30 -554 -448 l-443 -441
-2 1436 c-3 1433 -3 1437 -24 1464 -11 15 -32 37 -46 47 -34 25 -113 32 -153
13z"/>
<path d="M415 1426 c-41 -18 -83 -69 -90 -109 -4 -18 -5 -196 -3 -397 l3 -365
34 -63 c38 -70 69 -100 145 -140 l51 -27 2005 0 2005 0 51 27 c76 40 107 70
145 140 l34 63 0 392 c0 380 -1 392 -21 419 -39 53 -71 69 -134 69 -63 0 -95
-16 -134 -69 -20 -26 -21 -42 -24 -377 l-3 -349 -1919 0 -1919 0 -3 349 c-3
335 -4 351 -24 377 -11 15 -32 37 -46 47 -33 25 -113 32 -153 13z"/>
</g>
</svg>

<p style={{marginLeft:10,marginTop:5}}>Bulk Update</p>
</div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        {/* <a onClick={()=>{setShow1(true)}} data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2" style={{ fontSize: '13px' }}>
            Inventory
          </a> */}
          <a onClick={()=>{setShow(true)}} data-bs-toggle="modal" data-bs-target="#importModal" className="btn btn-danger buttonx me-2" style={{ fontSize: '13px' }}>
            Import
          </a>
          <a onClick={()=>navigate('/product/create')} className="btn btn-danger buttonx" style={{ fontSize: '13px' }}>
            Add product
          </a>
        
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between' style={{marginTop:10,width:'96%',marginLeft:'2%'}}>
        <div>
          <p>All Products</p>
        </div>
<div className='d-flex align-items-center'>
<p id="pagination-text" className="mx-2" style={{ fontSize: '12px' }}>
            {page === 1 ? 0 : (page - 1) * limit}-{page * limit <= count ? page * limit : count} of {count}
          </p>
          <div className="pagination-container py-2 border d-flex h-100">
          <svg  onClick={() => {
                if (page > 1) setPage(page - 1);
              }} style={{ opacity: page > 1 ? '1' : '0.25' }} className="border-end" fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
  <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
</svg>
       
         

<svg  onClick={() => {
                if (page < totalPages) setPage(page + 1);
              }}   style={{ opacity: page < totalPages ? '1' : '0.25' }} fill="#000000" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve" transform="matrix(-1, 0, 0, 1, 0, 0)">
  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
  <g id="SVGRepo_iconCarrier">
    <polygon points="213.3,205.3 213.3,77.3 0,248 213.3,418.7 213.3,290.7 512,290.7 512,205.3" />
  </g>
</svg>
          </div>
</div>
      </div>
{showAlerts()}

      <div className={`main-products-container py-3 px-4 ${loading ? 'd-flex justify-content-center align-items-center main-products-containerx' : ''}`}>
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
        <>
        {products.length==0?
          <div style={{width:'100%',height:'70vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
             <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50.000000pt" height="50.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path d="M1930 5109 c-494 -51 -951 -269 -1306 -623 -246 -246 -412 -514 -518 -836 -141 -428 -141 -872 0 -1300 106 -323 273 -592 520 -837 488 -485 1153 -705 1839 -608 353 50 707 195 985 404 30 23 57 40 61 39 4 -2 307 -298 673 -658 417 -410 680 -661 704 -672 98 -46 208 12 228 120 16 84 72 24 -1024 1106 l-342 338 58 68 c250 289 415 655 478 1055 24 156 24 442 0 600 -71 459 -272 853 -607 1186 -460 457 -1101 684 -1749 618z m479 -335 c404 -59 759 -235 1040 -515 344 -345 523 -774 523 -1259 0 -179 -16 -302 -58 -457 -212 -788 -931 -1333 -1759 -1333 -493 0 -951 187 -1293 529 -232 232 -387 500 -471 811 -74 277 -74 623 0 900 168 626 665 1119 1292 1281 228 59 507 76 726 43z"/>
</g>
</svg>
<p style={{marginTop:10,fontSize:19,fontWeight:'bold',color:'black'}}>No search results found</p>
<p style={{fontSize:15,fontWeight:'bold',color:'black'}}>Please try again with a different search term</p>
          </div>
        :
        products.map((product) => (

          <ProdItem
           fetch={fetchprods}
            key={product.id} // Make sure to provide a unique key
            setModalState={setModalState}
            setModalInfo={setModalInfo}
            setModalImgPrev={setModalImgPrev}
            product={product}
            id={id==product.id?id:null}
            hasid={id==product.id?true:false}
          />
        ))
        }
        </>
        
            
      
        )}
       
      </div>
    </main>

    </div>
  )
}
